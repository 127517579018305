import StripeCards from 'components/svg/StripeCards';
import cx from 'classnames';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { Stripe, StripeElements, StripeError } from '@stripe/stripe-js';
import { ApiError, isApiError, isStripeError } from 'utils/errors';

export interface StripeData {
  stripe: Stripe;
  elements: StripeElements;
}

interface CreditCardFormProps {
  onSubmit: (stripeData: StripeData) => void;
  withCgvAndCguValidation: boolean;
  error?: StripeError | ApiError | unknown;
  amountInCent?: number;
  isSubmitting?: boolean;
  disableSubmitButton?: boolean;
}

interface CardElementState {
  complete: boolean;
  error?: {
    type: 'validation_error';
    code: string;
    message: string;
  };
}

export default function CreditCardForm({
  onSubmit,
  withCgvAndCguValidation,
  error,
  amountInCent,
  isSubmitting,
  disableSubmitButton,
}: CreditCardFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [cgvAndCguState, setCgvAndCguState] = useState<{
    checked: boolean;
    error?: boolean;
  }>({
    checked: false,
    error: undefined,
  });
  const [cardNumberState, setCardNumberState] = useState<CardElementState>({
    complete: false,
  });
  const [cardExpiryState, setCardExpiryState] = useState<CardElementState>({
    complete: false,
  });
  const [cardCvcState, setCardCvcState] = useState<CardElementState>({
    complete: false,
  });

  const formComplete =
    cardNumberState.complete &&
    cardExpiryState.complete &&
    cardCvcState.complete &&
    (!withCgvAndCguValidation || cgvAndCguState.checked);

  const isSubmitDisabled = !formComplete || isSubmitting || disableSubmitButton;

  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    if (cardNumberState.error) {
      elements.getElement('cardNumber')?.focus();
      return;
    }
    if (cardExpiryState.error) {
      elements.getElement('cardExpiry')?.focus();
      return;
    }
    if (cardCvcState.error) {
      elements.getElement('cardCvc')?.focus();
      return;
    }
    if (withCgvAndCguValidation && !cgvAndCguState.checked) {
      setCgvAndCguState({ ...cgvAndCguState, error: true });
    }
    onSubmit({ stripe, elements });
  };

  return (
    <>
      <StripeCards className="w-48 mb-6" />
      <form>
        <div className="md:flex justify-between6">
          <div
            className={cx('w-full md:w-2/3 md:mr-4 required', {
              error: cardNumberState.error,
            })}
          >
            <label>Numéro de carte</label>{' '}
            <CardNumberElement
              options={{ style: { base: { fontSize: '18px' } } }}
              className="border shadow-inner border-gray-300 bg-white w-full p-3 mb-4 rounded-md"
              onChange={(e) => {
                setCardNumberState(e);
              }}
            />{' '}
            {cardNumberState.error && <div className="error explanation">{cardNumberState.error.message}</div>}
          </div>
          <div
            className={cx('w-full md:w-1/3 md:mr-4 required', {
              error: cardExpiryState.error,
            })}
          >
            <label>Expire le</label>{' '}
            <CardExpiryElement
              options={{ style: { base: { fontSize: '18px' } } }}
              className="border shadow-inner border-gray-300 bg-white w-full p-3 mb-4 rounded-md"
              onChange={(e) => {
                setCardExpiryState(e);
              }}
            />{' '}
            {cardExpiryState.error && <div className="error explanation">{cardExpiryState.error.message}</div>}
          </div>
          <div
            className={cx('w-full md:w-1/3 required', {
              error: cardCvcState.error,
            })}
          >
            <label>CVC</label>{' '}
            <CardCvcElement
              options={{ style: { base: { fontSize: '18px' } } }}
              className="border shadow-inner border-gray-300 bg-white w-full p-3 mb-4 rounded-md"
              onChange={(e) => {
                setCardCvcState(e);
              }}
            />{' '}
            {cardCvcState.error && <div className="error explanation">{cardCvcState.error.message}</div>}
          </div>
        </div>

        {withCgvAndCguValidation && (
          <>
            <div
              className={cx('inline-flex items-center mt-4 required', {
                error: cgvAndCguState.error,
              })}
            >
              <input
                type="checkbox"
                id="id1"
                checked={cgvAndCguState.checked}
                onChange={() =>
                  setCgvAndCguState({
                    checked: !cgvAndCguState.checked,
                    error: cgvAndCguState.error == null ? false : !cgvAndCguState.error,
                  })
                }
              />{' '}
              <label htmlFor="id1" className="mb-0 ml-2 leading-none cursor-pointer">
                {' '}
                J'accèpte les&nbsp;
                <a href="https://rightsnow.fr/cgv-cgu/" target="_blank">
                  {' '}
                  CGV / CGU{' '}
                </a>{' '}
              </label>
            </div>
            {cgvAndCguState.error && (
              <div className="w-full pt-4 error explanation">Vous devez accepter les CGV et les CGU</div>
            )}
          </>
        )}
        <div className="actions mt-6">
          <button
            type="submit"
            className={cx('button', { loading: isSubmitDisabled })}
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
          >
            Je m'abonne
          </button>
        </div>
        {!!error && (
          <div className="message bg-red-100 text-red-500 mt-6 p-4">
            {isStripeError(error) && error.type !== 'validation_error' && error.message && error.message}
            {/*{!isStripeError(error) && isApiError(error) && error.json.message}{' '}*/}
            {!isStripeError(error) && (
              <>
                Une erreur s'est produite, mais ce n'est pas de votre faute ! Merci de retenter dans quelques minutes :)
              </>
            )}
          </div>
        )}
      </form>
    </>
  );
}
