import BroadcastChart, { getTimeRangeInterval } from './BroadcastChart';
import React, { useState } from 'react';
import useUserInfo from '../../hooks/use-user-info';
import IconLoading from '../../components/icons/IconLoading';
import { RadioHeader, StreamHeader, TvHeader } from 'components/broadcast/BroadcastHeader';
import PageTitle from 'features/layout/PageTitle';
import { useQuery } from 'react-query';
import api from 'services/api';
import Select, { Theme } from 'react-select';
import { TimeRange } from 'shared-types';
import { computeTotalsFromOverview } from 'utils/broadcastHelper';
import RightAmountWithMargin from 'components/broadcast/RightsAmountWithMargin';
import Card from 'features/dashboard/Card';
import { BroadcastContent } from 'components/broadcast/BroadcastContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';

// Custom design for Select
const customColors = {
  primary: '#003cff',
  primary75: '#99b1ff',
  primary50: '#e6ecff',
  primary25: '#f2f5ff',
  neutral20: '#d1d5db',
};

type StateType = {
  isFocused: boolean;
};

const getControlClassNames = (state: StateType) =>
  state.isFocused ? '!ring-4 !ring-primary-200/80 !border-primary' : '';

const customTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    ...customColors,
  },
});

interface SelectOption<T> {
  value: T;
  label: string;
}

const timeRangeOptions: SelectOption<TimeRange>[] = [
  { value: 'all_time', label: 'Depuis le début' },
  { value: 'last_7_days', label: '7 derniers jours' },
  { value: 'last_30_days', label: '30 derniers jours' },
  // { value: 'last_365_days', label: 'Depuis 1 an' },
];

const initialChannelNameOptions: Array<SelectOption<string>> = [{ value: '', label: 'Toutes les sources' }];

function DashboardPage() {
  const [timeRange, setTimeRange] = useState<SelectOption<TimeRange>>(timeRangeOptions[0]);
  const [streamChannelName, setStreamChannelName] = useState<SelectOption<string>>(initialChannelNameOptions[0]);
  const [radioChannelName, setRadioChannelName] = useState<SelectOption<string>>(initialChannelNameOptions[0]);
  const [tvChannelName, setTvChannelName] = useState<SelectOption<string>>(initialChannelNameOptions[0]);

  const from = getTimeRangeInterval(timeRange.value).lowerBound?.unix();
  const to = getTimeRangeInterval(timeRange.value).upperBound?.unix();
  const {
    userInfo: { userName, subscription, trackCount, email },
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
  } = useUserInfo();

  const {
    data: broadcastOverview,
    isLoading: isLoadingBroadcast,
    isError: isErrorBroadcast,
  } = useQuery(
    ['/api/broadcast-overview', timeRange],
    () =>
      api.fetchBroadcastOverview({
        from,
        to,
      }),
    { staleTime: Infinity },
  );

  const {
    data: rights,
    isLoading: isLoadingRights,
    isError: isErrorRights,
  } = useQuery(['/api/rights', timeRange], () => api.fetchRights({ from, to }), { staleTime: Infinity });

  const {
    data: streamStats,
    isLoading: isLoadingStreamStats,
    isError: isErrorStreamStats,
  } = useQuery(
    ['/api/stream-stats', timeRange, streamChannelName],
    () =>
      api.fetchBroadcastStatReport({
        from: getTimeRangeInterval(timeRange.value).lowerBound?.unix(),
        to: getTimeRangeInterval(timeRange.value).upperBound.unix(),
        media: 'streams',
        mediaName: streamChannelName.value,
      }),
    { staleTime: Infinity },
  );

  const {
    data: radioStats,
    isLoading: isLoadingRadioStats,
    isError: isErrorRadioStats,
  } = useQuery(
    ['/api/radio-stats', timeRange, radioChannelName],
    () =>
      api.fetchBroadcastStatReport({
        from: getTimeRangeInterval(timeRange.value).lowerBound?.unix(),
        to: getTimeRangeInterval(timeRange.value).upperBound.unix(),
        media: 'radio',
        mediaName: radioChannelName.value,
      }),
    { staleTime: Infinity },
  );

  const {
    data: tvStats,
    isLoading: isLoadingTvStats,
    isError: isErrorTvStats,
  } = useQuery(
    ['/api/tv-stats', timeRange, tvChannelName],
    () =>
      api.fetchBroadcastStatReport({
        from: getTimeRangeInterval(timeRange.value).lowerBound?.unix(),
        to: getTimeRangeInterval(timeRange.value).upperBound.unix(),
        media: 'tv',
        mediaName: tvChannelName.value,
      }),
    { staleTime: Infinity },
  );

  const streamTotal = (streamStats || []).reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);
  const radioTotal = (radioStats || []).reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);
  const tvTotal = (tvStats || []).reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);
  const totalBroadcastsFromOverview = computeTotalsFromOverview(broadcastOverview);
  const totalRights = rights ? rights.streams + rights.radio + rights.tv : 0;
  const weightPercentage = 0.17528;
  const loaderDataIcon = <IconLoading className="w-4 h-4 text-primary" />;

  const streamChannelNameOptions = [...initialChannelNameOptions];
  const radioChannelNameOptions = [...initialChannelNameOptions];
  const tvChannelNameOptions = [...initialChannelNameOptions];

  const streamedPlatform = (broadcastOverview?.streams || []).map((s) => ({ name: s.platform, count: s.count }));
  streamChannelNameOptions.push(
    ...streamedPlatform.map((v) => ({
      value: v.name,
      label: `${v.name} (${v.count.toLocaleString('fr-FR', {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: 1,
      })})`,
    })),
  );

  const liveBroadcastRadioChannelName = (broadcastOverview?.liveBroadcasts || [])
    .filter((lb) => lb.type === 'radio')
    .flatMap((lb) => lb.channels);
  radioChannelNameOptions.push(
    ...liveBroadcastRadioChannelName.map((v) => ({
      value: v.name,
      label: `${v.name} (${v.count.toLocaleString('fr-FR', {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: 1,
      })})`,
    })),
  );
  const liveBroadcastTvChannelName = (broadcastOverview?.liveBroadcasts || [])
    .filter((lb) => lb.type === 'tv')
    .flatMap((lb) => lb.channels);
  tvChannelNameOptions.push(
    ...liveBroadcastTvChannelName.map((v) => ({
      value: v.name,
      label: `${v.name} (${v.count.toLocaleString('fr-FR', {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: 1,
      })})`,
    })),
  );

  const isLoadingStats = isLoadingStreamStats || isLoadingRadioStats || isLoadingTvStats;
  const isErrorStats = isErrorStreamStats || isErrorRadioStats || isErrorTvStats;
  const isLoading = isLoadingUserInfo || isLoadingStats || isLoadingBroadcast;
  const isError = isErrorUserInfo || isErrorStats || isErrorBroadcast;

  const handleChangeTimeRange = (tr: any) => {
    setStreamChannelName(initialChannelNameOptions[0]);
    setRadioChannelName(initialChannelNameOptions[0]);
    setTvChannelName(initialChannelNameOptions[0]);
    setTimeRange(tr);
  };

  return (
    <>
      <PageTitle title="Tableau de bord" />
      <div className="w-full flex flex-col space-y-6">
        <div className="w-full">
          <div className="md:flex items-center justify-between mb-12">
            <h1 className="h4 text-gray-800">Bonjour {userName}</h1>
            <div className="inline-flex items-center text-xs bg-gray-100 shadow p-2 rounded select-none">
              <span className="bg-red-700 rounded-md text-white font-bold text-[11px] px-1 inline-flex items-center mr-2 shadow">
                <span className="w-2 h-2 animate-pulse bg-white rounded-full inline-block mr-1"></span>
                <span>REC</span>
              </span>
              <Link to="/channels-monitoring" className="underline">
                Monitoring actif
              </Link>
              <Tippy content="RightsNow! scanne en temps réél TOUS les channels pour détecter la diffusion de vos oeuvres et enrichir votre historique.">
                <FontAwesomeIcon icon={duotone('question-circle')} className="text-primary ml-3 h-4 cursor-help" />
              </Tippy>
            </div>
          </div>

          {/*Head of cards*/}
          <div className="flex justify-between space-y-4 flex-col md:flex-row items-baseline text-gray-800 mb-6">
            <div className="text-xl">
              Résumé
              <Select
                onChange={handleChangeTimeRange}
                className="react-select inline-flex ml-2"
                theme={customTheme}
                classNames={{ control: getControlClassNames }}
                options={timeRangeOptions}
                isSearchable={false}
                value={timeRange}
              />
            </div>
            <div className="md:inline-flex items-center">
              <span>Total des droits générés :&nbsp;</span>
              <strong>
                {/* Chargement des données */}
                {(isLoadingRights || isErrorRights) && <>{loaderDataIcon}</>}

                {/* Si tout est ok, on affiche les données */}
                {!isLoadingRights && !isErrorRights && (
                  <RightAmountWithMargin amount={totalRights} compact={false} weightPercentage={weightPercentage} />
                )}
              </strong>
            </div>
          </div>

          {/*New factorized cards*/}
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
            {/*Streams Card*/}
            <Card
              header={<StreamHeader />}
              content={
                <BroadcastContent
                  broadcastNumber={totalBroadcastsFromOverview.totalStreamBroadcast}
                  isLoadingBroadcast={isLoadingBroadcast && !isErrorBroadcast}
                  rightsAmount={rights?.streams ?? 0}
                  rightsMargin={weightPercentage}
                  isLoadingRights={isLoadingRights && !isErrorRights}
                  isLoading={isLoadingUserInfo && !isLoadingUserInfo}
                />
              }
            />

            {/*Radios Card*/}
            <Card
              header={<RadioHeader />}
              content={
                <BroadcastContent
                  broadcastNumber={totalBroadcastsFromOverview.totalRadioBroadcast}
                  isLoadingBroadcast={isLoadingBroadcast && !isErrorBroadcast}
                  rightsAmount={rights?.radio ?? 0}
                  rightsMargin={weightPercentage}
                  isLoadingRights={isLoadingRights && !isErrorRights}
                  isLoading={isLoadingUserInfo && !isLoadingUserInfo}
                  showUpdateSubscriptionLink={!isLoadingUserInfo && !isErrorUserInfo && subscription?.formula === '1'}
                />
              }
            />

            {/*TV Card*/}
            <Card
              header={<TvHeader />}
              content={
                <BroadcastContent
                  broadcastNumber={totalBroadcastsFromOverview.totalTvBroadcast}
                  isLoadingBroadcast={isLoadingBroadcast && !isErrorBroadcast}
                  rightsAmount={rights?.tv ?? 0}
                  rightsMargin={weightPercentage}
                  isLoadingRights={isLoadingRights && !isErrorRights}
                  isLoading={isLoadingUserInfo && !isLoadingUserInfo}
                  showUpdateSubscriptionLink={!isLoadingUserInfo && !isErrorUserInfo && subscription?.formula !== '3'}
                />
              }
            />
          </div>
        </div>

        <div className="overflow-hidden rounded shadow-xl">
          <StreamHeader>
            {!isLoadingBroadcast &&
              !isErrorBroadcast &&
              !isLoadingStreamStats &&
              !isErrorStreamStats &&
              totalBroadcastsFromOverview.totalStreamBroadcast > 0 && (
                <div className="mt-2 sm:mt-0 sm:inline-flex items-center sm:space-x-2">
                  <span className="hidden sm:inline-block">Afficher</span>
                  <Select
                    onChange={setStreamChannelName}
                    className="react-select"
                    theme={customTheme}
                    classNames={{ control: getControlClassNames }}
                    options={streamChannelNameOptions}
                    isSearchable={true}
                    value={streamChannelName}
                  />
                  <FontAwesomeIcon icon={duotone('arrow-right')} className="h-5 text-gray-600 hidden sm:inline-block" />
                  <div className="mt-2 sm:mt-0">
                    <span>Total : </span>
                    {!isLoadingStreamStats ? <strong>{streamTotal.toLocaleString()}</strong> : <>&mdash;</>}
                  </div>
                </div>
              )}
          </StreamHeader>
          <dl className="p-6 text-sm leading-6 bg-white">
            <BroadcastChart
              totalBroadcast={totalBroadcastsFromOverview.totalStreamBroadcast}
              stats={streamStats || []}
              from={from}
              to={to}
              trackCount={trackCount}
              isLoading={isLoadingStreamStats || isLoadingBroadcast}
              isError={isErrorStreamStats || isErrorBroadcast}
            />
          </dl>
        </div>

        {!isLoadingUserInfo && subscription?.formula !== '1' && (
          <div className="overflow-hidden rounded shadow-xl">
            <RadioHeader>
              {!isLoadingBroadcast &&
                !isErrorBroadcast &&
                !isLoadingRadioStats &&
                !isErrorRadioStats &&
                totalBroadcastsFromOverview.totalRadioBroadcast > 0 && (
                  <div className="mt-2 sm:mt-0 sm:inline-flex items-center sm:space-x-2">
                    <span className="hidden sm:inline-block">Afficher</span>
                    <Select
                      onChange={setRadioChannelName}
                      className="react-select"
                      theme={customTheme}
                      classNames={{ control: getControlClassNames }}
                      options={radioChannelNameOptions}
                      isSearchable={true}
                      value={radioChannelName}
                    />
                    <FontAwesomeIcon
                      icon={duotone('arrow-right')}
                      className="h-5 text-gray-600 hidden sm:inline-block"
                    />
                    <div className="mt-2 sm:mt-0">
                      <span>Total : </span>
                      {!isLoadingStreamStats ? <strong>{radioTotal.toLocaleString()}</strong> : <>&mdash;</>}
                    </div>
                  </div>
                )}
            </RadioHeader>
            <dl className="p-6 text-sm leading-6 bg-white">
              <BroadcastChart
                totalBroadcast={totalBroadcastsFromOverview.totalRadioBroadcast}
                stats={radioStats || []}
                from={from}
                to={to}
                trackCount={trackCount}
                isLoading={isLoadingRadioStats || isLoadingBroadcast}
                isError={isErrorRadioStats || isErrorBroadcast}
              />
            </dl>
          </div>
        )}

        {!isLoadingUserInfo && subscription?.formula === '3' && (
          <div className="overflow-hidden rounded shadow-xl">
            <TvHeader>
              {!isLoadingBroadcast &&
                !isErrorBroadcast &&
                !isLoadingTvStats &&
                !isErrorTvStats &&
                totalBroadcastsFromOverview.totalTvBroadcast > 0 && (
                  <div className="mt-2 sm:mt-0 sm:inline-flex items-center sm:space-x-2">
                    <span className="hidden sm:inline-block">Afficher</span>
                    <Select
                      onChange={setTvChannelName}
                      className="react-select"
                      theme={customTheme}
                      classNames={{ control: getControlClassNames }}
                      options={tvChannelNameOptions}
                      isSearchable={true}
                      value={tvChannelName}
                    />
                    <FontAwesomeIcon
                      icon={duotone('arrow-right')}
                      className="h-5 text-gray-600 hidden sm:inline-block"
                    />
                    <div className="mt-2 sm:mt-0">
                      <span>Total : </span>
                      {!isLoadingStreamStats ? <strong>{tvTotal.toLocaleString()}</strong> : <>&mdash;</>}
                    </div>
                  </div>
                )}
            </TvHeader>
            <dl className="p-6 text-sm leading-6 bg-white">
              <BroadcastChart
                totalBroadcast={totalBroadcastsFromOverview.totalTvBroadcast}
                stats={tvStats || []}
                from={from}
                to={to}
                trackCount={trackCount}
                isLoading={isLoadingTvStats || isLoadingBroadcast}
                isError={isErrorTvStats || isErrorBroadcast}
              />
            </dl>
          </div>
        )}

        <div className="text-xs mt-3">
          <details>
            <summary className="cursor-pointer text-primary hover:underline">
              Hey ! Je devrais voir certaines statistiques mais elles ne s'affichent pas
            </summary>
            <p className="mt-3">
              Ne vous inquiétez pas :). Nous collectons les données mais celles-ci peuvent avoir un peu de retard au
              niveau de l'affichage (nous dépendons des plateformes de diffusion).
            </p>
            <p>
              Il se peut également que l'un de vos titres soit diffusé durant une période mais que la plateforme ne nous
              n'a pas encore communiqué les données.{' '}
              <strong>Cette absence est automatiquement compensée lors de la prochaine collecte</strong>. Donc le total
              est toujours fiable.
            </p>
            <p>
              Si quelque chose vous semble anormal, n'hésitez pas à{' '}
              <a href="https://www.rightsnow.fr/support/" target="_blank">
                nous contacter
              </a>
              .
            </p>
          </details>
        </div>
      </div>
    </>
  );
}

export default DashboardPage;
