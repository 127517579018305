import React from 'react'

const FlorentGarciaLogo: React.FC = () => {
  return (
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 326 381">
      <g>
        <g>
          <path d="M147.55,0c10.3,0,20.6,0,30.91,0c9.84,1.33,19.72,2.36,29.5,4.04c26.36,4.54,51.65,12.26,74.21,27.16
			c20.69,13.67,35.45,31.84,41.34,56.41c0.96,4.01,1.68,8.08,2.5,12.12c0,6.98,0,13.96,0,20.95c-2.18,11.04-3.6,22.3-6.66,33.09
			c-18.46,65.03-50.19,123.34-91,176.88c-11.81,15.49-24.79,29.99-41,41.14c-17.9,12.31-30.7,12.24-48.59,0
			c-3.01-2.06-6.08-4.15-8.65-6.7c-8.12-8.06-16.62-15.86-23.91-24.64c-32.55-39.24-57.86-82.97-78.46-129.47
			c-12.8-28.9-23.79-58.41-27.09-90.18c-3.51-33.76,7.27-61.88,33.81-83.35c24.08-19.47,52.67-28.24,82.57-33.4
			C127.13,2.31,137.37,1.33,147.55,0z M106.3,45.24c-19.83,1.15-39.4,2.28-58.96,3.41c-0.04,0.66-0.09,1.33-0.13,1.99
			c9.57,1.26,19.14,2.53,28.57,3.77c0,4.6,0,9.34,0,14.13c-6.66,2.41-14.16,0.14-19.96,4.97c6.57,0.43,13.13,0.85,19.97,1.29
			c0,5.68,0,10.96,0,16.24c0,5.35,0,10.69,0,16.04c2.08-0.68,3.52-1.67,3.92-2.97c1.6-5.22,2.93-10.53,4.25-15.83
			c1.1-4.43,2.04-8.9,3.03-13.26c5.84,0.65,7.3-0.58,8.39-6.51c-2.38,0.19-4.76,0.39-7.61,0.62c0.42-4.11,0.37-7.81,1.34-11.22
			c0.45-1.59,2.81-3.54,4.45-3.7c3.54-0.34,7.18,0.38,11.31,0.71c-0.94,6.43-2.41,12.59-2.59,18.79c-0.2,6.87-0.24,14.05,1.51,20.61
			c2.31,8.67,10.79,10.29,17.49,4.28c0.46-0.41,1.03-0.68,1.5-0.98c5.05,3.8,10.37,6.46,16.31,3.56c5.27-2.58,6.75-7.93,7.3-13.38
			c0.18-1.77-0.01-3.73-0.62-5.38c-1.56-4.16-0.2-7.33,2.72-10.18c4.02,1.83,4.04,1.83,3.16,5.96c-1.55,7.25,0.1,13.89,4.1,19.92
			c4.08,6.17,9.52,5.89,13.1-0.46c0.53-0.93,1.16-1.81,1.79-2.78c8.33,8.73,15.33,9.52,23.79,3.49c3.99,1.74,7.57,3.3,11.56,5.03
			c0.76-13.69,4.06-23.36,8.92-27.57c-0.61,5.07-1.45,10.3-1.85,15.56c-0.93,12.4,2.25,16.23,15.01,17.88c0,30.7,0,61.5,0,92.33
			c4.74,0,8.79,0,12.83,0c0-31.9,0-63.4,0-95.22c-3.52-0.16-6.75-0.31-10.51-0.49c1-1.92,1.74-3.33,2.46-4.71
			c9.42,3.01,10.8,2.44,15.17-6.48c1.24-2.53,2.39-5.11,3.32-7.76c0.53-1.51,0.53-3.22,0.76-4.83c-0.43-0.17-0.86-0.33-1.28-0.5
			c-3.26,4.7-6.53,9.39-10.21,14.68c-0.63-1.55-1-2.01-0.95-2.41c1.33-11.18,2.61-22.38,4.24-33.52c0.15-1.02,2.45-2.25,3.89-2.48
			c6.21-0.96,12.52-1.37,18.71-2.47c1.89-0.33,3.5-2.23,5.23-3.42c-0.27-0.52-0.54-1.05-0.81-1.57c-7.81,0.38-15.63,0.76-24,1.16
			c1.15-3.29,2.47-5.87,2.84-8.58c0.21-1.51-0.82-4.32-1.89-4.67c-3.2-1.03-6.7-1.14-9.8-1.56c0,3.39,0.11,6.16-0.03,8.91
			c-0.18,3.83-1.85,5.9-6.16,5.75c-4.96-0.17-9.95,0.31-14.93,0.46c-4.12,0.12-5.71,2.8-5.42,6.25c0.35,4.01,3.58,2.32,5.85,2.12
			c5.84-0.51,11.68-1.11,17.08-1.62c-1.12,11.45-3.3,22.2-2.81,32.83c0.31,6.87-3.32,8.99-8.1,12.08c-0.78-3.81-1.4-7.68-0.74-11.32
			c1.21-6.67,3.31-13.17,4.78-19.8c1.04-4.68-0.53-6.97-5.16-6.7c-2.6,0.15-5.54,1.61-7.54,3.38c-2.85,2.52-4.98,5.86-7.43,8.87
			c0-4.62,1.49-9.76-0.44-12.34c-1.77-2.38-7.22-2.02-10.41-2.72c0,7.91-0.2,14.66,0.06,21.39c0.26,6.72-3.37,10.38-8.99,12.8
			c-2.98,1.28-5.76,1.69-7.87-1.56c-2.01-3.11-2.72-5.93,0.81-8.61c1.42-1.08,2.39-2.74,3.55-4.15c7.6-9.33,7.4-10.33-1.69-17.95
			c-2.72-2.28-5.15-2.33-6.58,0.44c-2.85,5.49-6.41,11.17-7.09,17.07c-0.68,5.94-3.66,9.72-6.41,14.08c-0.43-0.1-0.85-0.2-1.28-0.29
			c0.88-7.14,1.77-14.28,2.68-21.66c-1.76-0.7-3.94-1.2-5.65-2.38c-1-0.69-2-2.69-1.7-3.72c1.22-4.23-0.6-5.5-4.4-5.44
			c-2.09,0.03-4.18,0.01-5.02,0.01c-1.43,4.18-2.51,7.37-3.92,11.48c-1.39-1.77-1.68-2.18-2.01-2.55c-1.4-1.6-2.13-4.62-5.29-2.23
			c-3.17,2.39-0.62,3.87,0.73,5.6c3.97,5.11,4.17,15.96,0.47,21.25c-1.55,2.21-3.34,2.66-5.42,0.87c-3.84-3.31-5.65-7.8-4.37-12.5
			c1.46-5.36,4.31-10.33,6.19-15.6c0.52-1.45,0.35-4.33-0.54-4.88c-3-1.86-6.5-2.07-8.91,1.16c-4.84,6.49-7.41,13.69-5.68,21.92
			c1.22,5.81,0.45,7.3-5.82,10.17c-0.34-1.86-1-3.61-0.91-5.33c0.44-8.28,0.54-16.62,1.79-24.78c1.23-7.99,3.79-15.77,5.52-23.7
			c0.31-1.43-0.14-4.12-1.01-4.5c-3.13-1.39-6.58-2.04-10.19-3.05C107.14,39.78,106.79,42.06,106.3,45.24z M131.08,105.74
			c0,32.7,0,64.28,0,95.85c4.44,0,8.47,0,12.94,0c0-13.5,0-26.57,0-39.55c6.88-1.15,6.89-1.15,8.62,4.77
			c3.02,10.33,5.94,20.69,9.11,30.97c0.49,1.59,1.99,3.83,3.29,4.03c3.66,0.55,7.46,0.19,11.68,0.19c-0.45-1.82-0.65-2.91-0.98-3.97
			c-3.56-11.37-7.01-22.79-10.79-34.09c-1.23-3.67-1.37-6.17,2.22-8.77c2.24-1.62,3.95-4.6,4.85-7.32
			c3.29-9.91,3.08-19.93-0.69-29.7c-2.15-5.57-6.22-9.96-12.16-10.7C149.9,106.3,140.48,106.25,131.08,105.74z M72.67,158.98
			c-8.01,0-15.39,0-23.11,0c0,2.33-0.04,4.31,0.01,6.29c0.05,1.92,0.17,3.83,0.3,6.35c3.56,0,6.6,0,10.21,0
			c-0.43,4.28-0.28,8.02-1.26,11.44c-1.68,5.89-9.9,7.57-13.58,2.73c-1.55-2.04-2.22-5.2-2.26-7.86
			c-0.22-13.62-0.11-27.25-0.1-40.88c0-2.99-0.09-5.99,0.12-8.97c0.37-5.23,3.38-8.25,8.06-8.38c4.57-0.12,7.79,2.85,8.5,7.98
			c0.27,1.93,0.27,3.89,0.4,6.02c4.34,0,8.37,0,12.79,0c0-2.64,0.09-4.94-0.02-7.23c-0.58-12.19-9.6-20.89-21.56-20.86
			c-11.84,0.03-20.97,8.94-21.3,21.09c-0.21,7.97-0.05,15.95-0.05,23.93c0,2.58,0,5.16,0,7.69c-2.37,0.23-3.98,0.38-5.71,0.55
			c0,4.17,0,8.08,0,12.17c1.96,0.25,3.68,0.46,5.74,0.72c0,3.64-0.12,6.94,0.02,10.24c0.51,11.35,9.42,20.32,20.32,20.57
			c11.99,0.27,21.52-7.66,22.37-19.41C73.13,175.25,72.67,167.27,72.67,158.98z M289.48,158.6c-3.14-14.91-6.21-29.33-9.19-43.76
			c-1.92-9.28-1.85-9.3-11.43-8.55c-0.45,0.04-0.88,0.34-1.59,0.63c-6.54,31.46-13.1,62.99-19.71,94.76c4.98,0,9.05,0,13.31,0
			c3.88-21.51,7.71-42.71,11.54-63.92c0.32,0,0.63-0.01,0.95-0.01c3.91,21.3,7.83,42.61,11.75,63.96c4.52,0,8.58,0,13.22,0
			c-2.07-10.21-4.06-20.01-6.07-29.91c2.16-0.35,3.88-0.63,5.48-0.89c0-4.26,0-8.16,0-12.31C294.93,158.6,292.46,158.6,289.48,158.6
			z M220.52,137.07c-0.36-5.09-0.21-9.91-1.11-14.53c-2.27-11.73-10.59-17.73-22.59-16.86c-9.71,0.7-17.98,9.13-18.18,19.77
			c-0.35,19.25-0.35,38.52,0.03,57.77c0.19,9.98,6.88,17.33,15.9,19.07c10.04,1.94,19.65-2.34,23.15-11.91
			c2.3-6.28,2.36-13.38,3.5-20.57c-5.57,0-9.63,0-13.87,0c-0.34,4.48-0.37,8.79-1.06,12.98c-0.66,4.02-3.67,5.72-7.6,5.68
			c-3.76-0.04-5.75-2.26-6.37-5.76c-0.37-2.11-0.59-4.28-0.6-6.42c-0.06-8.96-0.09-17.93-0.01-26.9c0.06-7.46,0.15-14.93,0.49-22.38
			c0.19-4.24,2.08-7.44,6.98-7.33c4.67,0.11,6.74,3.19,7.35,7.32c0.48,3.24,0.51,6.54,0.76,10.05
			C211.63,137.07,215.68,137.07,220.52,137.07z M124.8,201.82c-0.22-1.83-0.27-2.98-0.5-4.09c-5.81-27.75-11.63-55.51-17.46-83.26
			c-1.91-9.1-1.92-9.1-11.18-8.23c-0.48,0.04-0.93,0.3-1.71,0.56c-6.54,31.46-13.11,63.03-19.74,94.92c4.93,0,9,0,13.32,0
			c3.87-21.43,7.7-42.58,11.52-63.74c0.34,0.02,0.68,0.03,1.01,0.05c3.91,21.26,7.81,42.52,11.72,63.78
			C116.38,201.82,120.32,201.82,124.8,201.82z M188.88,290.43c-5.71,0-10.54,0-15.97,0c4.05-12.62,7.9-24.59,12-37.37
			c-8.31,1.7-15.5,3.63-22.81,4.53c-5.92,0.73-8.16,3.56-9.31,9.18c-2.75,13.39-6.18,26.64-9.47,40.51c7.25,0,13.98,0,21.52,0
			c0,13.84,0,27.24,0,40.63c0.44,0.05,0.89,0.11,1.33,0.16C173.64,329.12,181.11,310.16,188.88,290.43z" />
          <path fill="#FFFFFF" d="M106.3,45.24c0.49-3.18,0.84-5.46,1.37-8.98c3.61,1.01,7.06,1.66,10.19,3.05c0.87,0.38,1.32,3.08,1.01,4.5
			c-1.73,7.93-4.3,15.71-5.52,23.7c-1.25,8.16-1.36,16.51-1.79,24.78c-0.09,1.72,0.57,3.47,0.91,5.33
			c6.27-2.86,7.04-4.35,5.82-10.17c-1.73-8.23,0.85-15.43,5.68-21.92c2.41-3.24,5.91-3.02,8.91-1.16c0.89,0.55,1.06,3.43,0.54,4.88
			c-1.88,5.27-4.74,10.24-6.19,15.6c-1.28,4.7,0.53,9.19,4.37,12.5c2.07,1.79,3.86,1.34,5.42-0.87c3.7-5.28,3.5-16.14-0.47-21.25
			c-1.35-1.73-3.9-3.21-0.73-5.6c3.17-2.39,3.89,0.64,5.29,2.23c0.33,0.37,0.62,0.78,2.01,2.55c1.4-4.11,2.49-7.3,3.92-11.48
			c0.84,0,2.93,0.03,5.02-0.01c3.8-0.06,5.62,1.21,4.4,5.44c-0.3,1.04,0.7,3.03,1.7,3.72c1.71,1.18,3.89,1.68,5.65,2.38
			c-0.91,7.39-1.8,14.52-2.68,21.66c0.43,0.1,0.85,0.2,1.28,0.29c2.76-4.36,5.73-8.14,6.41-14.08c0.68-5.9,4.24-11.58,7.09-17.07
			c1.44-2.77,3.87-2.72,6.58-0.44c9.09,7.62,9.29,8.62,1.69,17.95c-1.15,1.41-2.13,3.08-3.55,4.15c-3.53,2.68-2.82,5.51-0.81,8.61
			c2.11,3.25,4.89,2.84,7.87,1.56c5.63-2.42,9.25-6.08,8.99-12.8c-0.26-6.73-0.06-13.48-0.06-21.39c3.19,0.71,8.64,0.34,10.41,2.72
			c1.93,2.59,0.44,7.72,0.44,12.34c2.45-3,4.58-6.34,7.43-8.87c2-1.77,4.94-3.23,7.54-3.38c4.62-0.27,6.19,2.02,5.16,6.7
			c-1.47,6.63-3.57,13.13-4.78,19.8c-0.66,3.64-0.04,7.5,0.74,11.32c4.79-3.09,8.42-5.2,8.1-12.08
			c-0.49-10.63,1.69-21.38,2.81-32.83c-5.4,0.52-11.23,1.11-17.08,1.62c-2.27,0.2-5.5,1.89-5.85-2.12c-0.3-3.46,1.3-6.14,5.42-6.25
			c4.98-0.14,9.96-0.63,14.93-0.46c4.31,0.15,5.97-1.92,6.16-5.75c0.13-2.75,0.03-5.52,0.03-8.91c3.09,0.43,6.59,0.53,9.8,1.56
			c1.07,0.35,2.1,3.16,1.89,4.67c-0.37,2.71-1.69,5.3-2.84,8.58c8.37-0.41,16.18-0.78,24-1.16c0.27,0.52,0.54,1.05,0.81,1.57
			c-1.74,1.18-3.35,3.08-5.23,3.42c-6.19,1.1-12.49,1.5-18.71,2.47c-1.44,0.22-3.74,1.45-3.89,2.48
			c-1.62,11.14-2.9,22.34-4.24,33.52c-0.05,0.41,0.32,0.87,0.95,2.41c3.68-5.29,6.94-9.99,10.21-14.68c0.43,0.17,0.86,0.33,1.28,0.5
			c-0.24,1.62-0.23,3.32-0.76,4.83c-0.93,2.65-2.08,5.24-3.32,7.76c-4.37,8.91-5.75,9.49-15.17,6.48c-0.72,1.38-1.46,2.8-2.46,4.71
			c3.75,0.17,6.99,0.32,10.51,0.49c0,31.81,0,63.31,0,95.22c-4.04,0-8.09,0-12.83,0c0-30.83,0-61.63,0-92.33
			c-12.76-1.65-15.94-5.49-15.01-17.88c0.39-5.26,1.23-10.5,1.85-15.56c-4.86,4.21-8.17,13.88-8.92,27.57
			c-3.98-1.73-7.56-3.29-11.56-5.03c-8.46,6.03-15.46,5.25-23.79-3.49c-0.63,0.97-1.26,1.85-1.79,2.78
			c-3.58,6.35-9.02,6.63-13.1,0.46c-3.99-6.04-5.64-12.68-4.1-19.92c0.88-4.12,0.86-4.12-3.16-5.96c-2.92,2.85-4.28,6.02-2.72,10.18
			c0.62,1.65,0.8,3.62,0.62,5.38c-0.55,5.44-2.03,10.8-7.3,13.38c-5.94,2.9-11.26,0.24-16.31-3.56c-0.47,0.3-1.05,0.57-1.5,0.98
			c-6.7,6.01-15.18,4.39-17.49-4.28c-1.74-6.55-1.71-13.73-1.51-20.61c0.18-6.2,1.65-12.36,2.59-18.79
			c-4.13-0.33-7.77-1.05-11.31-0.71c-1.65,0.16-4,2.11-4.45,3.7c-0.97,3.41-0.92,7.11-1.34,11.22c2.84-0.23,5.22-0.42,7.61-0.62
			c-1.09,5.93-2.54,7.16-8.39,6.51c-0.99,4.37-1.93,8.83-3.03,13.26c-1.32,5.3-2.65,10.61-4.25,15.83c-0.4,1.3-1.84,2.29-3.92,2.97
			c0-5.35,0-10.69,0-16.04c0-5.28,0-10.56,0-16.24c-6.83-0.44-13.4-0.87-19.97-1.29c5.8-4.83,13.3-2.56,19.96-4.97
			c0-4.79,0-9.53,0-14.13c-9.44-1.25-19.01-2.51-28.57-3.77c0.04-0.66,0.09-1.33,0.13-1.99C66.91,47.52,86.48,46.39,106.3,45.24z
			 M177.75,83.62c0.58,0.31,1.16,0.61,1.73,0.92c2.17-3.88,4.34-7.77,6.51-11.65c-0.68-0.37-1.37-0.73-2.05-1.1
			C181.88,75.74,179.82,79.68,177.75,83.62z" />
          <path fill="#FFFFFF" d="M131.08,105.74c9.41,0.52,18.83,0.56,28.11,1.71c5.93,0.74,10.01,5.13,12.16,10.7
			c3.77,9.77,3.98,19.79,0.69,29.7c-0.9,2.72-2.61,5.7-4.85,7.32c-3.59,2.6-3.45,5.1-2.22,8.77c3.78,11.3,7.23,22.71,10.79,34.09
			c0.33,1.05,0.54,2.15,0.98,3.97c-4.22,0-8.02,0.36-11.68-0.19c-1.3-0.19-2.8-2.44-3.29-4.03c-3.18-10.28-6.1-20.64-9.11-30.97
			c-1.73-5.93-1.74-5.92-8.62-4.77c0,12.98,0,26.04,0,39.55c-4.48,0-8.5,0-12.94,0C131.08,170.02,131.08,138.44,131.08,105.74z
			 M144.23,148.09c12.65,2.21,16.29-0.66,16.5-12.75c0.02-1,0-1.99-0.02-2.99c-0.28-11.29-5.04-14.77-16.48-11.93
			C144.23,129.64,144.23,138.89,144.23,148.09z" />
          <path fill="#FFFFFF" d="M72.67,158.98c0,8.29,0.46,16.27-0.11,24.18c-0.85,11.75-10.38,19.68-22.37,19.41
			c-10.9-0.24-19.82-9.22-20.32-20.57c-0.15-3.29-0.02-6.6-0.02-10.24c-2.06-0.26-3.78-0.48-5.74-0.72c0-4.1,0-8,0-12.17
			c1.73-0.17,3.33-0.32,5.71-0.55c0-2.53,0-5.11,0-7.69c0-7.98-0.16-15.96,0.05-23.93c0.32-12.15,9.45-21.06,21.3-21.09
			c11.96-0.03,20.98,8.67,21.56,20.86c0.11,2.29,0.02,4.59,0.02,7.23c-4.42,0-8.45,0-12.79,0c-0.13-2.12-0.14-4.09-0.4-6.02
			c-0.71-5.14-3.93-8.1-8.5-7.98c-4.68,0.12-7.69,3.15-8.06,8.38c-0.21,2.98-0.12,5.98-0.12,8.97c-0.01,13.63-0.12,27.25,0.1,40.88
			c0.04,2.67,0.71,5.82,2.26,7.86c3.68,4.84,11.9,3.16,13.58-2.73c0.98-3.42,0.83-7.16,1.26-11.44c-3.6,0-6.64,0-10.21,0
			c-0.12-2.51-0.25-4.43-0.3-6.35c-0.05-1.98-0.01-3.96-0.01-6.29C57.28,158.98,64.66,158.98,72.67,158.98z" />
          <path fill="#FFFFFF" d="M289.48,158.6c2.98,0,5.45,0,8.26,0c0,4.15,0,8.05,0,12.31c-1.6,0.26-3.32,0.54-5.48,0.89
			c2.01,9.91,4,19.7,6.07,29.91c-4.64,0-8.7,0-13.22,0c-3.92-21.35-7.83-42.66-11.75-63.96c-0.32,0-0.63,0.01-0.95,0.01
			c-3.83,21.2-7.66,42.41-11.54,63.92c-4.26,0-8.33,0-13.31,0c6.61-31.77,13.17-63.31,19.71-94.76c0.7-0.29,1.13-0.6,1.59-0.63
			c9.57-0.75,9.51-0.73,11.43,8.55C283.26,129.27,286.34,143.69,289.48,158.6z" />
          <path fill="#FFFFFF" d="M220.52,137.07c-4.84,0-8.89,0-13.22,0c-0.25-3.51-0.28-6.81-0.76-10.05c-0.62-4.13-2.69-7.21-7.35-7.32
			c-4.9-0.12-6.78,3.09-6.98,7.33c-0.34,7.45-0.43,14.92-0.49,22.38c-0.08,8.96-0.06,17.93,0.01,26.9c0.02,2.14,0.23,4.31,0.6,6.42
			c0.61,3.5,2.6,5.73,6.37,5.76c3.93,0.04,6.94-1.66,7.6-5.68c0.69-4.2,0.72-8.5,1.06-12.98c4.24,0,8.3,0,13.87,0
			c-1.14,7.19-1.2,14.29-3.5,20.57c-3.51,9.57-13.11,13.85-23.15,11.91c-9.02-1.74-15.7-9.1-15.9-19.07
			c-0.37-19.25-0.38-38.52-0.03-57.77c0.19-10.64,8.47-19.07,18.18-19.77c12-0.86,20.31,5.14,22.59,16.86
			C220.31,127.16,220.16,131.98,220.52,137.07z" />
          <path fill="#FFFFFF" d="M124.8,201.82c-4.48,0-8.43,0-13.01,0c-3.91-21.27-7.81-42.52-11.72-63.78c-0.34-0.02-0.68-0.03-1.01-0.05
			c-3.83,21.16-7.65,42.31-11.52,63.74c-4.32,0-8.39,0-13.32,0c6.63-31.89,13.2-63.46,19.74-94.92c0.77-0.26,1.23-0.52,1.71-0.56
			c9.25-0.87,9.26-0.87,11.18,8.23c5.83,27.75,11.65,55.5,17.46,83.26C124.54,198.84,124.59,199.99,124.8,201.82z" />
          <path fill="#FFFFFF" d="M188.88,290.43c-7.77,19.73-15.24,38.68-22.71,57.64c-0.44-0.05-0.89-0.11-1.33-0.16
			c0-13.39,0-26.79,0-40.63c-7.54,0-14.27,0-21.52,0c3.29-13.87,6.73-27.12,9.47-40.51c1.15-5.61,3.39-8.45,9.31-9.18
			c7.31-0.9,14.5-2.83,22.81-4.53c-4.1,12.78-7.94,24.74-12,37.37C178.34,290.43,183.17,290.43,188.88,290.43z" />
          <path d="M177.75,83.62c2.06-3.94,4.13-7.88,6.19-11.83c0.68,0.37,1.37,0.73,2.05,1.1c-2.17,3.88-4.34,7.77-6.51,11.65
			C178.91,84.24,178.33,83.93,177.75,83.62z" />
          <path d="M144.23,148.09c0-9.2,0-18.45,0-27.67c11.44-2.84,16.19,0.63,16.48,11.93c0.03,1,0.04,1.99,0.02,2.99
			C160.53,147.43,156.89,150.3,144.23,148.09z" />
        </g>
      </g>

    </svg>

  )
}

export default FlorentGarciaLogo
