import React, { useState, useRef, useEffect } from 'react';
import IconSearchSpotify from 'components/icons/IconSearchSpotify';
import IconLoading from 'components/icons/IconLoading';
import GenericError from 'components/GenericError';
import Tippy from '@tippyjs/react';
import api from 'services/api';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { SpotifyAlbum, SpotifyArtist, SpotifyImage, SpotifyTrack } from 'shared-types';
import btNot from 'styles/assets/img/bt-not.svg';
import usePrevious from 'hooks/use-previous';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMyTracksStore } from 'features/tracks/MyTracksState'

interface SearchSpotifyProps {
  selectedArtists: SpotifyArtist[];
  selectedTracks: SpotifyTrack[];
  selectedAlbums: SpotifyAlbum[];
  toggleArtistSelection: (track: SpotifyArtist) => void;
  toggleTrackSelection: (track: SpotifyTrack) => void;
  toggleAlbumSelection: (track: SpotifyAlbum) => void;
}

function getCoverImageOrDefault(fallback: any, images?: SpotifyImage[] | null) {
  if (images && images.length > 0) {
    return images[0].url;
  } else {
    return fallback;
  }
}

function SearchSpotify({
  selectedArtists,
  selectedTracks,
  selectedAlbums,
  toggleArtistSelection,
  toggleTrackSelection,
  toggleAlbumSelection,
}: SearchSpotifyProps) {
  const [searchInput, setSearchInput] = useState<string>('');

  const [delayedValue] = useDebounce(searchInput, 500);
  const results = useQuery(
    ['/api/spotify', delayedValue],
    () => (delayedValue && delayedValue !== '' ? api.searchOnSpotify(delayedValue) : undefined),
    {
      staleTime: Infinity,
    },
  );
  const artist = (results.data && results.data.artists.items) || [];
  const tracks = (results.data && results.data.tracks.items) || [];
  const albums = (results.data && results.data.albums.items) || [];

  const [selectedArtistsTrackCount, setSelectedArtistsTrackCount] = useState<
    {
      artistId: string;
      albumsTrackCount: number;
    }[]
  >([]);
  const previewSelectedArtists = usePrevious(selectedArtists);
  useEffect(() => {
    if (selectedArtists.length > (previewSelectedArtists || []).length) {
      const computeSelectedArtistsTrackCount = async () => {
        const allArtistTrackCount = await Promise.all(
          selectedArtists.map(async (artist) => {
            const trackCountResponse = await api.getArtistAlbumsTrackCount(artist.id);
            return { artistId: artist.id, albumsTrackCount: trackCountResponse.data.albumsTrackCount };
          }),
        );
        setSelectedArtistsTrackCount(allArtistTrackCount);
      };
      computeSelectedArtistsTrackCount();
    }
  }, [selectedArtists]);

  const selectedTrackCount =
    selectedTracks.length +
    selectedAlbums.map((alb) => alb.total_tracks).reduce((a, b) => a + b, 0) +
    selectedArtists
      .map((artist) => selectedArtistsTrackCount.find((s) => s.artistId === artist.id)?.albumsTrackCount || 0)
      .reduce((a, b) => a + b, 0);

  // detect bottom of list
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const endOfScrollDiv = document.querySelector('.endOfScroll');

      if (endOfScrollDiv && endOfScrollDiv.getBoundingClientRect().top <= window.innerHeight) {
        setIsEndOfScroll(true);
      } else {
        setIsEndOfScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Nettoyage de l'écouteur lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Get focus of the search field input
  const inputRef = useRef<HTMLInputElement>(null);
  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      clearInput();
    }
  };

  return (
    <div>
      {/*Spotify search*/}
      <div className="mt-6 relative">
        <IconSearchSpotify className="h-6 absolute translate-y-1/2 ml-4" />{' '}
        <input
          onChange={(event) => setSearchInput(event.target.value)}
          type="search"
          className="w-full !rounded-full mb-0 px-6 !pl-12"
          placeholder="Rechercher sur Spotify..."
          ref={inputRef}
          autoFocus
        />
      </div>
      {/*Results*/}
      <div className={results.isLoading ? 'px-6 py-6 text-center' : 'hidden'}>
        <IconLoading className="w-full h-10 mb-4" />
        <div>Recherche en cours, veuillez patienter...</div>
      </div>
      {results.isError && <GenericError />}
      {/*My Selection*/}
      {/*Sticky footer*/}
      <div className={selectedTrackCount > 0 ? '' : 'hidden'}>
        <div className="xl:max-w-screen-xl flex mx-auto w-full px-6 py-4 mb-3 rounded bg-green-500 text-white">
          Nombre de titres correspondant à ma sélection :&nbsp;
          <strong>
            {selectedTrackCount} titre{selectedTrackCount > 1 && 's'}
          </strong>
        </div>
      </div>
      <div className={tracks.length > 0 || albums.length > 0 || artist.length > 0 ? 'results' : 'hidden'}>
        <div className="results-artist shadow overflow-hidden border-b border-gray-200 rounded-t-lg">
          <div className="bg-gray-800 text-white font-bold px-6 py-4 inline-flex w-full">
            <FontAwesomeIcon icon={duotone('user-music')} className="mr-2 h-5" />
            Artistes &mdash; <em className="font-normal">Tous les titres du profil sélectionné seront ajoutés</em>
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {artist.map((item: SpotifyArtist) => {
                const isSelectedArtist = selectedArtists.find((sa) => sa.id === item.id) !== undefined;
                return (
                  <label
                    key={item.id}
                    htmlFor={`artist` + item.id}
                    className="cursor-pointer relative font-normal w-full inline-table m-0 duration-200 hover:bg-gray-50 active:bg-green-200 group"
                  >
                    <tr
                      className={`${
                        isSelectedArtist ? 'duration-500 bg-green-50 hover:bg-red-50 active:bg-red-100' : ''
                      }`}
                    >
                      <td className="px-6 py-4">
                        <div
                          className={`${
                            isSelectedArtist
                              ? 'hidden'
                              : 'hidden w-full h-full text-green-500 justify-end top-0 right-16 m-0 p-0 lg:flex absolute opacity-0 group-hover:opacity-100 translate-x-2 group-hover:hover:translate-x-0 transition ease-in-out'
                          }`}
                        >
                          <em className="self-center z-10 text-xs">Ajouter cet artiste à ma sélection</em>
                        </div>
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-14 h-14 relative overflow-hidden">
                            <img
                              className="w-14 h-14 bg-primary-50 object-cover rounded-full border-4 border-primary-100 p-0.5"
                              src={getCoverImageOrDefault(btNot, item.images)}
                              alt={item.name}
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-lg leading-tight font-bold">
                              <span className="mr-2">{item.name}</span>
                              <Tippy content="Voir sur Spotify">
                                <a href={item.uri} target="_blank" rel="noreferrer" className="align-text-bottom">
                                  <FontAwesomeIcon
                                    icon={duotone('play-circle')}
                                    className="!w-4 align-middle text-primary-900 hover:text-primary-500 inline-flex"
                                  />
                                </a>
                              </Tippy>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap align-middle w-auto">
                        <div className="flex h-5 justify-end">
                          <input
                            id={`artist` + item.id}
                            type="checkbox"
                            checked={isSelectedArtist}
                            onChange={() => toggleArtistSelection(item)}
                            className="!h-6 !w-6 !border-2"
                          />
                        </div>
                      </td>
                    </tr>
                  </label>
                );
              })}
            </tbody>
          </table>
          <div className={artist.length === 0 ? 'px-6 py-6 text-center' : 'hidden'}>
            <div>Vous n'avez sélectionné aucun artiste</div>
          </div>
        </div>
        <div className="results-tracks shadow overflow-hidden border-b border-gray-200">
          <div className="bg-gray-800 text-white font-bold px-6 py-4 inline-flex w-full">
            <FontAwesomeIcon icon={duotone('list-music')} className="mr-2 h-5" />
            Titres
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {tracks.map((item: SpotifyTrack) => {
                const isSelectedTrack = selectedTracks.find((st) => st.id === item.id) !== undefined;
                return (
                  <label
                    key={item.id}
                    htmlFor={`track` + item.id}
                    className="cursor-pointer relative font-normal w-full inline-table m-0 duration-200 hover:bg-gray-50 active:bg-green-200 group"
                  >
                    <tr
                      className={`${
                        isSelectedTrack ? 'duration-500 bg-green-50 hover:bg-red-50 active:bg-red-100' : ''
                      }`}
                    >
                      <td className="px-6 py-4">
                        <div
                          className={`${
                            isSelectedTrack
                              ? 'hidden'
                              : 'hidden w-full h-full text-green-500 justify-end top-0 right-16 m-0 p-0 lg:flex absolute opacity-0 group-hover:opacity-100 translate-x-2 group-hover:hover:translate-x-0 transition ease-in-out'
                          }`}
                        >
                          <em className="self-center z-10 text-xs">Ajouter ce titre à ma sélection</em>
                        </div>
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-14 h-14 overflow-hidden">
                            <img
                              className="w-14 h-14 object-cover rounded-full border-2 border-gray-300 p-0.5"
                              src={getCoverImageOrDefault(btNot, item.album.images)}
                              alt={item.name}
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-lg leading-tight font-bold">
                              <span className="mr-2">{item.name}</span>
                              <Tippy content="Lire sur Spotify">
                                <a href={item.uri} target="_blank" rel="noreferrer" className="align-text-bottom">
                                  <FontAwesomeIcon
                                    icon={duotone('play-circle')}
                                    className="!w-4 align-middle text-primary-900 hover:text-primary-500 inline-flex"
                                  />
                                </a>
                              </Tippy>
                            </div>
                            <span className="text-sm text-gray-600">{item.artists.map((e) => e.name).join(' - ')}</span>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap align-middle w-auto">
                        <div className="flex h-5 justify-end">
                          <input
                            id={`track` + item.id}
                            type="checkbox"
                            checked={isSelectedTrack}
                            onChange={() => toggleTrackSelection(item)}
                            className="!h-6 !w-6 !border-2"
                          />
                        </div>
                      </td>
                    </tr>
                  </label>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="results-albums shadow overflow-hidden border-b border-gray-200 rounded-b-lg">
          <div className="bg-gray-800 text-white font-bold px-6 py-4 inline-flex w-full">
            <FontAwesomeIcon icon={duotone('album-collection')} className="mr-2 h-5" />
            Albums
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {albums.map((item: SpotifyAlbum) => {
                const isSelectedAlbum = selectedAlbums.find((sa) => sa.id === item.id) !== undefined;
                return (
                  <label
                    key={item.id}
                    htmlFor={`album` + item.id}
                    className="cursor-pointer relative font-normal w-full inline-table m-0 duration-200 hover:bg-gray-50 active:bg-green-200 group"
                  >
                    <tr
                      className={`${
                        isSelectedAlbum ? 'duration-500 bg-green-50 hover:bg-red-50 active:bg-red-100' : ''
                      }`}
                    >
                      <td className="px-6 py-4">
                        <div
                          className={`${
                            isSelectedAlbum
                              ? 'hidden'
                              : 'hidden w-full h-full text-green-500 justify-end top-0 right-16 m-0 p-0 lg:flex absolute opacity-0 group-hover:opacity-100 translate-x-2 group-hover:hover:translate-x-0 transition ease-in-out'
                          }`}
                        >
                          <em className="self-center z-10 text-xs">Ajouter cet album à ma sélection</em>
                        </div>
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-14 h-14 overflow-hidden z-10">
                            <img
                              className="w-14 h-14 object-cover border-2 border-gray-300 bg-white p-0.5 "
                              src={getCoverImageOrDefault(btNot, item.images)}
                              alt={item.name}
                            />
                          </div>
                          <div className="w-12 h-12 block bg-gray-700 absolute rounded-full translate-x-4 z-0 border-2 border-black shadow-black/50 shadow-md"></div>
                          <div className="ml-4">
                            <div className="text-lg leading-tight font-bold">
                              <span className="mr-2">{item.name}</span>
                              <Tippy content="Lire sur Spotify">
                                <a href={item.uri} target="_blank" rel="noreferrer" className="align-text-bottom">
                                  <FontAwesomeIcon
                                    icon={duotone('play-circle')}
                                    className="!w-4 align-middle text-primary-900 hover:text-primary-500 inline-flex"
                                  />
                                </a>
                              </Tippy>
                            </div>
                            <span className="text-sm text-gray-600">
                              {item.artists.map((e) => e.name).join(' - ')}&nbsp;
                              <em className="opacity-50">
                                ({item.total_tracks} titre{item.total_tracks > 1 && 's'})
                              </em>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap align-middle w-auto">
                        <div className="flex h-5 justify-end">
                          <input
                            id={`album` + item.id}
                            type="checkbox"
                            checked={isSelectedAlbum}
                            onChange={() => toggleAlbumSelection(item)}
                            className="!h-6 !w-6 !border-2"
                          />
                        </div>
                      </td>
                    </tr>
                  </label>
                );
              })}
            </tbody>
          </table>
          <div className={albums.length == 0 ? 'px-6 py-6 text-center' : 'hidden'}>
            <div>Vous n'avez sélectionné aucun album</div>
          </div>
        </div>
      </div>

      <div className="endOfScroll"></div>

      {/*My Selection*/}
      {/*Sticky footer*/}
      <div className={selectedTrackCount > 0 ? 'fixed w-full z-10 left-0 bottom-0' : 'hidden'}>
        <div className={isEndOfScroll ? 'hidden' : 'py-4 bg-green-500 mx-auto text-white font-bold flex w-full'}>
          <div className="xl:max-w-screen-xl flex mx-auto w-full px-16">
            Ma sélection{' '}
            <span className="font-normal">
              &nbsp;&mdash;&nbsp;{selectedTrackCount} titre{selectedTrackCount > 1 && 's'}
            </span>
          </div>
        </div>
      </div>
      {/*Summary table of selected items*/}
      <div className={selectedTrackCount > 0 ? 'selectedTracks mt-4' : 'hidden'}>
        <div className="selected-tracks shadow overflow-hidden border-b border-gray-200 rounded-lg">
          <div className="bg-green-500 text-white font-bold px-6 py-4 inline-flex w-full">
            Ma sélection{' '}
            <span className="font-normal">
              &nbsp;&mdash;&nbsp;{selectedTrackCount} titre{selectedTrackCount > 1 && 's'}
            </span>
          </div>
          <div className="divide-y divide-gray-200">
            {/*Selected Artists*/}
            <table className="min-w-full divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200 border-t border-b-2 border-t-gray-200">
                {selectedArtists.map((item) => {
                  const isSelectedArtist = selectedArtists.indexOf(item) > -1;
                  return (
                    <label
                      key={item.id}
                      htmlFor={`artist` + item.id}
                      className="cursor-pointer relative font-normal w-full inline-table m-0 duration-200 hover:bg-gray-50 group"
                    >
                      <tr
                        className={`${
                          isSelectedArtist ? 'duration-500 bg-green-50 hover:bg-red-50 active:bg-red-100' : ''
                        }`}
                      >
                        <td className="px-6 py-4">
                          <div
                            className={`${
                              isSelectedArtist
                                ? 'hidden w-full h-full text-red-500 justify-end top-0 right-16 m-0 p-0 lg:flex absolute opacity-0 group-hover:opacity-100 translate-x-2 group-hover:hover:translate-x-0 transition ease-in-out'
                                : 'hidden'
                            }`}
                          >
                            <em className="self-center z-10 text-xs">Supprimer de ma sélection</em>
                          </div>
                          <div className="flex items-center">
                            <div className="flex-shrink-0 w-14 h-14 relative overflow-hidden z-10">
                              <img
                                className="w-14 h-14 bg-primary-50 object-cover rounded-full border-4 border-primary-100 p-0.5"
                                src={getCoverImageOrDefault(btNot, item.images)}
                                alt={item.name}
                              />
                            </div>
                            <div className="ml-4">
                              <div className="text-lg leading-tight font-bold">
                                <span className="mr-2">{item.name}</span>
                                <Tippy content="Voir sur Spotify">
                                  <a href={item.uri} target="_blank" rel="noreferrer" className="align-text-bottom">
                                    <FontAwesomeIcon
                                      icon={duotone('play-circle')}
                                      className="!w-4 align-middle text-primary-900 hover:text-primary-500 inline-flex"
                                    />
                                  </a>
                                </Tippy>
                              </div>
                              <span className="text-sm text-gray-600">
                                {item.name}
                                <em className="opacity-50">
                                  {/*({item.total_tracks} titre{item.total_tracks > 1 && 's'})*/}
                                </em>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap align-middle w-auto">
                          <div className="flex h-5 justify-end">
                            <input
                              id={`artist` + item.id}
                              type="checkbox"
                              checked={isSelectedArtist}
                              onChange={() => toggleArtistSelection(item)}
                              className="!h-6 !w-6 !border-2"
                            />
                          </div>
                        </td>
                      </tr>
                    </label>
                  );
                })}
              </tbody>
            </table>
            {/*Selected Title*/}
            <table className="min-w-full divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200 border-t border-b-2 border-t-gray-200">
                {selectedTracks.map((item) => {
                  const isSelectedTrack = selectedTracks.indexOf(item) > -1;
                  return (
                    <label
                      key={item.id}
                      htmlFor={`track` + item.id}
                      className="cursor-pointer relative font-normal w-full inline-table m-0 duration-200 hover:bg-gray-50 group"
                    >
                      <tr
                        className={`${
                          isSelectedTrack ? 'duration-500 bg-green-50 hover:bg-red-50 active:bg-red-100' : ''
                        }`}
                      >
                        <td className="px-6 py-4">
                          <div
                            className={`${
                              isSelectedTrack
                                ? 'hidden w-full h-full text-red-500 justify-end top-0 right-16 m-0 p-0 lg:flex absolute opacity-0 group-hover:opacity-100 translate-x-2 group-hover:hover:translate-x-0 transition ease-in-out'
                                : 'hidden'
                            }`}
                          >
                            <em className="self-center z-10 text-xs">Supprimer de ma sélection</em>
                          </div>
                          <div className="flex items-center">
                            <div className="flex-shrink-0 w-14 h-14 overflow-hidden">
                              <img
                                className="w-14 h-14 object-cover rounded-full border-2 border-gray-300 p-0.5"
                                src={getCoverImageOrDefault(btNot, item.album.images)}
                                alt={item.name}
                              />
                            </div>
                            <div className="ml-4">
                              <div className="text-lg leading-tight font-bold">
                                <span className="mr-2">{item.name}</span>
                                <Tippy content="Lire sur Spotify">
                                  <a href={item.uri} target="_blank" rel="noreferrer" className="align-text-bottom">
                                    <FontAwesomeIcon
                                      icon={duotone('play-circle')}
                                      className="!w-4 align-middle text-primary-900 hover:text-primary-500 inline-flex"
                                    />
                                  </a>
                                </Tippy>
                              </div>
                              <span className="text-sm text-gray-600">
                                {item.artists.map((e) => e.name).join(' - ')}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap align-middle w-auto">
                          <div className="flex h-5 justify-end">
                            <input
                              id={`track` + item.id}
                              type="checkbox"
                              checked={isSelectedTrack}
                              onChange={() => toggleTrackSelection(item)}
                              className="!h-6 !w-6 !border-2"
                            />
                          </div>
                        </td>
                      </tr>
                    </label>
                  );
                })}
              </tbody>
            </table>
            {/*Selected Albums*/}
            <table className="min-w-full divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200 border-t border-t-gray-200">
                {selectedAlbums.map((item) => {
                  const isSelectedAlbum = selectedAlbums.indexOf(item) > -1;
                  return (
                    <label
                      key={item.id}
                      htmlFor={`album` + item.id}
                      className="cursor-pointer relative font-normal w-full inline-table m-0 duration-200 hover:bg-gray-50 group"
                    >
                      <tr
                        className={`${
                          isSelectedAlbum ? 'duration-500 bg-green-50 hover:bg-red-50 active:bg-red-100' : ''
                        }`}
                      >
                        <td className="px-6 py-4">
                          <div
                            className={`${
                              isSelectedAlbum
                                ? 'hidden w-full h-full text-red-500 justify-end top-0 right-16 m-0 p-0 lg:flex absolute opacity-0 group-hover:opacity-100 translate-x-2 group-hover:hover:translate-x-0 transition ease-in-out'
                                : 'hidden'
                            }`}
                          >
                            <em className="self-center z-10 text-xs">Supprimer de ma sélection</em>
                          </div>
                          <div className="flex items-center">
                            <div className="flex-shrink-0 w-14 h-14 overflow-hidden z-10">
                              <img
                                className="w-14 h-14 object-cover border-2 border-gray-300 bg-white p-0.5 "
                                src={getCoverImageOrDefault(btNot, item.images)}
                                alt={item.name}
                              />
                            </div>
                            <div className="w-12 h-12 block bg-gray-700 absolute rounded-full translate-x-4 z-0 border-2 border-black shadow-black/50 shadow-md"></div>
                            <div className="ml-4">
                              <div className="text-lg leading-tight font-bold">
                                <span className="mr-2">{item.name}</span>
                                <Tippy content="Lire sur Spotify">
                                  <a href={item.uri} target="_blank" rel="noreferrer" className="align-text-bottom">
                                    <FontAwesomeIcon
                                      icon={duotone('play-circle')}
                                      className="!w-4 align-middle text-primary-900 hover:text-primary-500 inline-flex"
                                    />
                                  </a>
                                </Tippy>
                              </div>
                              <span className="text-sm text-gray-600">
                                {item.artists.map((e) => e.name).join(' - ')}&nbsp;
                                <em className="opacity-50">
                                  ({item.total_tracks} titre{item.total_tracks > 1 && 's'})
                                </em>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap align-middle w-auto">
                          <div className="flex h-5 justify-end">
                            <input
                              id={`album` + item.id}
                              type="checkbox"
                              checked={isSelectedAlbum}
                              onChange={() => toggleAlbumSelection(item)}
                              className="!h-6 !w-6 !border-2"
                            />
                          </div>
                        </td>
                      </tr>
                    </label>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/*Message about collaboration & featuring*/}
      <div
        className={
          selectedTrackCount > 0 ? 'message shadow bg-blue-900 text-white flex-col items-start mt-6' : 'hidden'
        }
      >
        <p>
          👋 <strong>Vous avez collaboré avec d'autres artistes ?</strong> N'oubliez pas d'ajouter ces titres !
        </p>
        <button
          className="button outline !border-white hover:!text-white/50 hover:!border-white/50"
          onClick={handleClick}
        >
          Ajouter d'autres titres
        </button>
      </div>
    </div>
  );
}

export default SearchSpotify;
