import React from 'react';
import IconFileMusic from 'components/icons/IconFileMusic';
import TrackDropzone from 'components/TrackDropzone';
import { FileStatusChangeHandler, IFileWithMeta } from '../../components/Dropzone';
import { isNotBlank } from '../../utils/string-utils';

interface TrackUpload {
  onUpdate: (tracks: IFileWithMeta[]) => void;
  initialFiles?: IFileWithMeta[];
}

function TrackUpload({ onUpdate, initialFiles }: TrackUpload) {
  const handleOnFileStatusChange: FileStatusChangeHandler = (data) => {
    onUpdate(data.allFiles);
  };
  return (
    <>
      <p>Envoyez vos fichiers directement depuis votre ordinateur.</p>
      <div className="mt-6">
        <TrackDropzone
          uploadUrl="tracks/upload"
          onFileStatusChange={handleOnFileStatusChange}
          initialFiles={initialFiles}
        />
      </div>
    </>
  );
}

export default TrackUpload;
