import React, { ReactElement, useEffect, useRef, useState } from 'react';
import CreateAccountForm, { CreateAccountFormSchema, defaultValues } from './CreateAccountForm';
import Greeting from './Greeting';
import api from 'services/api';
import {
  CreateSubscriptionRequest,
  InvoicePaymentIntent,
  RegisterUserRequestDTO,
  SubscriptionPriceDTO,
} from 'shared-types';
import AddTrack, { TrackData } from './AddTrack';
import Payment from './Payment';
import { StripeData } from '../../components/CreditCardForm';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { ApiError } from '../../utils/errors';
import { CustomEvent } from '@piwikpro/react-piwik-pro';
import { useQuery } from 'react-query';
import Loader from 'components/Loader';

const DEFAULT_ENGAGEMENT = 12;
const DEFAULT_FORMULA = 3;

const isValidEngagement = (engagement: number, prices?: SubscriptionPriceDTO[]) => {
  return (prices || []).find((p) => p.engagement === engagement) !== undefined;
};

const isValidFormula = (formula: number, prices?: SubscriptionPriceDTO[]) => {
  return (prices || []).find((p) => Number(p.formula) === formula) !== undefined;
};

export interface RegisterWizardProps {
  initialEngagement?: string;
  initialFormula?: string;
  initialSource?: string;
}

export default function RegisterWizard({
  initialEngagement,
  initialFormula,
  initialSource,
}: RegisterWizardProps): ReactElement {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<StripeError | ApiError | unknown>(undefined);
  const createSubscriptionResponseRef = useRef<InvoicePaymentIntent | undefined>(undefined);
  const [accountFormData, setAccountFormData] = useState<CreateAccountFormSchema | undefined>({
    ...defaultValues,
    source: initialSource || '',
  });
  const [trackData, setTrackData] = useState<TrackData>({
    selectedArtists: [],
    selectedTracks: [],
    selectedAlbums: [],
    uploadedTracks: [],
  });

  const {
    data: prices,
    isLoading: isLoadingPrices,
    isError: isErrorPrices,
  } = useQuery(
    ['/api/subscription/prices'],
    () => {
      return api.fetchSubscriptionPrices({ source: 'one-offer' });
    },
    {
      staleTime: Infinity,
      onSuccess: (prices) => {
        if (
          initialEngagement &&
          Number.isSafeInteger(parseInt(initialEngagement)) &&
          isValidEngagement(parseInt(initialEngagement), prices) &&
          parseInt(initialEngagement)
        ) {
          setEngagement(parseInt(initialEngagement));
        }
        if (
          initialFormula &&
          Number.isSafeInteger(parseInt(initialFormula)) &&
          isValidFormula(parseInt(initialFormula), prices) &&
          parseInt(initialFormula)
        ) {
          setFormula(parseInt(initialFormula));
        }
      },
    },
  );

  const [engagement, setEngagement] = useState<number>(DEFAULT_ENGAGEMENT);
  const [formula, setFormula] = useState<number>(DEFAULT_FORMULA);

  useEffect(() => {
    const scrollToTop = () => {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 20);
    };
    scrollToTop();
    CustomEvent.trackEvent('Register', 'Update Step', 'step', step);
  }, [step]);

  const savePartialUserAndGoToPayment = async (data: TrackData) => {
    if (accountFormData) {
      api.registerPartial({
        user: {
          firstName: accountFormData.firstName,
          lastName: accountFormData.lastName,
          email: accountFormData.email,
          source: initialSource || accountFormData.source,
        },
        music: {
          spotifyArtistIds: data.selectedArtists.map((t) => t.id),
          spotifyTrackIds: data.selectedTracks.map((t) => t.id),
          spotifyAlbumIds: data.selectedAlbums.map((a) => a.id),
          uploadedTracks: [],
        },
      });
    }
    setTrackData(data);
    setStep(step + 1);
  };

  const subscribe = async ({ stripe, elements }: StripeData, priceId: string, priceInCent: number) => {
    setError(undefined);
    setLoading(true);

    try {
      if (accountFormData) {
        if (!createSubscriptionResponseRef.current) {
          const createSubscriptionRequest: CreateSubscriptionRequest = {
            email: accountFormData.email,
            firstName: accountFormData.firstName,
            lastName: accountFormData.lastName,
            source: initialSource || accountFormData.source,
            priceId: priceId,
          };
          const createSubscriptionResponse = await api.createSubscription(createSubscriptionRequest);
          createSubscriptionResponseRef.current = createSubscriptionResponse;
        }
        if (
          createSubscriptionResponseRef.current &&
          createSubscriptionResponseRef.current.clientSecret &&
          createSubscriptionResponseRef.current.stripeSubscriptionId &&
          createSubscriptionResponseRef.current.stripeCustomerId
        ) {
          let error = undefined;
          if (!createSubscriptionResponseRef.current.isTrialing) {
            const paymentIntentResult = await stripe!.confirmCardPayment(
              createSubscriptionResponseRef.current.clientSecret,
              {
                payment_method: { card: elements.getElement(CardNumberElement)! },
              },
            );
            error = paymentIntentResult.error;
          } else {
            const cardSetupResult = await stripe!.confirmCardSetup(
              createSubscriptionResponseRef.current?.clientSecret,
              {
                payment_method: { card: elements.getElement(CardNumberElement)! },
              },
            );
            error = cardSetupResult.error;
          }
          if (error) {
            setError(error);
          } else {
            const registerUser: RegisterUserRequestDTO = {
              user: { ...accountFormData, stripeCustomerId: createSubscriptionResponseRef.current.stripeCustomerId },
              music: {
                spotifyArtistIds: trackData.selectedArtists.map((t) => t.id),
                spotifyTrackIds: trackData.selectedTracks.map((t) => t.id),
                spotifyAlbumIds: trackData.selectedAlbums.map((a) => a.id),
                uploadedTracks: [],
              },
              stripeSubscriptionId: createSubscriptionResponseRef.current.stripeSubscriptionId,
            };
            await api.register(registerUser);
            fbq('track', 'Purchase', { value: priceInCent / 100, currency: 'EUR' });
            setStep(step + 1);
          }
        }
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  let StepComponent = <>{`undefined step ${step}`}</>;

  switch (step) {
    case 0:
      StepComponent = (
        <CreateAccountForm
          onSubmit={(data) => {
            setAccountFormData(data);
            fbq('track', 'Lead');
            setStep(step + 1);
          }}
          initialValues={accountFormData}
          disableSource={initialSource !== undefined}
        />
      );
      break;
    case 1:
      StepComponent = (
        <AddTrack
          onPrevious={() => setStep(step - 1)}
          onNext={savePartialUserAndGoToPayment}
          initialValues={trackData}
        />
      );
      break;
    case 2:
      StepComponent = (
        <Payment
          onPrevious={() => setStep(step - 1)}
          onSubscribe={subscribe}
          error={error}
          isProcessing={loading}
          engagement={engagement}
          setEngagement={setEngagement}
          formula={formula}
          setFormula={setFormula}
          source={initialSource}
          prices={prices!}
        />
      );
      break;
    case 3:
      StepComponent = <Greeting email={accountFormData?.email || ''} />;
      break;
    default:
      break;
  }

  if (isLoadingPrices || prices === undefined) {
    return (
      <div className="h-56 text-sm flex items-center justify-center leading-none">
        <Loader />
      </div>
    );
  }

  if (!isLoadingPrices && (isErrorPrices || prices.length === 0)) {
    return (
      <div className="bg-red-100 p-6 rounded">
        <h4>Oups !</h4>
        <p className="text-red-700">
          Une erreur est survenue mais ce n'est pas de votre faute. <br /> Merci de réessayer dans quelques instants.
        </p>
      </div>
    );
  }

  return <React.Fragment>{StepComponent}</React.Fragment>;
}
