import { SacemRegistrationFormSchema } from 'features/sacem-registration/interface';
import { DepositFormSchema } from 'features/work-deposit/interface';
import wretch from 'wretch';
import FormDataAddon from 'wretch/addons/formData';
import {
  APIDataListResponse,
  APIDataResponse,
  CancelSubscriptionResponse,
  CardDTO,
  CreateIdentityDocumentDTO,
  CreateSubscriptionRequest,
  InvoiceDTO,
  InvoicePaymentIntent,
  LoginResponse,
  RegisterUserRequestDTO,
  RegisterUserResponseDTO,
  SpotifyResult,
  SubscriptionDTO,
  SubscriptionPlanDTO,
  SubscriptionPricingDTO,
  SubscriptionStatusDTO,
  Broadcast,
  TrackWithMonitoringData,
  UpdateCardRequestResponse,
  UpdatePasswordDTO,
  UpdateTrackDTO,
  UpdateUserDTO,
  UploadTracksRequestDTO,
  UserDTO,
  UserInfoDTO,
  BroadcastOverview,
  RegisterPartialUserRequestDTO,
  SubscriptionPriceDTO,
} from 'shared-types';
import { isApiError } from 'utils/errors';
import { object } from 'yup';
import moment from 'moment/moment';

export class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

class ApiService {
  api = wretch().addon(FormDataAddon).url(`${process.env.REACT_APP_API_URL}`).errorType('json');

  get authApi() {
    return (
      this.api
        .auth(`Bearer ${localStorage.getItem('user_token')}`)
        // .options({ credentials: 'include', mode: 'cors' })
        .resolve((_) =>
          _.unauthorized((error) => {
            if (error.status === 401 || (isApiError(error) && error.json.statusCode === 401)) {
              const msg = isApiError(error) ? error.json.message : 'Unauthorized';
              throw new UnauthorizedError(msg);
            } else {
              throw error;
            }
          }),
        )
    );
  }

  login(username: string, password: string) {
    return this.api.url('/login').post({ username, password }).json<LoginResponse>();
  }

  fetchTrackCount() {
    return this.authApi
      .url('/track_count')
      .get()
      .json<APIDataResponse<number>>()
      .then((r) => r.data);
  }

  fetchMyInfo() {
    return this.authApi
      .auth(`Bearer ${localStorage.getItem('user_token')}`)
      .url('/my_info')
      .get()
      .json<APIDataResponse<UserInfoDTO>>()
      .then((r) => r.data);
  }

  fetchPersonalData() {
    return this.authApi
      .url('/personnal_data')
      .get()
      .json<APIDataResponse<UserDTO>>()
      .then((r) => r.data);
  }

  updatePersonalData(data: UpdateUserDTO) {
    return this.authApi
      .url('/personnal_data')
      .post(data)
      .json<APIDataResponse<UserDTO>>()
      .then((r) => r.data);
  }

  setupCard() {
    return this.authApi
      .url('/subscription/setup-card')
      .post({})
      .json<APIDataResponse<UpdateCardRequestResponse>>()
      .then((r) => r.data);
  }

  updatePassword(data: UpdatePasswordDTO) {
    return this.authApi
      .url('/update_password')
      .post(data)
      .json<APIDataResponse<string>>()
      .then((r) => r.data);
  }

  sendEmailVerificationLink() {
    return this.authApi.url('/send-email-verification').post({}).json();
  }

  fetchMyTracks(options: { currentPage: number; pageSize: number; search?: string }) {
    const endPoint = `/tracks?current_page=${options.currentPage}&page_size=${options.pageSize}&search=${options.search}`;
    return this.authApi.url(endPoint).get().json<APIDataListResponse<TrackWithMonitoringData>>();
  }

  fetchInvoices() {
    return this.authApi.url('/subscription/invoices').get().json<APIDataListResponse<InvoiceDTO>>();
  }

  retrievePaymentMethod() {
    return this.authApi
      .url('/subscription/payment_method')
      .get()
      .notFound(() => undefined)
      .json<APIDataResponse<CardDTO | undefined>>()
      .then((r) => r.data);
  }

  fetchPlans() {
    return this.api.url('/subscription/plans').get().json<APIDataListResponse<SubscriptionPlanDTO>>();
  }

  fetchSubscriptionPricing(trackCount: number) {
    return this.api.url('/subscription/pricing').post({ trackCount }).json<APIDataResponse<SubscriptionPricingDTO>>();
  }

  fetchSubscriptionPrices(options?: { source?: string }) {
    return this.api
      .url(`/subscription/prices${options ? `?${appendUrlParam(options)}` : ''}`)
      .get()
      .json<APIDataListResponse<SubscriptionPriceDTO>>()
      .then((r) => r.data);
  }

  deleteTrack(trackId: number) {
    return this.authApi
      .url(`/tracks/${trackId}`)
      .delete()
      .res(() => 1);
  }

  updateTrack(trackId: number, data: UpdateTrackDTO) {
    return this.authApi.url(`/tracks/${trackId}`).put(data).json();
  }

  fetchBroadcastStatReport(options?: FetchBroadcastStatReportOptions): Promise<Broadcast[]> {
    return this.authApi
      .auth(`Bearer ${localStorage.getItem('user_token')}`)
      .url(`/statistics/report${options ? `?${appendUrlParam(options)}` : ''}`)
      .get()
      .json<
        APIDataListResponse<{
          count: number;
          date: string;
          media: string;
        }>
      >()
      .then((r) => r.data)
      .then((data) => data.map((d) => ({ ...d, date: moment(d.date) })));
  }

  // fetchBroadcastChannels(options?: FetchBroadcastStatReportOptions) {
  //   return this.authApi
  //     .auth(`Bearer ${localStorage.getItem('user_token')}`)
  //     .url(`/statistics/user/channels?${appendUrlParam(options)}`)
  //     .get()
  //     .json<APIDataListResponse<Channel>>()
  //     .then((r) => r.data);
  // }

  // fetchStatMedia() {
  //   return this.authApi
  //     .auth(`Bearer ${localStorage.getItem('user_token')}`)
  //     .url('/statistics/medias')
  //     .get()
  //     .json<APIDataListResponse<MediaCount>>()
  //     .then((r) => r.data);
  // }

  fetchBroadcastOverview(options?: FetchStatBroadcastOverviewOptions) {
    return this.authApi
      .auth(`Bearer ${localStorage.getItem('user_token')}`)
      .url(`/statistics/overview${options ? `?${appendUrlParam(options)}` : ''}`)
      .get()
      .json<APIDataResponse<BroadcastOverview>>()
      .then((r) => r.data);
  }

  fetchRights(options?: { from?: number; to?: number }) {
    return this.authApi
      .auth(`Bearer ${localStorage.getItem('user_token')}`)
      .url(`/rights${options ? `?${appendUrlParam(options)}` : ''}`)
      .get()
      .json<APIDataResponse<{ streams: number; radio: number; tv: number }>>()
      .then((r) => r.data);
  }

  register(data: RegisterUserRequestDTO) {
    return this.api
      .url('/user')
      .post(data)
      .json<APIDataResponse<RegisterUserResponseDTO>>()
      .then((r) => r.data);
  }

  registerPartial(data: RegisterPartialUserRequestDTO) {
    return this.api
      .url('/user/partial')
      .post(data)
      .json<APIDataResponse<string>>()
      .then((r) => r.data);
  }

  deleteUser() {
    return this.authApi.url('/user').delete().json();
  }

  getSacemAdmissionSecret() {
    return this.api
      .url('/sacem-registration/secret')
      .get()
      .json<APIDataResponse<{ client_secret: string }>>()
      .then((r) => r.data);
  }

  getWorkDepositSecret() {
    return this.api
      .url('/work-deposit/secret')
      .get()
      .json<APIDataResponse<{ client_secret: string }>>()
      .then((r) => r.data);
  }

  postWorkDeposit(data: DepositFormSchema) {
    Promise.resolve({});
  }

  postSacemRegistration(data: SacemRegistrationFormSchema) {
    const { ribIbanFile, idFile, idPhotoFile, ...rest } = data;
    const formData = new FormData();
    formData.append('ribIbanFile', ribIbanFile as File, (ribIbanFile as File).name);
    formData.append('idFile', idFile as File, (idFile as File).name);
    formData.append('idPhotoFile', idPhotoFile as File, (idPhotoFile as File).name);
    Object.keys(rest).forEach((key) => formData.append(key, (rest as any)[key]));
    return this.api
      .url('/sacem-registration')
      .formData({
        ribIbanFile,
        idFile,
        idPhotoFile,
        ...rest,
      })
      .post()
      .json<APIDataResponse<string>>()
      .then((r) => r.data);
  }

  existingEmail(email: string) {
    return this.api
      .url('/user/existing-email')
      .post({ email })
      .json<APIDataResponse<boolean>>()
      .then((r) => r.data);
  }

  sendEmailVerificationEmail(email: string) {
    return this.api
      .url(`/email=${encodeURIComponent(email)}/send-verification`)
      .post({})
      .json<APIDataResponse<string>>()
      .then((r) => r.data);
  }

  verifyEmail(email: string, token: string) {
    return this.api
      .url(`/email=${encodeURIComponent(email)}/verification?token=${encodeURIComponent(token)}`)
      .post({})
      .json<APIDataResponse<string>>()
      .then((r) => r.data);
  }

  sendForgottenPasswordEmail(email: string) {
    return this.api
      .url(`/user/forgotten-password/${encodeURIComponent(email)}`)
      .post({})
      .json<APIDataResponse<string>>()
      .then((r) => r.data);
  }

  changePassword(email: string, token: string, password: string) {
    return this.api
      .url(`/user/change-password/${encodeURIComponent(email)}`)
      .post({
        token,
        password,
      })
      .json<APIDataResponse<string>>()
      .then((r) => r.data);
  }

  createIdentityDocument(dto: CreateIdentityDocumentDTO) {
    return this.authApi
      .url('/identity-documents')
      .post(dto)
      .json<APIDataResponse<any>>()
      .then((r) => r.data);
  }

  createSubscription(request: CreateSubscriptionRequest) {
    return this.api
      .url('/subscription')
      .post(request)
      .json<APIDataResponse<InvoicePaymentIntent>>()
      .then((r) => r.data);
  }

  createCustomerPortalSession() {
    return this.authApi.url('/subscription/create-customer-portal-session').get().json<Promise<{ data: string }>>();
  }

  findSubscription(): Promise<SubscriptionDTO | undefined> {
    return this.authApi
      .url('/subscription')
      .get()
      .notFound(() => ({}))
      .json<APIDataResponse<SubscriptionDTO>>()
      .then((r) => r.data);
  }

  findSubscriptionStatus() {
    return this.authApi
      .url('/subscription/status')
      .get()
      .notFound(() => ({}))
      .json<APIDataResponse<SubscriptionStatusDTO>>()
      .then((r) => r.data);
  }

  cancelSubscription() {
    return this.authApi
      .url('/subscription/cancel')
      .post()
      .json<APIDataResponse<CancelSubscriptionResponse>>()
      .then((r) => r.data);
  }

  unCancelSubscription() {
    return this.authApi.url('/subscription/un-cancel').post().json();
  }

  uploadTracks(request: UploadTracksRequestDTO) {
    return this.authApi
      .url('/tracks')
      .post(request)
      .json<APIDataResponse<number>>()
      .then((r) => r.data);
  }

  downloadTrack(track: TrackWithMonitoringData) {
    return this.authApi
      .url(`/tracks/${track.id}/download`)
      .get()
      .blob((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', track.filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  }

  searchOnSpotify(rawText: string) {
    return this.api.url(`/spotify?search=${rawText}`).get().json<SpotifyResult>();
  }

  getArtistAlbumsTrackCount(artistId: string) {
    return this.api
      .url(`/spotify/artists/${artistId}/albums_track_count`)
      .get()
      .json<APIDataResponse<{ albumsTrackCount: number }>>();
  }
}

function appendUrlParam(object: { [pName: string]: any }) {
  const esc = encodeURIComponent;
  const sanitized = Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== undefined && v !== true && v !== ''),
  );
  const query = Object.entries(sanitized)
    .map(([k, v]) => esc(k) + '=' + esc(v))
    .join('&');
  return query;
}

export interface FetchStatBroadcastOverviewOptions {
  to?: number;
  from?: number;
  trackId?: number;
  media?: string;
}

export interface FetchBroadcastStatReportOptions extends FetchStatBroadcastOverviewOptions {
  media?: string;
  mediaName?: string;
}

export default new ApiService();
