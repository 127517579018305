import React from 'react';

type Props = {
  className?: string
}
const SoundCloudLogoSymbol: React.FC<Props> = (props) => {
  return (
    <svg height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.319 291.319" className={`w-5 ${props.className}`}>
      <g>
        <path className="fill-[#FF7700]" d="M72.83,218.485h18.207V103.832c-6.828,1.93-12.982,5.435-18.207,10.041 C72.83,113.874,72.83,218.485,72.83,218.485z M36.415,140.921v77.436l1.174,0.127h17.033v-77.682H37.589 C37.589,140.803,36.415,140.921,36.415,140.921z M0,179.63c0,14.102,7.338,26.328,18.207,33.147V146.52 C7.338,153.329,0,165.556,0,179.63z M109.245,218.485h18.207v-109.6c-5.444-3.396-11.607-5.635-18.207-6.5V218.485z  M253.73,140.803h-10.242c0.519-3.168,0.847-6.382,0.847-9.705c0-32.182-25.245-58.264-56.388-58.264 c-16.896,0-31.954,7.775-42.287,19.955v125.695h108.07c20.747,0,37.589-17.388,37.589-38.855 C291.319,158.182,274.477,140.803,253.73,140.803z"/>
      </g>
    </svg>
  );
};

export default SoundCloudLogoSymbol;
