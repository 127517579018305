import PageTitle from 'features/layout/PageTitle';
import React from 'react';
import { useLocation } from 'react-router-dom';
import RegisterWizard from './RegisterWizard';

export default function RegisterPage() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const engagement = query.get('engagement');
  const formula = query.get('formula');
  const source = query.get('source')
  return (
    <div className="flex justify-center subscription">
      <PageTitle title="Inscription" />
      <RegisterWizard initialEngagement={engagement || undefined} initialFormula={formula || undefined} initialSource={source || undefined} />
    </div>
  );
}
