import React from 'react';
import api from 'services/api';
import PageTitle from '../layout/PageTitle';
import IconAdd from '../../components/icons/IconAdd';
import EmptyStateUpload from '../../components/svg/EmptyStateUpload';
import GenericError from 'components/GenericError';
import Loader from 'components/Loader';
import { useQuery } from 'react-query';
import { MyTracksTableContainer } from 'features/tracks/MyTracksTableContainer';

const MyTracks = () => {
  const {
    data: trackCount,
    isLoading,
    isError,
  } = useQuery('/api/track-count', () => api.fetchTrackCount(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: (query) => {
      return query.state.data === undefined || query.state.data === 0;
    },
    refetchOnReconnect: false,
  });

  return (
    <>
      <PageTitle title="Mes titres" />
      <div className="w-full">
        {!isLoading && isError && <GenericError />}
        {isLoading && !isError && <Loader className="justify-center" />}
        {!isLoading &&
          !isError &&
          trackCount !== undefined &&
          trackCount === 0 && (
            <div className="w-full flex justify-center">
              <div className="w-full md:w-6/12 bg-white shadow-xl p-6 rounded text-center">
                <h4 className="mb-6">C'est un peu vide par ici...</h4>
                <EmptyStateUpload className="w-64 mb-6 mx-auto" />
                <p>
                  C'est ici que vous pourrez suivre les statistiques de tous vos
                  titres par canal et média.
                </p>
                <a
                  className="button w-full md:text-xl mt-6"
                  href="/track-upload"
                >
                  <IconAdd className="mr-4" /> Mettre en ligne mon premier titre
                </a>
              </div>
            </div>
          )}
        {!isLoading &&
          !isError &&
          trackCount != undefined &&
          trackCount > 0 && <MyTracksTableContainer />}
      </div>
    </>
  );
};

export default MyTracks;
