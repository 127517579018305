import React from 'react';
import IconFileMusic from './icons/IconFileMusic';
import Dropzone, { DropzoneProps } from './Dropzone';

function TrackDropzone(props: Omit<DropzoneProps, 'emptyMeta' | 'config'>) {
  const dzConfig = {
    minSizeBytes: 1,
    maxSizeBytes: 200000000,
    maxDuration: '15min',
    maxFiles: 10,
    accept: '.wav,.mp3,.aac,.aiff',
    fileIcon: <IconFileMusic />,
  };
  const dzEmptyMeta = (
    <>
      <div className="meta">
        wav / mp3 / aac / aiff <br />
        Taille max : {dzConfig.maxSizeBytes / 1000000} mo
      </div>
      <div className="meta">
        <strong>Durée inférieure à 15 min.</strong>
      </div>
      <div className="meta">
        <strong>Maximum {dzConfig.maxFiles} titres.</strong>
      </div>
      <a>Parcourir mon ordinateur</a>
    </>
  );
  return <Dropzone config={dzConfig} emptyMeta={dzEmptyMeta} {...props} />;
}

export default TrackDropzone;
