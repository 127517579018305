import React from 'react';
import IconLoading from 'components/icons/IconLoading';

export default function Loader({className}: { className?: string }) {
  return (
    <div
      className={`text-sm flex items-center leading-none ${className ? className : ''}`}
    >
      <IconLoading className="w-5 h-5 text-primary"/>
      <span className="ml-2">Chargement...</span>
    </div>
  );
}
