import React, { PropsWithChildren } from 'react'
import NavigationHeader from './NavigationHeader';
import NavigationFooter from './NavigationFooter';
import { Helmet } from 'react-helmet';
import CountdownSacem from "./CountDownSacem";
export default class ConnectedUserLayout extends React.Component<PropsWithChildren<{
  title?: string;
}>> {
  render() {
    const { children, title } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>RightsNow! {`${title ? `- ${title}` : ''}`}</title>
        </Helmet>
        <nav className="w-full">
          <NavigationHeader />
          <CountdownSacem />
        </nav>
        <div className="max-w-screen-xl flex justify-center py-4 md:py-12 px-4 mx-auto md:px-6 lg:px-8">{children}</div>
        <div className="max-w-screen-xl px-4 mx-auto md:px-6 lg:px-8 pt-6 pb-12">
          <NavigationFooter />
        </div>
      </React.Fragment>
    );
  }
}
