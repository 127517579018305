import React from 'react';
import IconLoading from 'components/icons/IconLoading';

export default function LoaderVertical({ className }: { className?: string }) {
  return (
    <div
      className={`flex flex-col items-center leading-none ${
        className ? className : ''
      }`}
    >
      <IconLoading className="w-14 h-14 text-primary mb-6" />
      <div className="font-bold">Chargement...</div>
    </div>
  );
}
