import React, {useEffect, useState} from 'react';
import moment from "moment/moment";
import {Link} from "react-router-dom";

const CountdownSacem: React.FC = () => {
  const [countdown, setCountdown] = useState<string | null>(null);

  useEffect(() => {
    const targetMonths = [1, 4, 7, 10]; // Mois de janvier, avril, juillet, octobre (1 indexé)
    const timer = setInterval(() => {
      const now = new Date();
      let targetDate = new Date();

      // Trouver le prochain mois cible et le dernier jour ouvré avant le 6
      targetDate.setDate(6);
      if (!targetMonths.includes(targetDate.getMonth() + 1) || now >= targetDate) {
        const nextMonthIndex = (targetMonths.findIndex(m => m > now.getMonth() + 1) + 1) % targetMonths.length;
        const nextTargetMonth = targetMonths[nextMonthIndex] - 1;
        targetDate = new Date(now.getFullYear() + (nextTargetMonth < now.getMonth() ? 1 : 0), nextTargetMonth, 6);
      }
      targetDate = getLastWorkday(targetDate);

      const diff = targetDate.getTime() - now.getTime();
      if (diff <= 0) {
        clearInterval(timer);
        setCountdown(null);
      } else {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        if (days <= 4) {
          const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          setCountdown(`${days} jours, ${hours} heures et ${minutes} minutes`);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Fonction pour trouver le dernier jour ouvré avant le 6 du mois cible
  const getLastWorkday = (date: Date) => {
    date.setDate(5); // Débuter à la veille du 6
    while (date.getDay() === 0 || date.getDay() === 6) { // 0 = Dimanche, 6 = Samedi
      date.setDate(date.getDate() - 1);
    }
    return date;
  };

  return (
    <>
      {
        countdown &&
        <div className="relative max-w-screen-xl px-4 mx-auto md:mt-2 md:px-6 lg:px-8">
          <div className="p-2 bg-white text-xs shadow rounded text-center">
            Plus que {countdown} avant la prochaine répartition Sacem
          </div>
        </div>
      }
    </>
  )
    ;
};

export default CountdownSacem;