import { ActiveLink } from 'components/ActiveLink';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {duotone} from '@fortawesome/fontawesome-svg-core/import.macro';

export default function SettingsLeftMenu() {
  return (
    <div>
      <div className="flex mb-6 flex-col">
        <ActiveLink
          to="/settings/account"
          className="p-4 rounded inline-flex items-center"
        >
          <FontAwesomeIcon icon={duotone("address-card")} className="h-5" />
          <span className="ml-4">Informations personnelles</span>
        </ActiveLink>
        {/*{isSubscribed && (*/}
        {/*  <ActiveLink*/}
        {/*    to="/settings/bank"*/}
        {/*    className="p-4 rounded inline-flex items-center"*/}
        {/*  >*/}
        {/*    <IconPayment />*/}
        {/*    <span className="ml-4">Données bancaires</span>*/}
        {/*  </ActiveLink>*/}
        {/*)}*/}
        <ActiveLink
          to="/settings/password"
          className="p-4 rounded inline-flex items-center"
        >
          <FontAwesomeIcon icon={duotone("fingerprint")} className="h-5" />
          <span className="ml-4">Mot de passe</span>
        </ActiveLink>
        {/*<ActiveLink to="/settings/notifications" className="p-4 rounded inline-flex items-center">*/}
        {/*  <IconNotification /><span className="ml-4">Gérer mes notifications</span>*/}
        {/*</ActiveLink>*/}
        <ActiveLink
          to="/settings/subscription"
          className="p-4 rounded inline-flex items-center"
        >
          <FontAwesomeIcon icon={duotone("wallet")} className="h-5" />
          <span className="ml-4">Abonnement & Factures</span>
        </ActiveLink>
        <ActiveLink
          to="/settings/offers"
          className="p-4 rounded inline-flex items-center"
        >
          <FontAwesomeIcon icon={duotone("gift-card")} className="h-5" />
          <span className="ml-4">Offres partenaires</span>
        </ActiveLink>
      </div>
    </div>
  );
}
