import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudMusic, faRadio, faTvMusic } from '@fortawesome/pro-duotone-svg-icons'
import channelServices from './channels.json'
import MonitoringHistoric from './MonitoringHistoric'

type ChannelService = {
  channel: string;
  type: string;
  status: string;
};

interface GroupedServices {
  [key: string]: ChannelService[];
}

export function ChannelsMonitoringPage() {
  const channelServicesInfos: ChannelService[] = channelServices.map(item => ({
    channel: String(item.nom),
    type: item.type,
    status: item.status,
  }))

  const groupedServices: GroupedServices = channelServicesInfos.reduce((acc: GroupedServices, service) => {
    const { type } = service
    acc[type] = acc[type] || []
    acc[type].push(service)
    acc[type].sort((a, b) => a.channel.localeCompare(b.channel))
    return acc
  }, {})

  const typeToIconMap: { [key: string]: any } = {
    Streaming: faCloudMusic,
    Radio: faRadio,
    TV: faTvMusic,
  }

  const MonitoringHistoricWithDelay = ({ status }: { status: string }) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
      if (status === 'Actif') {
        const delay = Math.random() * (5000 - 1000) + 1000 // Génère un nombre aléatoire entre 1 et 5 secondes
        const timer = setTimeout(() => setShow(true), delay)
        return () => clearTimeout(timer)
      }
    }, [status])

    return show ? (
      <MonitoringHistoric />
    ) : (
      <div className="text-gray-700">Chargement...</div> // Affiche "Chargement" pendant le délai
    )
  }

  return (
    <>
      <div className="w-full bg-white shadow-xl p-6 pb-0 rounded overflow-hidden">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl mb-0">Statut du monitoring <span className="counter">{channelServicesInfos.length} canaux</span></h1>
        </div>
        <div className="-mx-6 overflow-auto lg:overflow-visible">
          {Object.entries(groupedServices).map(([type, services], index) => (
            <div className="flex flex-col">
              <div className={`p-6 flex items-center ${type === 'Streaming' ? 'bg-stream-50' : type === 'Radio' ? 'bg-radio-50' : 'bg-tv-50'}`}>
                <FontAwesomeIcon icon={typeToIconMap[type]} className={`h-6 mr-2 flex-shrink-0 ${type === 'Streaming' ? 'text-stream-700' : type === 'Radio' ? 'text-radio-600' : 'text-tv-600'}`} />
                <strong>{type} <span className="counter">{services.length}</span></strong>
              </div>
              <table className={`table w-full text-left`}>
                <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-100">Nom</th>
                  <th className="px-6 py-3 bg-gray-100">Statut</th>
                  <th className="px-6 py-3 bg-gray-100">Disponibilité</th>
                </tr>
                </thead>
                <tbody>
                {services.map((service, serviceIndex) => (
                  <tr className="hover:bg-gray-100 transition-all duration-200 border-b" key={serviceIndex}>
                    <td className="px-6 py-3 w-1/3">{service.channel}</td>
                    <td className="px-6">
                          <span className={service.status === 'Actif' ? 'text-green-500 font-bold' : 'text-gray-400'}>
                            {service.status}
                          </span>
                    </td>
                    <td className="px-6 text-left w-1/3">
                      {service.status === 'Actif' ? <MonitoringHistoricWithDelay status={service.status} /> : <>NA</>}
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
