import PageTitle from 'features/layout/PageTitle';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import api from 'services/api';
import { UpdateUserDTO } from 'shared-types';
import PersonnalDataForm, {
  PersonnalDataFormValues,
} from './PersonnalDataForm';

const AccountPage = () => {
  const { data } = useQuery('/api/personnal_data', () =>
    api.fetchPersonalData(),
  );
  const [updatedData, setUpdatedData] = useState<UpdateUserDTO | undefined>();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const updatePersonnalData = async (values: PersonnalDataFormValues) => {
    const data: UpdateUserDTO = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
    };
    const updated = await api.updatePersonalData(data);
    setUpdatedData(updated);
    setUpdateSuccess(true);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <PageTitle title="Informations personnelles" />
      <PersonnalDataForm
        initialData={updatedData || data || {}}
        handleSubmit={updatePersonnalData}
        updateSuccess={updateSuccess}
        closeUpdateSuccessMessage={() => setUpdateSuccess(false)}
      />
    </>
  );
};

export default AccountPage;
