import React from 'react';

export default function MonitoringHistoric({ className }: { className?: string }) {
  const svgWidth = 400;
  const rectWidth = svgWidth * 0.02;
  const spacing = svgWidth * 0.004; // Espace entre les rectangles, ajustez selon besoin
  const rects = Array.from({ length: 6 }).map((_, i) => (
    <rect
      key={i}
      x={(rectWidth + spacing) * i}
      y="0"
      width={rectWidth}
      height="20"
      rx="4"
    >
    </rect>
  ));

  return (
    <svg width="100%" height="20" xmlns="http://www.w3.org/2000/svg" className="`${className}` text-green-400">
      {rects}
    </svg>
  );
}