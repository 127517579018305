import cx from 'classnames';
import InputField from 'components/InputField';
import {
  PasswordRequirementInForm,
  passwordRequirementSchema,
} from 'components/PasswordRequirement';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import api from 'services/api';
import { validateYupSchemaMultiErrors } from 'utils/formikHelpers';
import * as Yup from 'yup';
import IconClose from '../../components/icons/IconClose';

const ChangePasswordFormSchema = Yup.object()
  .shape({
    actualPassword: Yup.string().required('Ce champ est obligatoire'),
    newPassword: passwordRequirementSchema,
    newPasswordConfirmation: Yup.string()
      .required('Ce champ est obligatoire')
      .test(
        'password-match',
        'Les mots de passe doivent être identiques',
        (value, ctx) => {
          return ctx.parent.newPassword === value;
        },
      ),
  })
  .defined();

export type ChangePasswordFormValues = Yup.InferType<
  typeof ChangePasswordFormSchema
>;

function ChangePasswordForm() {
  return (
    <Formik<ChangePasswordFormValues>
      initialValues={{
        actualPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      }}
      validate={async (values) =>
        validateYupSchemaMultiErrors(values, ChangePasswordFormSchema)
      }
      onSubmit={async (values, { setStatus, setFieldError }) => {
        try {
          setStatus(undefined);
          await api.updatePassword(values);
          setStatus({ updateSuccess: true });
          window.scrollTo(0, 0);
        } catch (error: any) {
          console.error('An error occurred', error.json);
          if (
            error &&
            error.json &&
            error.json.message &&
            error.json.message === 'wrong-password'
          ) {
            setFieldError(
              'actualPassword',
              'Hum, il semblerait que le mot de passe actuel est incorrect.',
            );
          } else {
            setStatus({ updateError: true });
          }
        }
      }}
    >
      {({ isSubmitting, errors, status, setStatus, values, isValid }) => {
        return (
          <Form>
            {status && status.updateSuccess && (
              <div className="message bg-green-500 text-white mb-6 flex justify-between">
                <div className="content">Mot de passe mis à jour.</div>
                <div
                  className="cursor-pointer self-baseline"
                  onClick={() => setStatus(undefined)}
                >
                  <IconClose className="w-6" />
                </div>
              </div>
            )}
            <div className="bg-white shadow-xl p-6 rounded">
              <div className="mb-6">
                <Field
                  type="password"
                  label="Mot de passe actuel"
                  name="actualPassword"
                  className="w-full mb-0"
                  isRequired
                  component={InputField}
                  renderError={(error: string | string[]) => (
                    <div className="error explanation">
                      {typeof error === 'string' ? error : error[0]}
                    </div>
                  )}
                />
                <hr className="mt-4" />
                <Field
                  type="password"
                  label="Nouveau mot de passe"
                  name="newPassword"
                  className="w-full"
                  isRequired
                  component={InputField}
                  renderError={(error: string | string[]) => {
                    if (
                      typeof error !== 'string' &&
                      error.includes('Ce champ est obligatoire')
                    ) {
                      return (
                        <div className="error explanation">{error[0]}</div>
                      );
                    } else return undefined;
                  }}
                />{' '}
                <Field
                  type="password"
                  label="Confirmez votre nouveau mot de passe"
                  name="newPasswordConfirmation"
                  className="w-full"
                  isRequired
                  component={InputField}
                  renderError={(error: string | string[]) => (
                    <div className="error explanation">
                      {typeof error === 'string' ? error : error[0]}
                    </div>
                  )}
                />
                <PasswordRequirementInForm
                  fieldName="newPassword"
                  values={values}
                  errors={errors}
                />
              </div>
              <div className="actions">
                <button
                  type="submit"
                  className={cx('button', {
                    loading: !isValid || isSubmitting,
                  })}
                  disabled={!isValid || isSubmitting}
                >
                  Modifier le mot de passe
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ChangePasswordForm;
