import { BroadcastOverview } from 'shared-types';
import lodash from 'lodash';

export function computeTotalsFromOverview(broadcastOverview?: BroadcastOverview) {
  const totalStreamBroadcast = broadcastOverview
    ? lodash.sumBy(broadcastOverview.streams, (stream) => stream.count)
    : 0;
  const totalRadioBroadcast = broadcastOverview
    ? lodash.sumBy(
        broadcastOverview.liveBroadcasts.filter((lb) => lb.type === 'radio').flatMap((lb) => lb.channels),
        (lb) => lb.count,
      )
    : 0;
  const totalTvBroadcast = broadcastOverview
    ? lodash.sumBy(
        broadcastOverview.liveBroadcasts.filter((lb) => lb.type === 'tv').flatMap((lb) => lb.channels),
        (lb) => lb.count,
      )
    : 0;
  const totalBroadcast = totalStreamBroadcast + totalRadioBroadcast + totalTvBroadcast;

  return {
    totalStreamBroadcast,
    totalRadioBroadcast,
    totalTvBroadcast,
    totalBroadcast,
  };
}

export function formatBroadcastNumber(value: number) {
  return value.toLocaleString('fr-FR', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 2,
  });
}

export function formatRightsAmount(value: number, compact: boolean, weightPercentage?: number) {
  const adjustedRights = weightPercentage ? value - value * weightPercentage : value;
  const toLocaleStringOption: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'EUR',
  };
  if (compact) {
    toLocaleStringOption.notation = 'compact'
    toLocaleStringOption.compactDisplay = 'short'
    toLocaleStringOption.maximumFractionDigits = 2
  }
  return adjustedRights.toLocaleString('fr-FR', toLocaleStringOption);
}
