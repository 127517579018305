import React from 'react';
import {Helmet} from 'react-helmet';
import RightsNowLogo from "../../components/svg/RightsNowLogo";

export const Page404 = () => (
  <div className="max-w-screen-md py-12 px-4 mx-auto md:px-6 lg:px-8">
    <div className="bg-white shadow-xl p-6 rounded">
      <Helmet>
        <title>RightsNow! - Page non trouvée (404)</title>
      </Helmet>
      <div className="w-64 mb-12">
        <RightsNowLogo/>
      </div>
      <p>La page que vous cherchez n'a pas été trouvée (404).</p>
      <a href="/" className="button mt-6">Revenir à page d'accueil</a>
    </div>
  </div>
);

export default Page404;
