import React from 'react';
import IconLoading from "./icons/IconLoading";

export default function LoaderCard({className}: { className?: string }) {
  return (
    <div className={`flex flex-col w-full animate-pulse space-y-2 ${className ? className : ''}`}>
      <div className="h-2 bg-gray-200 rounded-full w-1/3"></div>
      <div className="h-2 bg-gray-200 rounded-full w-full"></div>
      <div className="h-2 bg-gray-200 rounded-full w-2/3"></div>
      <div className="h-2 bg-gray-200 rounded-full w-4/5" ></div>
      <div className="h-2 bg-gray-200 rounded-full w-full"></div>
      <span className="sr-only">Chargement des données...</span>
    </div>
  );
}