import PageTitle from 'features/layout/PageTitle';
import Notification from 'features/notification/Notification';

const NotificationPage = () => {
  return (
    <>
      <PageTitle title="Notifications" />
      <Notification />
    </>
  );
};

export default NotificationPage;
