import React from 'react';

export default function LoaderTrackTable({className}: { className?: string }) {
  const rows = [];
  const getRandomWidthClassTitle = () => {
    const classes = ['w-1/6', 'w-3/4', 'w-3/5', 'w-1/5', 'w-1/2', 'w-2/3'];
    return classes[Math.floor(Math.random() * classes.length)];
  };
  const getRandomWidthClassArtist = () => {
    const classes = ['w-96','w-80','w-64','w-48','w-40','w-32','w-24'];
    return classes[Math.floor(Math.random() * classes.length)];
  };
  for (let i = 0; i < 20; i++) {
    const randomWidthClassTitle = getRandomWidthClassTitle();
    const randomWidthClassArtist = getRandomWidthClassArtist();
    rows.push(
      <div key={i} className="w-full space-y-2 p-6">
        <div className={`${randomWidthClassTitle} h-2 bg-gray-300 rounded-full`}></div>
        <div className={`${randomWidthClassArtist} h-2 bg-gray-200 rounded-full`}></div>
      </div>
    );
  }
  return (
    <div className={`animate-pulse -mx-6 ${className ? className : ''}`}>
      <div className="w-full flex justify-between align-middle items-center space-x-2 py-8 px-6 bg-gray-700">
        <div className="w-32 h-2 bg-gray-500 rounded-full"></div>
        <div className="w-16 h-2 bg-gray-500 rounded-full"></div>
        <div className="w-32 h-2 bg-gray-500 rounded-full"></div>
        <div className="w-6 h-2 bg-gray-500 rounded-full"></div>
        <div className="w-12 h-2 bg-gray-500 rounded-full"></div>
      </div>
      <div role="status" className="w-full divide-y divide-gray-300 rounded-full">
        {rows}
      </div>
      <span className="sr-only">Chargement des données...</span>
    </div>
  );
}