import cx from 'classnames';
import { useField } from 'formik';
import React from 'react';

type Option = {
  label: string;
  value: string;
};

interface SelectFieldProps {
  name: string;
  label: string;
  options: Option[];
  className?: string;
  isRequired?: boolean;
}

const SelectField: React.FC<SelectFieldProps> = ({ name, label, options, className, isRequired }) => {
  const [field, meta] = useField(name);
  const isError = meta.touched && meta.error; // Déterminer si le champ est en état d'erreur

  return (
    <div
      className={cx(
        className, // Classe de base
        { 'required': isRequired }, // Ajouter la classe 'required' si isRequired est vrai
        { 'error': isError }, // Ajouter la classe 'error' si le champ est en état d'erreur
      )}
    >
      <label htmlFor={name}>{label}</label>
      <select {...field} id={name} className={`select ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {meta.touched && meta.error ? (
        <div className="error explanation">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default SelectField;
