import React from 'react';
import RightNowLogo from 'components/svg/RightsNowLogo';
import IconSubscription from '../../components/icons/IconSubscription';
import StripeLogo from '../../components/svg/StripeLogo';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';
import IconLoading from '../../components/icons/IconLoading';
import GenericError from '../../components/GenericError';
import useUserInfo from 'hooks/use-user-info';

export default function SubscribePage() {
  const navigate = useNavigate();
  const {
    userInfo: { subscription },
    isLoading,
    isError,
  } = useUserInfo();

  if (isLoading) {
    return (
      <div className="h-56 text-sm flex items-center justify-center leading-none">
        <IconLoading className="w-5 h-5 text-primary mr-2" /> Chargement...
      </div>
    );
  }

  if (isError) {
    return <GenericError />;
  }

  if (subscription !== undefined) {
    navigate('/');
  }
  return (
    <>
      <div className="min-h-full pt-16 flex flex-col max-w-2xl mx-auto">
        <main className="flex-grow flex flex-col max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex mb-12">
            <span className="sr-only">RightsNow!</span> <RightNowLogo className="h-12 w-auto" />
          </div>
          <h1 className="text-green flex text-xl">
            <IconSubscription className="mr-2 w-6 flex-shrink-0" />
            <strong>Évolution de votre abonnement</strong>
          </h1>
          <p> Suite au changement du mode d’abonnement, merci de mettre à jour votre moyen de paiement. </p>
          <p>
            <strong>En tant qu’ancien·e membre, vous profitez AUTOMATIQUEMENT d'1 mois gratuit 🎉.</strong>
          </p>

          <div className="message bg-yellow-50 shadow mt-3 mb-6">
            <p>
              Sans action de votre part, le monitoring de vos titres sera automatiquement désactivé le XX/XX/XXXX à
              minuit et <strong>votre compte sera archivé</strong>.
            </p>
          </div>

          <p>
            Si vous le souhaitez, vous pouvez également <a onClick={() => {}}>supprimer votre compte</a> dès maintenant.
          </p>

          <div className="flex flex-col sm:flex-row items-baseline justify-between mt-6">
            <a
              className="w-full text-center pl-0 text-sm py-2 sm:w-fit text-primary mb-3 sm:mb-0"
              href="https://rightsnow.fr/support/"
              target="_blank"
              rel="noreferrer"
            >
              Besoin d'aide ?
            </a>
            <button
              className="w-full button text-sm py-2 sm:w-fit"
              onClick={async () => {
                const res = await api.createCustomerPortalSession();
                window.location.href = (res as any).data;
              }}
            >
              <IconSubscription className="mr-2 !w-4 hidden sm:inline-flex" />
              Gérer mon abonnement sur Stripe
            </button>
          </div>
          <hr className="border-gray-300 my-12" />
          <div className="mx-auto text-center">
            <span className="text-xs opacity-70">En partenariat avec</span>
            <StripeLogo className="w-14 fill-[#635bff] mx-auto" />
          </div>
        </main>
      </div>
    </>
  );
}
