import { StripeError } from '@stripe/stripe-js';
import { WretchError } from 'wretch';

export function isStripeError(error: unknown): error is StripeError {
  // return !!error && !!(error as (StripeError)).type ;
  return typeof error === 'object' && error !== null && 'type' in error;
}

export interface ApiError {
  json: {
    statusCode: number;
    message: string;
  };
}

export function isWretcherError(error: unknown): error is WretchError {
  return !!(error && (error as WretchError).status);
}

export function isApiError(error: unknown): error is ApiError {
  return (
    isWretcherError(error) &&
    error.json &&
    (error as ApiError).json.statusCode
  );
}
