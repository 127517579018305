import React, { useState } from 'react'
import cx from 'classnames'
import api from 'services/api'
import { Broadcast, TimeRange, TimeRangeInterval } from 'shared-types'
import { useQuery } from 'react-query'
import moment from 'moment'
import useUserInfo from 'hooks/use-user-info'
import EmptyStateUpload from '../../components/svg/EmptyStateUpload'
import Recharts from '../../components/Recharts'
import Select, { Theme } from 'react-select'
import { computeTotalsFromOverview } from 'utils/broadcastHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import LoaderChart from '../../components/LoaderChart'
import LoaderFilters from '../../components/LoaderFilters'

export interface BroadcastChartProps {
  noData?: boolean;
  trackId?: number;
}

interface State {
  loading: boolean;
  error: boolean;
  timeRange: TimeRange;
  showRights: boolean;
  stats: Broadcast[];
}

function getLowerBound(timeRange?: TimeRange) {
  switch (timeRange) {
    case 'last_7_days':
      return moment().subtract(7, 'days');
    case 'last_30_days':
      return moment().subtract(30, 'days');
    case 'last_365_days':
      return moment().subtract(365, 'days');
    case 'all_time':
      return undefined;
    default:
      // last 7 days
      return moment().subtract(7, 'days');
  }
}

export function getTimeRangeInterval(timeRange?: TimeRange): TimeRangeInterval {
  const upperBound = moment();
  const lowerBound = getLowerBound(timeRange);

  return {
    lowerBound: lowerBound,
    upperBound: upperBound,
  };
}

const timeRangeOptions: SelectOption<TimeRange>[] = [
  { value: 'all_time', label: 'Depuis le début' },
  { value: 'last_7_days', label: '7 derniers jours' },
  { value: 'last_30_days', label: '30 derniers jours' },
  { value: 'last_365_days', label: 'Depuis 1 an' },
];

const initialChannelTypeOptions: SelectOption<string>[] = [{ value: '', label: 'Tous les médias' }];

const initialChannelNameOptions: Array<SelectOption<string>> = [
  {
    value: '',
    label: 'Toutes les sources',
  },
];

// Custom design for Select
const customColors = {
  primary: '#003cff',
  primary75: '#99b1ff',
  primary50: '#e6ecff',
  primary25: '#f2f5ff',
  neutral20: '#d1d5db',
};

type StateType = {
  isFocused: boolean;
};

const getControlClassNames = (state: StateType) =>
  state.isFocused ? '!ring-4 !ring-primary-200/80 !border-primary' : '';

const customTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    ...customColors,
  },
});

interface SelectOption<T> {
  value: T;
  label: string;
}

export default function BroadcastChartWithFilter({
  trackId,
  forceChannel,
  customClass,
}: {
  trackId?: number;
  forceChannel?: 'streams' | 'radio' | 'tv';
  customClass?: string;
}) {
  const [showRights, setShowRights] = useState<boolean>(false);
  const [timeRange, setTimeRange] = useState<SelectOption<TimeRange>>(timeRangeOptions[0]);
  const [channelType, setChannelType] = useState<SelectOption<string>>(initialChannelTypeOptions[0]);
  const [channelName, setChannelName] = useState<SelectOption<string>>(initialChannelNameOptions[0]);

  const {
    userInfo: { trackCount },
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
  } = useUserInfo();

  const from = getTimeRangeInterval(timeRange.value).lowerBound?.unix();
  const to = getTimeRangeInterval(timeRange.value).upperBound?.unix();

  const {
    data: broadcastOverview,
    isLoading: isLoadingBroadcastOverview,
    isError: isErrorBroadcastOverview,
  } = useQuery(
    ['/api/broadcast-overview', timeRange, trackId, forceChannel, customClass],
    () =>
      api.fetchBroadcastOverview({
        from,
        to,
        trackId,
        media: forceChannel,
      }),
    { staleTime: Infinity },
  );

  const totalFromOverview = computeTotalsFromOverview(broadcastOverview);

  const {
    data: stats,
    isLoading: isLoadingStats,
    isError: isErrorStats,
  } = useQuery(
    ['/api/stats', timeRange, trackId, channelType, channelName, forceChannel],
    () =>
      api
        .fetchBroadcastStatReport({
          from: getTimeRangeInterval(timeRange.value).lowerBound?.unix(),
          to: getTimeRangeInterval(timeRange.value).upperBound.unix(),
          trackId,
          media: forceChannel || channelType.value,
          mediaName: channelName.value,
        })
        .then((d) => {
          return d;
          // })
          // .then(broadcastStats => {
          //   if (broadcastStats.length > 0) {
          //     const timeRangeInterval = getTimeRangeInterval(timeRange.value);
          //     const currentDate =
          //       timeRangeInterval.lowerBound === undefined
          //         ? moment(broadcastStats[0].date)
          //         : timeRangeInterval.lowerBound.startOf('day');
          //     const stopDate = timeRangeInterval.upperBound;
          //
          //     const statsToDisplay = [];
          //     while (currentDate <= stopDate) {
          //       var existing = broadcastStats.find(
          //         (elem) =>
          //           moment(elem.date).toDate().getTime() ===
          //           currentDate.toDate().getTime(),
          //       );
          //       if (existing !== undefined) {
          //         statsToDisplay.push(existing);
          //       } else {
          //         const newDate: Broadcast = {
          //           count: 0,
          //           date: currentDate.format('YYYY-MM-DD'),
          //           media: 'radio'
          //         };
          //         statsToDisplay.push(newDate);
          //       }
          //       currentDate.add(1, 'day');
          //     }
          //     return statsToDisplay;
          //   } else {
          //     return [];
          //   }
        }),
    { staleTime: Infinity },
  );

  const channelTypeOptions = [...initialChannelTypeOptions];
  if ((broadcastOverview?.streams || []).length > 0) {
    channelTypeOptions.push({ value: 'streams', label: 'Streams' });
  }
  if ((broadcastOverview?.liveBroadcasts || []).length > 0) {
    if ((broadcastOverview?.liveBroadcasts || []).filter((lb) => lb.type === 'radio').length > 0) {
      channelTypeOptions.push({ value: 'radio', label: 'Radios' });
    }
    if ((broadcastOverview?.liveBroadcasts || []).filter((lb) => lb.type === 'tv').length > 0) {
      channelTypeOptions.push({ value: 'tv', label: 'TV' });
    }
  }

  const channelNameOptions = [...initialChannelNameOptions];
  if (channelType.value === '') {
    const streamedPlatform = (broadcastOverview?.streams || []).map((s) => ({ name: s.platform, count: s.count }));
    const liveBroadcastChannelName = (broadcastOverview?.liveBroadcasts || []).flatMap((lb) => lb.channels);
    channelNameOptions.push(
      ...streamedPlatform.concat(liveBroadcastChannelName).map((v) => ({
        value: v.name,
        label: `${v.name} (${v.count.toLocaleString('fr-FR', {
          notation: 'compact',
          compactDisplay: 'short',
          maximumFractionDigits: 1,
        })})`,
      })),
    );
  } else if (channelType.value === 'streams') {
    const streamedPlatform = (broadcastOverview?.streams || []).map((s) => ({ name: s.platform, count: s.count }));
    channelNameOptions.push(
      ...streamedPlatform.map((v) => ({
        value: v.name,
        label: `${v.name} (${v.count.toLocaleString('fr-FR', {
          notation: 'compact',
          compactDisplay: 'short',
          maximumFractionDigits: 1,
        })})`,
      })),
    );
  } else if (channelType.value === 'radio') {
    const liveBroadcastChannelName = (broadcastOverview?.liveBroadcasts || [])
      .filter((lb) => lb.type === 'radio')
      .flatMap((lb) => lb.channels);
    channelNameOptions.push(
      ...liveBroadcastChannelName.map((v) => ({
        value: v.name,
        label: `${v.name} (${v.count.toLocaleString('fr-FR', {
          notation: 'compact',
          compactDisplay: 'short',
          maximumFractionDigits: 1,
        })})`,
      })),
    );
  } else if (channelType.value === 'tv') {
    const liveBroadcastChannelName = (broadcastOverview?.liveBroadcasts || [])
      .filter((lb) => lb.type === 'tv')
      .flatMap((lb) => lb.channels);
    channelNameOptions.push(
      ...liveBroadcastChannelName.map((v) => ({
        value: v.name,
        label: `${v.name} (${v.count.toLocaleString('fr-FR', {
          notation: 'compact',
          compactDisplay: 'short',
          maximumFractionDigits: 1,
        })})`,
      })),
    );
  }

  const updateChannelType = (e: any) => {
    setChannelName(channelNameOptions[0]);
    setChannelType(e);
  };

  const isLoading = isLoadingUserInfo || isLoadingStats || isLoadingBroadcastOverview;
  const isError = isErrorStats || isErrorBroadcastOverview || isErrorUserInfo;
  const noTrack = !isLoading && !isError && trackCount === 0;
  const noData = !isLoading && !isError && totalFromOverview.totalBroadcast === 0;
  const noDataWithFilters = !noData && stats && stats.length === 0;

  const totalBroadcast = (stats || []).reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);

  const loadingMessage = isLoading && <LoaderChart className="h-72 overflow-hidden" />;

  const noDataMessage = !noTrack && noData && (
    <div className="bg-yellow-200 p-6 rounded">
      <p className="text-lg">
        <strong>Aucune donnée</strong>
      </p>
      <p>Dès que l'un de vos titres sera diffusé, les données seront affichées.</p>
    </div>
  );

  const noDataWithFiltersMessage = noDataWithFilters && (
    <div className="bg-yellow-200 p-6 my-6 rounded">
      <p>😭 Aucune donnée récoltée sur ce(s) média(s) durant cette période...</p>
    </div>
  );

  const noTrackMessage = noTrack && (
    <div className="bg-primary-50 p-6 my-6 rounded text-center">
      <EmptyStateUpload className="w-64 mb-6 mx-auto" />
      <p>Pour commencer à récolter les diffusions,</p>
      <a href="/track-upload" className="button">
        Ajoutez votre premier titre
      </a>
    </div>
  );

  const errorMessage = !isLoading && isError && (
    <div className="bg-red-100 p-6 my-6 rounded">
      <h4>Oups !</h4>
      <p className="text-red-700">
        Une erreur est survenue mais ce n'est pas de votre faute. <br /> Merci de réessayer dans quelques instants.
      </p>
    </div>
  );

  return (
    <React.Fragment>
      {isLoading && <LoaderFilters className="mb-6 lg:!w-2/3 mx-auto" />}
      {!isLoading && !isError && totalFromOverview.totalBroadcast > 0 && (
        <>
          <div className="flex flex-wrap space-x-2 items-center mb-6 mx-auto md:justify-center">
            <span>Afficher les diffusions provenant de</span>
            <Select
              onChange={setChannelName}
              className="react-select"
              theme={customTheme}
              classNames={{ control: getControlClassNames }}
              options={channelNameOptions}
              isSearchable={true}
              value={channelName}
            />
            <span>sur</span>
            <Select
              onChange={setTimeRange}
              className="react-select"
              theme={customTheme}
              classNames={{ control: getControlClassNames }}
              options={timeRangeOptions}
              isSearchable={false}
              defaultValue={timeRange}
            />
            <FontAwesomeIcon icon={duotone('arrow-right')} className="h-5 text-gray-600" />
            <span>
              Total : <strong>{totalBroadcast.toLocaleString()}</strong>
            </span>
          </div>
          {/*{forceChannel === undefined && (*/}
          {/*  <Select*/}
          {/*    onChange={updateChannelType}*/}
          {/*    className="w-full react-select"*/}
          {/*    theme={customTheme}*/}
          {/*    classNames={{control: getControlClassNames}}*/}
          {/*    options={channelTypeOptions}*/}
          {/*    isSearchable={false}*/}
          {/*    value={channelType}/>*/}
          {/*)}*/}
        </>
      )}
      {noTrackMessage} {noDataMessage} {noDataWithFiltersMessage} {loadingMessage} {errorMessage}
      <div
        className={cx('chart w-full overflow-hidden h-72', customClass, {
          hidden: noData || isLoading || isError,
        })}
      >
        {/* <BarGraph broadcastData={stats} /> */}{' '}
        {/*{!isLoadingStats && !isErrorStats && stats && stats.length > 0 && (*/}
        {/*  <AreaGraph data={stats || []} />*/}
        {/*)}*/}
        {!isLoading && !isError && stats && stats.length > 0 && <Recharts data={stats || []} from={from} to={to} />}
      </div>
    </React.Fragment>
  );
}
