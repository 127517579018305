import React from 'react';

const GrooverLogo: React.FC = () => {
  return (
    <svg viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Payin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Payin-Cart-(applyText/NoCalculation)-Copy-32" transform="translate(-64.000000, -16.000000)" fill="#EB6345" fillRule="nonzero">
          <g id="navbar">
            <g id="TopBar-Copy-5" transform="translate(64.000000, 16.000000)">
              <g id="Group-6">
                <g id="Group-9">
                  <g id="Logo">
                    <path d="M27.460587,15.0293546 L29.9672843,15.0293546 C29.9919358,16.916142 29.6309569,18.7881442 28.9063915,20.531043 C27.7383126,23.4740747 25.6505684,25.9628396 22.9529854,27.6279988 C17.4485456,31.0732132 10.3462049,30.5842839 5.36790799,26.4174315 C0.389611125,22.2505791 -1.32943145,15.355979 1.1116356,9.34671051 C3.6437816,3.15612059 9.57492006,-0.018540905 14.9239194,0.0338410097 L14.9239194,2.42277379 C13.9950417,2.56245889 13.0741168,2.64500009 12.1786405,2.84341644 C7.01255372,3.98788191 3.08868175,8.44510665 2.57811714,13.7086954 C1.9291752,20.3992945 6.55447706,26.2978156 13.2522577,27.3073579 C19.8736923,28.305789 26.2947183,23.5850673 27.3142569,16.9674854 C27.4128707,16.3230292 27.4224139,15.6658742 27.4733113,15.0166559 L27.460587,15.0293546 Z"
                          id="Shapesel"></path>
                    <path d="M22.3517598,15.0372912 L24.8330084,15.0372912 C25.3785649,17.7246422 22.9243556,21.8945601 19.7862123,23.6231633 C15.9874796,25.7141676 11.2677378,25.0978783 8.13593862,22.1019065 C5.00413946,19.1059347 4.18755011,14.4260057 6.12025856,10.5499072 C7.80146353,7.21651265 11.9527833,4.65932281 14.9318721,5.14345869 L14.9318721,7.46731091 C14.1366002,7.6371553 13.3413282,7.72763315 12.6049063,7.97525675 C9.08994145,9.17267469 7.00699694,12.782836 7.73385471,16.4177678 C8.46071248,20.0526997 11.7726191,22.5884359 15.4788848,22.3476916 C19.1851506,22.1069473 22.1397521,19.1641636 22.3883423,15.4658706 C22.3866493,15.3192717 22.3754924,15.1729341 22.3549409,15.0277673 L22.3517598,15.0372912 Z" id="Shapesel"></path>
                    <path d="M27.4717208,15.0166559 C26.9404791,14.9944333 26.4092374,14.9706234 25.8811768,14.9547501 C25.5996505,14.9452261 25.3197148,14.9547501 24.9697951,14.9547501 L24.9697951,14.392835 C24.9697951,11.4483364 24.9618424,8.50225056 24.9793384,5.55775202 C24.9793384,5.14345869 24.8616381,5.03234554 24.4528683,5.0355202 C21.5167242,5.05139351 18.58058,5.04345685 15.6444358,5.04345685 L15.0511629,5.04345685 L15.0511629,2.58309419 L27.4574059,2.58309419 C27.4574059,6.73872609 27.4574059,10.8874796 27.4574059,15.0293546 L27.4717208,15.0166559 Z" id="Shapesel"></path>
                    <path
                      d="M19.3949385,16.9484375 C18.4688698,19.0444613 16.1839877,20.1912502 13.9457349,19.6834084 C11.688054,19.1860966 10.0977337,17.1651358 10.1488328,14.8583484 C10.1999318,12.5515611 11.8781897,10.6027122 14.1556867,10.2054564 C14.402221,10.1625985 14.6551175,10.1641858 14.9668641,10.1419632 C14.9668641,10.9356286 14.9764074,11.6848487 14.9525492,12.4356562 C14.9525492,12.5134354 14.7616839,12.6293105 14.6439837,12.6515331 C13.481296,12.8737594 12.6621659,13.8055226 12.6494416,14.9468134 C12.64824,16.1424768 13.5493928,17.147218 14.7402881,17.2780048 C15.9311834,17.4087916 17.0297009,16.6236587 17.2890583,15.4563466 C17.3176881,15.3134868 17.3431368,15.1690397 17.3685855,15.0325293 L19.902322,15.0325293 C19.7257717,15.6642869 19.6541972,16.3468391 19.3949385,16.9484375 Z"
                      id="Shapesel"></path>
                    <path d="M19.902322,15.0325293 C19.902322,13.5452003 19.8784639,12.0578714 19.902322,10.5705425 C19.902322,10.1641858 19.7782596,10.0721207 19.3949385,10.07847 C18.1225033,10.1006926 16.861202,10.07847 15.5935384,10.07847 L15.07184,10.07847 L15.07184,7.63239331 L22.3517598,7.63239331 L22.3517598,15.0357039 C22.3517598,15.0357039 22.3517598,15.0261799 22.3517598,15.0357039 L19.8784639,15.0261799 L19.902322,15.0325293 Z" id="Shapesel"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GrooverLogo;
