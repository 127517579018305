import { useAuth } from 'hooks/use-auth';
import React from 'react';
import { useQuery } from 'react-query';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import api from 'services/api';
import RightsNowLogo from '../../components/svg/RightsNowLogo';
import PageTitle from "../layout/PageTitle";

type VerificationResult =
  | 'VERIFICATION_SUCCESS'
  | 'ALREADY_VERIFIED'
  | 'VERIFICATION_FAILED';

function fbqTrackLead() {
  window.fbq('track', 'Lead')
}

async function verifyEmail(
  email?: string | null,
  token?: string | null,
): Promise<VerificationResult> {
  if (email && token) {
    try {
      const response = await api.verifyEmail(email, token);
      if (response.includes('already')) {
        return 'ALREADY_VERIFIED';
      } else {
        fbqTrackLead();
        return 'VERIFICATION_SUCCESS';
      }
    } catch (error) {
      console.log(error);
      return 'VERIFICATION_FAILED';
    }
  } else {
    return Promise.resolve('VERIFICATION_FAILED');
  }
}

export default function EmailVerification() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get('email');
  const token = query.get('token');

  const {
    data: verificationResult,
    isLoading,
    isError,
  } = useQuery(
    ['/api/verify-email', email, token],
    async () => verifyEmail(email, token),
    { staleTime: Infinity },
  );

  return (
    <>
      <PageTitle title="Email validé"/>
      <div className="flex justify-center md:pt-16">
        <div className="w-full self-center text-center md:w-2/3 lg:w-1/2 p-10 md:rounded-lg">
          <div className="w-64 mb-12 mx-auto">
            <a href="https://rightsnow.fr/">
              <RightsNowLogo/>
            </a>
          </div>
          {isLoading && <span>Loading...</span>}{' '}
          {isError ||
            (verificationResult &&
              verificationResult === 'VERIFICATION_FAILED' && (
                <div className="segment red">
                  <div>
                    <h4>🤓 Oups !</h4>
                    <p className="text-red">
                      <strong>
                        Nous n'avons pas réussi à valider votre email.
                      </strong>
                    </p>
                    <p>
                      Merci de vérifier que l'email que vous avez renseigné est
                      correct. Sinon,{' '}
                      <a
                        href="https://www.rightsnow.fr/support/"
                        className="underline"
                      >
                        {' '}
                        contactez-nous{' '}
                      </a>{' '}
                      .
                    </p>
                  </div>
                </div>
              ))}{' '}
          {verificationResult && verificationResult !== 'VERIFICATION_FAILED' && (
            <div
              className={cx('segment', {
                yellow: verificationResult === 'ALREADY_VERIFIED',
              })}
            >
              {verificationResult === 'VERIFICATION_SUCCESS' && (
                <div>
                  <h4>👏 Félicitation !</h4>
                  <p className="mb-12">
                    <strong>Merci !</strong> Votre email a bien été validé.
                  </p>
                  <a href="/dashboard" className="button">
                    {' '}
                    Je me connecte{' '}
                  </a>
                </div>
              )}{' '}
              {verificationResult === 'ALREADY_VERIFIED' && (
                <div className="text-center md:text-left">
                  <h4>🤓 Oups !</h4>
                  <p className="mb-12">Votre email a déjà été validé.</p>
                  <a
                    href="/dashboard"
                    className="button w-full md:w-auto mr-4 mb-6 md:mb-0"
                  >
                    {' '}
                    Je me connecte{' '}
                  </a>{' '}
                  <a href="/dashboard"> J'ai oublié mon mot de passe </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
