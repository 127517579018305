import cx from 'classnames';
import { FieldProps } from 'formik';
import React from 'react';

type Option = {
  value: string;
  label: string;
};

interface InputProps extends FieldProps {
  label: string;
  className?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  type?: string;
  hint?: string;
  customHint?: React.ReactNode;
  placeholder?: string;
  as?: 'input' | 'select';
  options?: Option[];
}

class InputField extends React.Component<InputProps> {
  render() {
    const {
      field, // destructuring assignment pour extraire name, onBlur, onChange, value depuis field
      form: { errors, touched },
      label,
      className,
      isRequired,
      isDisabled,
      type,
      hint,
      customHint,
      placeholder,
      as = 'input', // valeur par défaut à 'input'
      options,
    } = this.props;

    const { name, onBlur, onChange, value } = field;
    const fieldError = errors[name];
    const isError = touched[name] && errors[name];

    return (
      <div
        className={cx(
          className,
          { 'required': isRequired },
          { 'helper': hint || customHint },
          { 'error': isError },
        )}
      >
        <label htmlFor={name}>{label}</label>
        {hint && <div className="hint">{hint}</div>}
        {customHint}
        {
          as === 'select' ?
            (
              <select
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isDisabled}
                className={className}
              >
                {options?.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            ) :
            (
              <input
                id={name}
                name={name}
                type={type || 'text'}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isDisabled}
                placeholder={placeholder}
                className={className}
              />
            )
        }
        {isError && (
          <div className="error explanation">
            {typeof fieldError === 'string'
              ? fieldError
              : (fieldError as string[])[0]}
          </div>
        )}
      </div>
    );
  }
}

export default InputField;
