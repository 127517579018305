import PageTitle from 'features/layout/PageTitle';
import PartnerOffers from "./PartnerOffers";

const PartnerOffersPage = () => {
  return (
    <>
      <PageTitle title="Offres partenaires"/>
      <PartnerOffers />
    </>
  );
};

export default PartnerOffersPage;
