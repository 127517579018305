import React from 'react';

export default function LoaderFilters({className}: { className?: string }) {

  return (
    <div className={`flex flex-row w-full animate-pulse items-center justify-center space-x-2  ${className ? className : ''}`}>
      <div className="h-4 bg-gray-200 rounded-full w-3/12"></div>
      <div className="h-4 bg-gray-100 rounded-full w-3/12"></div>
      <div className="h-4 bg-gray-200 rounded-full w-2/12"></div>
      <div className="h-4 bg-gray-100 rounded-full w-4/12"></div>
      <span className="sr-only">Chargement des filtres...</span>
    </div>
  );
}

