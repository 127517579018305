import {FormikErrors, FormikValues, getIn, setIn, validateYupSchema} from 'formik';
import * as Yup from 'yup';

function yupToFormErrors(
  yupError: Yup.ValidationError,
  validationSchemaOptions: { showMultipleFieldErrors: boolean },
) {
  let errors: { [key: string]: any } = {};
  if (yupError.inner.length === 0 && yupError.path) {
    return setIn(errors, yupError.path, yupError.message);
  }
  // if showMultipleFieldErrors is enabled, set the error value
  // to an array of all errors for that field
  if (validationSchemaOptions.showMultipleFieldErrors) {
    for (let err of yupError.inner) {
      if (err.path) {
        let fieldErrors = getIn(errors, err.path);
        if (!fieldErrors) {
          fieldErrors = [];
        }
        fieldErrors.push(err.message);
        errors = setIn(errors, err.path, fieldErrors);
      }
    }
  } else {
    for (let err of yupError.inner) {
      if (err.path && !errors[err.path]) {
        errors = setIn(errors, err.path, err.message);
      }
    }
  }
  return errors;
}

export const validateYupSchemaMultiErrors: <T extends FormikValues>(
  values: T,
  schema: Yup.ObjectSchema<any, any, any, any>,
) => Promise<FormikErrors<T>> = async (values, schema) => {
  try {
    await validateYupSchema(values, schema);
    return {};
  } catch (e: unknown) {
    if (e instanceof Yup.ValidationError) {
      return yupToFormErrors(e, { showMultipleFieldErrors: true });
    } else {
      throw e;
    }
  }
};
