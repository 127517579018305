import React from 'react';
import cx from 'classnames';
import { createCORSRequest } from 'utils/CORSRequest';
import {APIDataResponse, TrackUpload} from 'shared-types';
import Tippy from '@tippyjs/react';
import IconFileMusic from './icons/IconFileMusic';
import IconDelete from './icons/IconDelete';

export type StatusValue =
  | ''
  | 'rejected_file_type'
  | 'rejected_max_files'
  | 'preparing'
  | 'error_file_size'
  | 'error_validation'
  | 'ready'
  | 'started'
  | 'getting_upload_params'
  | 'error_upload_params'
  | 'uploading'
  | 'exception_upload'
  | 'aborted'
  | 'restarted'
  | 'removed'
  | 'error_upload'
  | 'headers_received'
  | 'done';

export interface IMeta {
  id: string;
  status: StatusValue;
  type: string; // MIME type, example: `image/*`
  name: string;
  percent: number;
  size: number; // bytes
  duration?: number; // seconds
}

export interface IFileWithMeta {
  file: File;
  meta: IMeta & Partial<TrackUpload>;
  cancel: () => void;
  restart: () => void;
  remove: () => void;
  xhr?: XMLHttpRequest;
}

export type FileStatusChangeHandler = (data: {
  file: IFileWithMeta;
  status: StatusValue;
  allFiles: IFileWithMeta[];
}) => { meta: { [name: string]: any } } | void;

export interface DropzoneProps {
  onFileStatusChange?: FileStatusChangeHandler;
  isUploadRunning?: (isUploadRunning: boolean) => void;
  initialFiles?: IFileWithMeta[];
  uploadUrl: string;
  config: {
    minSizeBytes: number;
    maxSizeBytes: number;
    maxDuration?: string;
    maxFiles: number;
    accept: string;
    fileIcon: React.ReactNode;
  };
  emptyMeta: React.ReactNode;
  disabled?: boolean;
}

export default class Dropzone extends React.Component<
  DropzoneProps,
  {
    active: boolean;
    dragged: (File | DataTransferItem)[];
  }
> {
  private fileInputRef = React.createRef<HTMLInputElement>();
  private dropzone = React.createRef<HTMLDivElement>();
  private mounted: boolean;
  private files: IFileWithMeta[];
  private dragTimeoutId?: number;

  constructor(props: DropzoneProps) {
    super(props);
    this.state = {
      active: false,
      dragged: [],
    };
    this.mounted = true;
    this.files = this.props.initialFiles
      ? this.props.initialFiles.map((f) => {
          return {
            ...f,
            remove: () => this.handleRemove(f),
          };
        })
      : [];
  }

  componentWillUnmount() {
    this.mounted = false;
    for (const fileWithMeta of this.files) this.handleCancel(fileWithMeta);
  }

  forceUpdate = () => {
    if (this.mounted) super.forceUpdate();
  };

  handleFileStatusChange = (fileWithMeta: IFileWithMeta) => {
    if (!this.props.onFileStatusChange) return;
    const { meta = {} } =
      this.props.onFileStatusChange({
        file: fileWithMeta,
        status: fileWithMeta.meta.status,
        allFiles: this.files,
      }) || {};
    if (meta) {
      delete meta.status;
      fileWithMeta.meta = { ...fileWithMeta.meta, ...meta };
      this.forceUpdate();
    }
  };

  openFileDialog = () => {
    this.fileInputRef && this.fileInputRef.current && this.fileInputRef.current.click();
  };

  handleDragEnter = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const dragged = this.getFilesFromEvent(e);
    this.setState({ active: true, dragged });
  };

  handleDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    clearTimeout(this.dragTimeoutId);
    const dragged = this.getFilesFromEvent(e);
    this.setState({ active: true, dragged });
  };

  handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // prevents repeated toggling of `active` state when file is dragged over children of uploader
    // see: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
    this.dragTimeoutId = window.setTimeout(() => this.setState({ active: false, dragged: [] }), 150);
  };

  handleDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ active: false, dragged: [] });
    const files = this.getFilesFromEvent(e) as File[];
    this.handleFiles(files);
  };

  onFilesAdded = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    const files = this.getFilesFromEvent(evt) as File[];
    this.handleFiles(files);
  };

  // expects an array of File objects
  handleFiles = (files: File[]) => {
    files.forEach((f, i) => this.handleFile(f, `${new Date().getTime()}-${i}`));
    const { current } = this.dropzone;
    if (current) setTimeout(() => current.scroll({ top: current.scrollHeight, behavior: 'smooth' }), 150);
  };

  handleFile = async (file: File, id: string) => {
    const { name, size, type, lastModified } = file;
    const { minSizeBytes, maxSizeBytes, maxDuration, maxFiles, accept } = this.props.config;
    const status = '';
    const uploadedDate = new Date().toISOString();
    const lastModifiedDate = lastModified && new Date(lastModified).toISOString();
    const fileWithMeta = {
      file,
      meta: {
        name,
        size,
        type,
        lastModifiedDate,
        uploadedDate,
        percent: 0,
        id,
        status,
      },
      cancel: () => this.handleCancel(fileWithMeta),
      remove: () => this.handleRemove(fileWithMeta),
      restart: () => this.handleRestart(fileWithMeta),
    } as IFileWithMeta;

    // firefox versions prior to 53 return a bogus mime type for file drag events,
    // so files with that mime type are always accepted
    if (file.type !== 'application/x-moz-file' && !this.accepts(file, accept)) {
      fileWithMeta.meta.status = 'rejected_file_type';
      this.handleFileStatusChange(fileWithMeta);
      return;
    }
    if (this.files.length >= maxFiles) {
      fileWithMeta.meta.status = 'rejected_max_files';
      this.handleFileStatusChange(fileWithMeta);
      return;
    }

    fileWithMeta.meta.status = 'preparing';
    this.files.push(fileWithMeta);
    this.handleFileStatusChange(fileWithMeta);
    this.forceUpdate();

    if (size < minSizeBytes || size > maxSizeBytes) {
      fileWithMeta.meta.status = 'error_file_size';
      this.handleFileStatusChange(fileWithMeta);
      this.forceUpdate();
      return;
    }

    // check audio duration
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    audio.addEventListener('loadedmetadata', () => {
      fileWithMeta.meta.duration = audio.duration;
    });

    this.uploadFile(fileWithMeta);
  };

  formatBytes = (b: number) => {
    const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0;
    let n = b;

    while (n >= 1024) {
      n /= 1024;
      l += 1;
    }

    return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`;
  };

  formatDuration = (seconds: number) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    const dateString = date.toISOString().slice(11, 19);
    if (seconds < 3600) return dateString.slice(3);
    return dateString;
  };

  // adapted from: https://github.com/okonet/attr-accept/blob/master/src/index.js
  // returns true if file.name is empty and accept string is something like ".csv",
  // because file comes from dataTransferItem for drag events, and
  // dataTransferItem.name is always empty
  accepts = (file: File, accept: string) => {
    if (!accept || accept === '*') return true;

    const mimeType = file.type || '';
    const baseMimeType = mimeType.replace(/\/.*$/, '');

    return accept
      .split(',')
      .map((t) => t.trim())
      .some((type) => {
        if (type.charAt(0) === '.') {
          return file.name === undefined || file.name.toLowerCase().endsWith(type.toLowerCase());
        } else if (type.endsWith('/*')) {
          // this is something like an image/* mime type
          return baseMimeType === type.replace(/\/.*$/, '');
        }
        return mimeType === type;
      });
  };

  getFilesFromEvent = (
    event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>,
  ): Array<File | DataTransferItem> => {
    let items = null;

    if ('dataTransfer' in event) {
      const dt = event.dataTransfer;

      // NOTE: Only the 'drop' event has access to DataTransfer.files, otherwise it will always be empty
      if ('files' in dt && dt.files.length) {
        items = dt.files;
      } else if (dt.items && dt.items.length) {
        items = dt.items;
      }
    } else if (event.target && event.target.files) {
      items = event.target.files;
    }

    return Array.prototype.slice.call(items || []);
  };

  handleCancel = (fileWithMeta: IFileWithMeta) => {
    if (fileWithMeta.meta.status !== 'uploading') return;
    fileWithMeta.meta.status = 'aborted';
    if (fileWithMeta.xhr) fileWithMeta.xhr.abort();
    this.handleFileStatusChange(fileWithMeta);
    this.forceUpdate();
  };

  handleRemove = (fileWithMeta: IFileWithMeta) => {
    const index = this.files.findIndex((f) => f.meta.id === fileWithMeta.meta.id);
    if (index !== -1) {
      fileWithMeta.meta.status = 'removed';
      if (fileWithMeta.xhr) fileWithMeta.xhr.abort();
      this.files.splice(index, 1);
      this.handleFileStatusChange(fileWithMeta);
      this.forceUpdate();
    }
  };

  handleRestart = (fileWithMeta: IFileWithMeta) => {
    if (fileWithMeta.meta.status === 'ready') fileWithMeta.meta.status = 'started';
    else fileWithMeta.meta.status = 'restarted';
    this.handleFileStatusChange(fileWithMeta);

    fileWithMeta.meta.status = 'getting_upload_params';
    fileWithMeta.meta.percent = 0;
    this.handleFileStatusChange(fileWithMeta);
    this.forceUpdate();
    this.uploadFile(fileWithMeta);
  };

  uploadFile = async (fileWithMeta: IFileWithMeta) => {
    const formData = new FormData();
    const xhr = createCORSRequest('POST', `${process.env.REACT_APP_API_URL}/${this.props.uploadUrl}`);
    if (!xhr) {
      throw new Error('CORS not supported');
    }
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    const token = window.localStorage.getItem('user_token');
    if (token) {
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    }

    // update progress (can be used to show progress indicator)
    xhr.upload.addEventListener('progress', (e: any) => {
      fileWithMeta.meta.percent = (e.loaded * 100.0) / e.total || 100;
      this.forceUpdate();
    });

    xhr.addEventListener('readystatechange', () => {
      // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
      if (xhr.readyState !== 2 && xhr.readyState !== 4) return;

      if (xhr.status === 0 && fileWithMeta.meta.status !== 'aborted') {
        fileWithMeta.meta.status = 'exception_upload';
        this.handleFileStatusChange(fileWithMeta);
        this.forceUpdate();
      }

      if (xhr.status > 0 && xhr.status < 400) {
        fileWithMeta.meta.percent = 100;
        if (xhr.readyState === 2) fileWithMeta.meta.status = 'headers_received';
        if (xhr.readyState === 4) {
          fileWithMeta.meta.status = 'done';
          if (xhr.responseText) {
            const jsonResponse = JSON.parse(xhr.responseText) as APIDataResponse<TrackUpload>;
            if (jsonResponse) {
              fileWithMeta.meta.filename = jsonResponse.data.filename;
              fileWithMeta.meta.originalname = jsonResponse.data.originalname;
            }
          }
        }
        this.handleFileStatusChange(fileWithMeta);
        this.forceUpdate();
      }

      if (xhr.status >= 400 && fileWithMeta.meta.status !== 'error_upload') {
        fileWithMeta.meta.status = 'error_upload';
        this.handleFileStatusChange(fileWithMeta);
        this.forceUpdate();
      }
    });

    formData.set('file', fileWithMeta.file, fileWithMeta.file.name);
    // if (this.props.timeout) xhr.timeout = this.props.timeout;
    xhr.send(formData);
    fileWithMeta.xhr = xhr;
    fileWithMeta.meta.status = 'uploading';
    this.handleFileStatusChange(fileWithMeta);
    this.forceUpdate();
  };

  render() {
    const { active } = this.state;
    const uploadingFile = !!this.files.find((f) => f.meta.status === 'uploading');
    this.props.isUploadRunning && this.props.isUploadRunning(uploadingFile);
    return (
      <div className={`${this.props.disabled ? 'disabled' : ''}`}>
        <input
          ref={this.fileInputRef}
          style={{ display: 'none' }}
          type="file"
          accept={this.props.config.accept}
          multiple
          onChange={this.onFilesAdded}
        />{' '}
        {this.files.length == 0 && (
          <div
            className={cx('dropzone', { hightlight: active })}
            onClick={this.openFileDialog}
            onDragOver={this.handleDragOver}
            onDragLeave={this.handleDragLeave}
            onDrop={this.handleDrop}
          >
            <IconFileMusic className="!w-12 mb-6 text-primary" />
            {this.props.emptyMeta}
          </div>
        )}
        {this.files.length > 0 && (
          <div
            ref={this.dropzone}
            className="dropzone populated space-y-2 w-full flex justify-start text-xs overflow-y-auto customScrollBar"
          >
            {this.files.map((fileWithMeta) => {
              const status = fileWithMeta.meta.status;
              const isSuccess = status === 'done' || status === 'headers_received';
              const isError =
                status === 'error_upload_params' || status === 'exception_upload' || status === 'error_upload';
              const barPercent =
                isSuccess || (isError && fileWithMeta.meta.percent == 0) ? 100 : fileWithMeta.meta.percent;
              const fileSize = this.formatBytes(fileWithMeta.meta.size);
              return (
                <div className="w-full p-2 relative rounded bg-white shadow hover:bg-yellow-200">
                  <div>
                    <div className="flex justify-start items-center w-full" key={fileWithMeta.meta.id}>
                      <div className="flex-shrink-0 text-primary">{this.props.config.fileIcon}</div>
                      <div className="text-truncate tracking-wide ml-3">
                        {fileWithMeta.meta.name} <span className="text-xs opacity-60">({fileSize})</span>
                      </div>
                      {fileWithMeta.xhr && (
                        <div className={cx('relative pt-1', { success: isSuccess }, { error: isError })}>
                          <div className="overflow-hidden h-2 text-xs flex rounded bg-primary-200" />
                          <div
                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"
                            style={{ width: `${barPercent}%` }}
                          />
                        </div>
                      )}{' '}
                      <Tippy content="Retirer ce fichier">
                        <button
                          className="button p-1.5 ml-auto bg-red text-white self-baseline hover:bg-red-700 focus:ring-red-200"
                          onClick={fileWithMeta.remove}
                        >
                          <IconDelete />
                        </button>
                      </Tippy>
                    </div>
                    {fileWithMeta.xhr && (
                      <div className={cx('bg-gray-200 rounded overflow-hidden h-1 w-full mt-1', { hidden: isSuccess })}>
                        <div
                          className={cx(
                            'h-full bg-primary rounded',
                            { '!bg-green': isSuccess },
                            { '!bg-red': isError },
                          )}
                          style={{ width: `${barPercent}%` }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}{' '}
            {this.props.config.maxFiles > 1 && (
              <button className="button ghost border-gray-500 text-gray-500 mt-6" onClick={this.openFileDialog}>
                Ajouter un autre titre
              </button>
            )}
          </div>
        )}
        {uploadingFile && (
          <div className="message bg-yellow-200 text-yellow-900 my-4 p-4">
            Ne fermez pas cette page avant la fin des téléchargements.
          </div>
        )}
      </div>
    );
  }
}
