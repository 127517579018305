import React from 'react';
import {useAuth} from 'hooks/use-auth';
import useUserInfo from 'hooks/use-user-info';
import {Link, useNavigate} from 'react-router-dom';
import {ActiveLink} from 'components/ActiveLink';
import RightsNowLogo from '../../components/svg/RightsNowLogo';
import RightsNowLogoIcon from '../../components/svg/RightsNowLogoIcon';
import IconMenu from '../../components/icons/IconMenu';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {duotone} from '@fortawesome/fontawesome-svg-core/import.macro';
import { Analytics } from '@customerio/cdp-analytics-node'

export default function NavigationHeader() {
  const {
    userInfo: {
      registerTs,
      userName,
      unreadNotif,
      trackCount,
      subscription,
      email
    },
  } = useUserInfo();
  const navigate = useNavigate();
  const {signout} = useAuth();
  const handleSignout = () => {
    signout();
    navigate('/login');
  };

  const subscriptionStatus = subscription?.status
  const registerDate = new Date(registerTs)

  if (subscriptionStatus === undefined || subscriptionStatus === 'canceled') {
    navigate('/canceled-subscription');
  } else if (subscriptionStatus === 'past_due' || subscriptionStatus === 'unpaid') {
    navigate('/past-due');
  }

  // Customer.io Node.js SDK
  const analytics = new Analytics({
    writeKey: '1fb16a550f87ab61a8fb',
    host: 'https://cdp-eu.customer.io',
  })

  analytics.identify({
    userId: registerTs.toString(), // Utilisez le timestamp comme identifiant unique si nécessaire
    traits: {
      name: userName,
      email: email,
    }
  });

  // if (isLoading) {
  //   return (
  //     <div className="h-56 text-sm flex items-center justify-center leading-none">
  //       <IconLoading className="w-5 h-5 text-primary mr-2" /> Chargement...
  //     </div>
  //   );
  // }

  return (
    <React.Fragment>
      <div className="relative flex justify-between max-w-screen-xl px-4 mx-auto items-center md:flex-row md:px-6 lg:px-8">
        <a href="/dashboard">
          <RightsNowLogo className="py-4 w-40 mr-4 relative md:hidden sm:inline-block lg:inline-block"/>
        </a>
        <a href="/dashboard">
          <RightsNowLogoIcon className="py-4 w-8 mr-4 relative hidden md:inline-block lg:hidden sm:hidden"/>
        </a>
        <input className="hidden" type="checkbox" id="hamburger"/>
        <label className="flex items-center md:hidden text-gray-700 ml-auto w-8 cursor-pointer m-0" htmlFor="hamburger">
          <IconMenu/>
        </label>
        <div className="mainItems md:ml-3">
          <div className="flex justify-between flex-col space-y-2 md:py-4 md:flex-row md:items-center md:mr-auto md:space-y-0 md:space-x-2 transition-all">
            <ActiveLink
              to="/dashboard"
              className="text-base lg:text-base px-3.5 py-2.5 rounded-lg flex items-center hover:bg-white/50"
            >
              <FontAwesomeIcon icon={duotone("home-user")} className="md:hidden lg:inline-block h-5"/>
              <span className="ml-2 sm:ml-0 lg:ml-2">Tableau de bord</span>
            </ActiveLink>
            <ActiveLink
              to="/my-tracks"
              className="text-base lg:text-base px-3.5 py-2.5 rounded-lg flex items-center hover:bg-white/50"
            >
              <FontAwesomeIcon icon={duotone("list-music")} className="md:hidden lg:inline-block h-5"/>
              <span className="ml-2 sm:ml-0 lg:ml-2">
                Mes titres
                {trackCount > 0 && <span className="counter">{trackCount}</span>}
              </span>
            </ActiveLink>
            <ActiveLink
              to="/channels-monitoring"
              className="text-base lg:text-base px-3.5 py-2.5 rounded-lg flex items-center hover:bg-white/50"
            >
              <FontAwesomeIcon icon={duotone("monitor-waveform")} className="md:hidden lg:inline-block h-5"/>
              <span className="ml-2 sm:ml-0 lg:ml-2">Monitoring</span>
            </ActiveLink>
          </div>
          <div className="flex flex-col pt-4 mt-0 md:m-0 md:p-0 md:justify-between md:space-x-4 md:flex-row md:items-center md:py-4">
            <div id="offers">
              <ActiveLink
                to="/settings/offers"
                className="relative hidden md:flex justify-center align-middle items-center w-10 h-10 rounded-full hover:text-primary hover:bg-white hover:shadow-sm cursor-pointer"
              >
                <Tippy content="Offres exclusives partenaires">
                  <FontAwesomeIcon icon={duotone("gift-card")} className="h-6"/>
                </Tippy>
              </ActiveLink>
            </div>
            <div id="news">
              <Tippy content="Quoi de neuf sur RightsNow! ?">
                <a
                  href="https://rightsnow.notion.site/ed80a5f8fd1e4aabba817a7deb540fb4?v=2ff216346f2544f9af3b783f5584bfe9&pvs=4"
                  target="_blank"
                  className="relative hidden md:flex justify-center align-middle items-center w-10 h-10 rounded-full hover:text-primary hover:bg-white hover:shadow-sm cursor-pointer"
                  rel="noreferrer"
                >
                  <span className="absolute inline-block w-2 h-2 top-0.5 right-0.5 bg-red-600 rounded-full"></span>
                  <FontAwesomeIcon icon={duotone("bell-on")} className="h-6"/>
                </a>
              </Tippy>
            </div>
            <div className="account">
              <div className="relative hidden md:m-0 md:p-0 md:flex">
                <FontAwesomeIcon icon={duotone("circle-user")} className="text-primary h-7"/>
                {unreadNotif > 0 && (
                  <div className="bg-red absolute text-white text-xs rounded-3xl w-4 h-4 text-center -top-2 right-0">
                    {unreadNotif}
                  </div>
                )}
              </div>
              <div className="mt-0 md:ml-2 pt-4 md:pt-0 flex justify-between items-baseline md:inline-block md:text-sm font-bold whitespace-nowrap border-t border-gray-300 md:border-0">
                <div
                  className="text-gray-700 max-w-full md:max-w-[180px] overflow-hidden overflow-ellipsis"
                  title={userName}
                >
                  <FontAwesomeIcon icon={duotone("circle-user")} className="text-primary !h-5 inline-flex align-sub mr-1 md:hidden"/>
                  {userName}
                </div>
                {/*<div className="text-green items-center ml-2 sm:ml-0">*/}
                {/*  {perceivedRightsAmount} €*/}
                {/*</div>*/}
              </div>
              <div className="dropdown w-full mt-4">
                {/* <Link to="/notifications" className="px-6 py-4 flex items-center">

                <IconNotification /> <span className="ml-5">
                  Notifications
                </span>
              </Link> */}
                {/*<Link to="/payouts" className={`md:px-6 py-4 flex items-center ${isSubscribed ? '' : 'disabled'}`}>*/}
                {/*    <IconPayment/> <span className="ml-5">Versements</span>*/}
                {/*</Link>*/}
                <Link
                  to="/settings/account"
                  className="px-3.5 py-2.5 md:px-6 md:py-4 flex items-center rounded-lg md:rounded-none"
                >
                  <FontAwesomeIcon icon={duotone("gear")} className="h-6"/>
                  <span className="ml-5">Paramètres</span>
                </Link>
                <a
                  className="px-3.5 py-2.5 md:px-6 md:py-4 flex items-center rounded-lg md:rounded-none text-red hover:text-red-800 hover:bg-red-50 border-t border-gray-200 cursor-pointer"
                  onClick={handleSignout}
                >
                  <FontAwesomeIcon icon={duotone("right-from-bracket")} className="h-6"/>
                  <span className="ml-5">Déconnexion</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="relative max-w-screen-xl px-4 mx-auto md:mt-2 md:px-6 lg:px-8">*/}
      {/*  <div className="p-2 bg-yellow-200 text-xs shadow rounded text-center">*/}
      {/*    Vous souhaitez monitorer plus de 100 titres, votre compte est en cours de vérification. <a href="" target="_blank">En savoir plus</a>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {subscription?.status === 'trialing' && registerDate.getTime() < new Date('2023-12-22').getTime() && (
        <div className="relative max-w-screen-xl px-4 mx-auto md:mt-2 md:px-6 lg:px-8">
          <div className="p-2 bg-yellow-200 text-xs shadow rounded text-center">
            Voici la <a href="https://rightsnow.notion.site/ed80a5f8fd1e4aabba817a7deb540fb4?v=2ff216346f2544f9af3b783f5584bfe9&p=54a60592495f4eaf92f8f00d98172e53&pm=s" target="_blank" rel="noreferrer" className="underline">nouvelle version</a> de RightsNow!. En tant qu'ancien membre, <strong>vous profitez d'un mois gratuit</strong>. <Link to="/settings/subscription" className="underline">Mettez à jour votre abonnement</Link> si vous souhaitez continuer à utiliser RightsNow!
          </div>
        </div>
      )}
      {subscription?.status === 'trialing' && registerDate.getTime() >= new Date('2023-12-22').getTime() && !subscription?.cancelAtPeriodEnd && (
        <div className="relative max-w-screen-xl px-4 mx-auto md:mt-2 md:px-6 lg:px-8">
          <div className="p-2 bg-yellow-200 text-xs shadow rounded text-center">
            Votre période d'essai gratuite se terminera le {moment(subscription?.trialEndTs || 0).format('DD/MM/YYYY')}. Si vous ne souhaitez plus avoir accès à RightsNow!, mettez fin à votre abonnement.
          </div>
        </div>
      )}
      {subscription?.cancelAtPeriodEnd && (
        <div className="relative max-w-screen-xl px-4 mx-auto md:mt-2 md:px-6 lg:px-8">
          <div className="p-2 bg-yellow-200 text-xs shadow rounded text-center">
            Vous n'aurez plus accès aux services de RightsNow! à partir du{' '}
            {moment(subscription?.cancelAtTs || 0).format('DD/MM/YYYY')}.&nbsp;
            <Link to="/settings/subscription" className="underline">
              Plus de détails
            </Link>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
