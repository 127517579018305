import React from 'react';
import {
  CartesianGrid,
  Legend,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
  Bar, Label,
} from 'recharts';
import moment from 'moment';
import lodash from 'lodash';
import 'moment/locale/fr';
import {Broadcast} from 'shared-types';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';

interface RechartsProps {
  data: Broadcast[];
  from?: number;
  to?: number;
}

interface StackedBroadcast {
  date: moment.Moment;
  tv: number;
  radio: number;
  stream: number;
}

const CustomTooltip = ({active, payload, label,}: TooltipProps<ValueType, NameType>) => {
  if (active) {
    return (
      <div className="bg-gray-50 shadow-xl rounded-md text-xs py-2 px-3 border border-white">
        <span className="label">{label} : <strong>{(payload?.[0].value)?.toLocaleString('fr-FR')}</strong></span>
      </div>
    );
  }
  return null;
};

// const CustomTooltip = ({active, payload, label,}: TooltipProps<ValueType, NameType>) => {
//   if (active) {
//     return (
//       <div className="custom-tooltip">
//         <p className="label">{`${label}`}</p>
//         <p className="label">{`Radios : ${payload?.[1].value}`}</p>
//         <p className="label">{`TV : ${payload?.[2].value}`}</p>
//         <p className="label">{`Spotify : ${payload?.[2].value}`}</p>
//       </div>
//     );
//   }
//   return null;
// };

function applyRandomPercent(number: number) {
  // Generate a random percentage between 0 and 100
  const randomPercent = Math.random() * 100;

  // Decide randomly whether to increase or decrease the number by the random percentage
  let newNumber;
  if (Math.random() > 0.5) {
    newNumber = number * (1 + randomPercent / 100);
  } else {
    newNumber = number * (1 - randomPercent / 100);
  }
  return Math.round(newNumber);
}

export default function Recharts({data, from, to}: RechartsProps) {
  // from [{date, media, count}]
  // to [{date, tv, radio, spotify, youtube, ...}]
  // to [{date, tv, radio, stream}]
  // console.log(props.data)
  // const endDate =

  const groupByDate = lodash.groupBy(data, (d) => d.date);

  const stackedData: StackedBroadcast[] = Object.keys(groupByDate)
    .map((d) => {
      return {
        date: d,
        ...groupByDate[d].reduce(
          (acc, curr) => ({
            ...acc,
            date: curr.date,
            [curr.media]: curr.count,
          }),
          {},
        ),
      };
    })
    .map((data) => {
      const {date, tv, radio, ...streams} = data as any;
      return {
        date,
        tv,
        radio,
        stream: lodash.sumBy(Object.keys(streams).map((s) => streams[s])),
      };
    });

  const dataToDisplay: StackedBroadcast[] = [];
  if (data.length > 0) {
    const minDate = (from && moment.unix(from)) || lodash.minBy(data, (d) => d.date)!.date;
    const maxDate = (to && moment.unix(to)) || lodash.maxBy(data, (d) => d.date)!.date;
    let currentDate = moment(minDate);
    while (currentDate.isSameOrBefore(maxDate, 'day')) {
      const existing = stackedData.find((d) => d.date.isSame(currentDate, 'day'));
      if (existing) {
        dataToDisplay.push(existing);
      } else {
        dataToDisplay.push({
          date: moment(currentDate),
          tv: 0,
          radio: 0,
          stream: 0,
        });
      }
      currentDate = moment(currentDate).add(1, 'day');
    }
  }

  const hasStream = dataToDisplay.filter((d) => d.stream > 0).length > 0;
  const hasRadio = dataToDisplay.filter((d) => d.radio > 0).length > 0;
  const hasTV = dataToDisplay.filter((d) => d.tv > 0).length > 0;

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          margin={{top: 10, left: -20, right: 0, bottom: 0}}
          data={dataToDisplay.map((d) => ({
            ...d,
            date: d.date.format('DD/MM/YY'),
            // // tickDate: moment(d.date).locale("fr").format("MMMM"),
            // TV: d.count,
            // Radios: applyRandomPercent(Number(d.count)),
            // Streams: applyRandomPercent(Number(d.count)),
          }))}
        >
          <defs>
            <linearGradient id="colorStream" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#77D012" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#77D012" stopOpacity={0.3}/>
            </linearGradient>
            <linearGradient id="colorRadio" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#E9680D" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#E9680D" stopOpacity={0.3}/>
            </linearGradient>
            <linearGradient id="colorTv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#4C0CEB" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#4C0CEB" stopOpacity={0.3}/>
            </linearGradient>
          </defs>
          {hasStream && <CartesianGrid strokeDasharray="1 5" className="stroke-stream-500" vertical={false}/>}
          {hasRadio && <CartesianGrid strokeDasharray="1 5" className="stroke-radio-500" vertical={false}/>}
          {hasTV && <CartesianGrid strokeDasharray="1 5" className="stroke-tv-500" vertical={false}/>}
          <XAxis
            dataKey="date"
            dy={10}
            height={50}
            minTickGap={100}
            interval={'preserveStartEnd'}
            axisLine={false}
            style={{
              fontSize: '.7rem',
              fontFamily: 'Inter',
            }}
          />
          <YAxis
            minTickGap={100}
            tickFormatter={(value) =>
              new Intl.NumberFormat("fr-FR", {
                notation: "compact",
                compactDisplay: "short",
              }).format(value)
            }
            // mirror={true}
            axisLine={false}
            style={{
              fontSize: '.7rem',
              fontFamily: 'Inter',
            }}
          />
          <Tooltip
            // wrapperStyle={{ width: 100, backgroundColor: '#eee' }}
            cursor={<rect fill="#0000000D"/>}
            isAnimationActive={false}
            content={<CustomTooltip/>}
          />
          {/*<Legend*/}
          {/*  wrapperStyle={{*/}
          {/*    fontSize: '1rem',*/}
          {/*    fontFamily: 'Inter',*/}
          {/*  }}*/}
          {/*  display={'none'}*/}
          {/*  iconType={'circle'}*/}
          {/*/>*/}
          {hasStream && <Bar animationDuration={150} dataKey="stream" stackId="a" fill="url(#colorStream)" radius={[4, 4, 0, 0]}/>}
          {hasRadio && <Bar animationDuration={150} dataKey="radio" stackId="a" fill="url(#colorRadio)" radius={[4, 4, 0, 0]}/>}
          {hasTV && <Bar animationDuration={150} dataKey="tv" stackId="a" fill="url(#colorTv)" radius={[4, 4, 0, 0]}/>}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
