import React, { createContext, useContext, useEffect } from 'react';
import api from 'services/api';
import { Navigate, useLocation } from 'react-router-dom';

export interface AuthContext {
  isAuthenticated: () => boolean;
  signin: (email: string, password: string) => Promise<any>;
  signout: () => any;
}

const auth: AuthContext = {
  isAuthenticated: () => {
    const token = localStorage.getItem('user_token');
    const tokenExpiryDate = localStorage.getItem('user_token_expiry_date');
    const authenticated = !!(token && tokenExpiryDate && new Date(Number(tokenExpiryDate)) > new Date());
    return authenticated;
  },
  signin: async (email: string, password: string) => {
    const { access_token, access_token_expiry_date } = await api.login(email, password);
    localStorage.setItem('user_token', access_token);
    localStorage.setItem('user_token_expiry_date', access_token_expiry_date.toString());
    return access_token;
  },
  signout: () => {
    localStorage.removeItem('user_token');
    localStorage.removeItem('user_token_expiry_date');
  },
};

const authContext = createContext<AuthContext>(auth);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  useEffect(() => {
    fbq('track', 'PageView');
  }, [location]);
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  if (!auth.isAuthenticated()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth(): AuthContext {
  // const [user, setUser] = useState<string | null>(
  //   window.localStorage.getItem('token'),
  // );

  const { isAuthenticated, signin, signout } = auth;

  return {
    isAuthenticated,
    signin,
    signout,
  };
}
