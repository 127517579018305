import { formatRightsAmount } from 'utils/broadcastHelper';
import React from 'react';

export default function RightAmountWithMargin({
  amount,
  compact,
  weightPercentage,
}: {
  amount: number;
  compact: boolean;
  weightPercentage?: number;
}) {
  if (amount > 0 && weightPercentage) {
    return (
      <>
        {formatRightsAmount(amount, compact, weightPercentage)}
        &nbsp;<span className="text-gray-400">&mdash;</span>&nbsp;
        {formatRightsAmount(amount, compact)}
      </>
    );
  } else {
    return <>{formatRightsAmount(amount, compact)}</>;
  }
}
