import React from 'react';

const Notification = () => {
  return (
    <>
      <div className="bg-white shadow-xl p-6 rounded">
        <div className="flex justify-between items-center mb-12">
          <h4 className="mb-0">
            Notifications <span className="counter">3</span>
          </h4>
          <a
            className="button text-xs"
            href="/settings/notifications"
          >
            Gérer les notifications
          </a>
        </div>
        <div className="segment compact">
          <div className="hint margin right">
            <span className="date">14/02/2019</span>
            <span className="badge small green">Validation</span>
          </div>
          <p>
            Le titre <strong>I Will Survive - Gloria Gaynor</strong> a été
            validé.
          </p>
        </div>
        <div className="segment compact">
          <div className="hint margin right">
            <span className="date">14/02/2019</span>
            <span className="badge small red">Problème</span>
          </div>
          <p>
            Votre demande de versement n'a pu aboutir. Il semblerait qu'il y ait
            un problème avec votre IBAN. Merci de vérifier cette information.
          </p>
        </div>
        <div className="segment compact">
          <div className="hint margin right">
            <span className="date">14/02/2019</span>
            <span className="badge small green">Validation</span>
          </div>
          <p>
            Le titre <strong>I Will Survive - Gloria Gaynor</strong> a été
            validé.
          </p>
        </div>
        <div className="segment compact">
          <div className="hint margin right">
            <span className="date">14/02/2019</span>
            <span className="badge small red">Problème</span>
          </div>
          <p>
            Votre demande de versement n'a pu aboutir. Il semblerait qu'il y ait
            un problème avec votre IBAN. Merci de vérifier cette information.
          </p>
        </div>
      </div>
    </>
  );
};

export default Notification;
