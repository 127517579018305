import React from 'react'

const TuneCoreLogo: React.FC = () => {
  return (
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 285 285">
      <g>
        <rect width="285" height="285" />
        <g>
          <path id="path2" fill="#FFFFFF" d="M170.88,152.59c-0.37,1.02-0.91,1.93-1.55,2.84c-0.91,1.15-1.79,2.19-2.94,2.94
			c-1.15,0.78-2.3,1.42-3.72,1.93c-1.42,0.51-2.84,0.64-4.36,0.64c-1.93,0-3.72-0.37-5.51-1.15c-1.66-0.78-3.21-1.79-4.5-3.21
			c-1.28-1.28-2.3-2.94-3.08-4.76c-0.78-1.79-1.15-3.72-1.15-5.78s0.37-3.99,1.15-5.78c0.78-1.79,1.79-3.34,3.08-4.76
			c1.28-1.28,2.84-2.43,4.5-3.21c1.66-0.78,3.59-1.15,5.51-1.15c1.66,0,3.08,0.27,4.5,0.64c1.42,0.51,2.7,1.15,3.72,1.93
			c1.15,0.91,2.06,1.79,2.84,2.94c0.64,0.91,1.15,1.79,1.55,2.84h3.05c-0.51-1.66-1.28-3.21-2.3-4.63
			c-1.02-1.42-2.19-2.57-3.48-3.59c-1.28-1.02-2.84-1.79-4.5-2.3c-1.66-0.51-3.48-0.91-5.4-0.91c-2.3,0-4.5,0.51-6.69,1.42
			c-2.06,0.91-3.85,2.19-5.4,3.85c-1.55,1.66-2.7,3.48-3.59,5.65c-0.91,2.19-1.28,4.5-1.28,6.93c0,2.43,0.37,4.76,1.28,6.93
			c0.91,2.19,2.06,4.12,3.59,5.65c1.55,1.66,3.34,2.94,5.4,3.85c2.06,0.91,4.23,1.42,6.69,1.42c1.79,0,3.59-0.27,5.27-0.78
			c1.66-0.51,3.21-1.28,4.5-2.3c1.42-1.02,2.57-2.19,3.59-3.59c1.02-1.42,1.79-2.94,2.43-4.76L170.88,152.59z" />
          <path id="path4" fill="#FFFFFF" d="M208.9,138.97c-0.91-2.19-2.06-4.12-3.59-5.78c-1.55-1.66-3.34-2.84-5.51-3.85
			c-2.06-0.91-4.36-1.42-6.8-1.42c-2.3,0-4.63,0.51-6.69,1.42s-3.99,2.19-5.51,3.85c-1.55,1.66-2.84,3.59-3.72,5.78
			c-0.91,2.19-1.28,4.5-1.28,7.06c0,2.57,0.37,4.87,1.28,7.06c0.91,2.19,2.19,4.12,3.72,5.78c1.55,1.66,3.34,2.94,5.51,3.85
			c2.06,0.91,4.36,1.42,6.69,1.42s4.63-0.51,6.69-1.42c2.06-0.91,3.99-2.19,5.51-3.85c1.55-1.66,2.84-3.59,3.72-5.78
			c0.88-2.19,1.42-4.63,1.42-7.06C210.18,143.6,209.67,141.16,208.9,138.97L208.9,138.97z M206.06,151.95
			c-0.78,1.79-1.79,3.48-3.08,4.76c-1.28,1.42-2.84,2.43-4.5,3.21c-1.79,0.78-3.59,1.15-5.51,1.15c-1.93,0-3.85-0.37-5.51-1.15
			c-1.79-0.78-3.21-1.79-4.5-3.21c-1.28-1.28-2.3-2.94-3.08-4.76s-1.15-3.72-1.15-5.91c0-2.06,0.37-3.99,1.15-5.91
			c0.78-1.79,1.79-3.34,3.08-4.76c1.28-1.42,2.84-2.43,4.5-3.21s3.59-1.15,5.51-1.15s3.85,0.37,5.51,1.15
			c1.79,0.78,3.21,1.79,4.5,3.08c1.28,1.28,2.3,2.94,2.94,4.76c0.78,1.79,1.15,3.72,1.15,5.91
			C207.24,148.23,206.84,150.15,206.06,151.95z" />
          <path id="path6" fill="#FFFFFF" d="M238.71,162.73h-3.21v-8.48c0-1.66-0.51-2.94-1.28-3.85c-0.91-0.91-2.3-1.28-4.36-1.28H216.5
			v13.75h-3.21v-34.68h13.22c1.79,0,5.51,0.27,6.93,0.78c1.42,0.51,2.57,1.28,3.59,2.19c1.02,0.91,1.66,2.06,2.19,3.34
			c0.51,1.28,0.64,2.84,0.64,4.36c0,1.93-0.27,3.34-1.15,4.76c-0.88,1.42-2.06,2.57-3.48,3.48c2.43,1.42,3.59,3.85,3.59,7.33
			L238.71,162.73z M228.3,146.03c2.06,0,4.12-0.64,5.4-1.55c1.66-1.15,2.84-2.43,2.84-5.91c0-3.59-1.28-5-2.94-6.05
			c-1.15-0.91-3.48-1.42-5.65-1.42h-11.56v14.93H228.3z" />
          <path id="path8" fill="#FFFFFF" d="M268.11,128.05v3.08h-21.57v12.58h19.53v3.08h-19.53v12.98h21.57v3.08h-24.78v-34.65h24.78
			L268.11,128.05z" />
          <path id="path12" fill="#FFFFFF" d="M32.3,156.95c-0.91-0.13-1.66-0.51-2.43-1.02c-0.64-0.51-1.28-1.28-1.66-2.19
			c-0.37-0.91-0.64-2.19-0.64-3.59V134.1h7.71v-5.91h-7.73v-7.33h-6.02v7.33h-4.63v5.91h4.63v16.05c0,2.19,0.37,4.12,1.02,5.78
			s1.66,3.08,2.84,4.23c1.15,1.15,2.7,1.93,4.36,2.3c1.15,0.27,2.3,0.37,3.59,0.37c0.64,0,1.28,0,1.93-0.13h0.27v-5.65h-0.27
			C34.23,157.22,33.21,157.08,32.3,156.95z M61.33,149.38c0,1.28-0.27,2.43-0.64,3.48c-0.37,1.02-1.02,1.93-1.79,2.7
			c-0.78,0.78-1.66,1.42-2.7,1.79c-1.02,0.37-2.19,0.64-3.34,0.64c-2.7,0-4.63-0.78-6.05-2.3c-1.42-1.55-2.06-3.72-2.06-6.42v-21.06
			h-6.15v20.76c0,2.19,0.27,4.12,0.91,5.91c0.64,1.79,1.42,3.34,2.57,4.63c1.15,1.28,2.57,2.3,4.23,3.08
			c1.66,0.78,3.59,1.02,5.65,1.02c2.19,0,4.12-0.37,5.78-1.15c1.42-0.64,2.84-1.66,3.85-2.84v3.21h6.05v-34.65h-6.15v21.19H61.33z
			 M96.52,131.4c-1.15-1.28-2.57-2.3-4.23-3.08c-1.66-0.64-3.59-1.02-5.65-1.02s-4.12,0.37-5.78,1.28
			c-1.55,0.78-2.84,1.66-3.85,2.84v-3.21h-6.05v34.68h6.15V141.7c0-1.28,0.27-2.43,0.64-3.48c0.37-1.02,1.02-1.93,1.79-2.7
			c0.78-0.78,1.66-1.42,2.7-1.79c1.02-0.37,2.19-0.64,3.48-0.64c2.57,0,4.63,0.78,6.05,2.43c1.42,1.66,2.06,3.72,2.06,6.29v20.92
			H100v-20.82c0-2.19-0.27-4.12-0.91-5.91C98.58,134.34,97.67,132.68,96.52,131.4L96.52,131.4z M137.62,146.94
			c0-3.08-0.51-5.78-1.42-8.21s-2.3-4.5-3.85-6.15c-1.66-1.66-3.48-2.94-5.65-3.85c-2.06-0.91-4.23-1.28-6.56-1.28
			c-2.43,0-4.76,0.51-6.8,1.42c-2.06,0.91-3.99,2.3-5.51,3.85c-1.55,1.66-2.84,3.59-3.72,5.78c-0.91,2.19-1.42,4.63-1.42,7.06
			c0,2.57,0.51,4.87,1.42,7.06c0.91,2.19,2.19,4.12,3.72,5.65c1.55,1.66,3.48,2.94,5.65,3.85c2.19,0.91,4.5,1.42,6.93,1.42
			c3.99,0,7.44-1.02,10.27-2.94c2.84-1.93,4.87-4.87,6.05-8.62l0.13-0.37h-6.56l-0.13,0.13c-0.91,1.93-2.19,3.48-3.85,4.5
			c-1.66,1.02-3.72,1.66-6.05,1.66c-1.42,0-2.7-0.27-3.99-0.78c-1.28-0.51-2.43-1.15-3.48-2.19c-1.02-0.91-1.93-2.06-2.7-3.48
			c-0.64-1.28-1.15-2.7-1.28-4.36h28.76v-0.13H137.62z M109.36,141.67c0.37-1.28,0.91-2.43,1.55-3.48c0.78-1.15,1.66-2.06,2.57-2.84
			c1.02-0.78,2.06-1.42,3.21-1.79c2.3-0.78,4.76-0.78,6.93,0c1.15,0.37,2.19,1.02,3.08,1.79c0.91,0.78,1.79,1.79,2.43,2.84
			c0.64,1.02,1.15,2.19,1.55,3.34h-21.33L109.36,141.67z" />
        </g>
      </g>
    </svg>

  )
}

export default TuneCoreLogo
