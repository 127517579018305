import React, { MouseEvent, ReactElement, useState } from 'react';
import RightsNowLogo from '../../components/svg/RightsNowLogo';
import CreditCardForm, { StripeData } from '../../components/CreditCardForm';
import { StripeError } from '@stripe/stripe-js';
import { ApiError } from '../../utils/errors';
import LoaderVertical from '../../components/LoaderVertical';
import { brands, duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import api from 'services/api';
import Loader from 'components/Loader';
import PageTitle from '../layout/PageTitle';
import HyconiqLogo from 'components/svg/HyconiqLogo';
import { SubscriptionPriceDTO } from 'shared-types';
import SpotifyLogoSymbol from '../../components/svg/SpotifyLogoSymbol';
import YoutubeLogoSymbol from '../../components/svg/YoutubeLogoSymbol';
import SoundCloudLogoSymbol from '../../components/svg/SoundCloudLogoSymbol';

interface PaymentProps {
  onPrevious: () => void;
  onSubscribe: (stripe: StripeData, priceId: string, priceInCent: number) => void;
  isProcessing?: boolean;
  error?: StripeError | ApiError | unknown;
  engagement: number;
  setEngagement: (e: number) => void;
  formula: number;
  setFormula: (f: number) => void;
  source?: string;
  prices: SubscriptionPriceDTO[];
}

function formatPriceInEuro(priceInCent: number) {
  return (priceInCent / 100).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 });
}

function formatOriginalPriceInEuro(originalPriceInCent: number) {
  return (originalPriceInCent / 100).toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });
}

function formatPriceInCent(priceInCent: number): string {
  return (priceInCent / 100).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 });
}

function Payment({
  onPrevious,
  onSubscribe,
  isProcessing,
  error,
  engagement,
  setEngagement,
  formula,
  setFormula,
  source,
  prices,
}: PaymentProps): ReactElement {
  const handleOnPrevious = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onPrevious();
  };

  const getPriceInCent = (engagement: number, formula: number): number => {
    const price = (prices || []).find((p) => p.engagement === engagement && Number(p.formula) === formula);
    return price?.priceInCent || 0;
  };

  const getOriginalPriceInCent = (engagement: number, formula: number): number => {
    const price = (prices || []).find((p) => p.engagement === engagement && Number(p.formula) === formula);
    return price?.originalPriceInCent || 0;
  };

  const getSelectedPrice = (engagement: number, formula: number) => {
    return (prices || []).find((price) => {
      return price.engagement === engagement && Number(price.formula) === formula;
    });
  };

  const displayPrice = (engagement: number, formula: number): string => {
    const price = getPriceInCent(engagement, formula);
    return formatPriceInCent(price);
  };

  const displayOriginalPrice = (engagement: number, formula: number): string => {
    const price = getOriginalPriceInCent(engagement, formula);
    return formatPriceInCent(price);
  };

  const displayPriceInEuro = (engagement: number, formula: number): string => {
    const price = getPriceInCent(engagement, formula);
    return formatPriceInEuro(price);
  };

  const handleOnSubmit = (data: StripeData) => {
    const selectedPrice = getSelectedPrice(engagement, formula);
    const priceId = selectedPrice?.stripeId || 'undefined';
    const priceInCent = selectedPrice?.priceInCent || 0;
    onSubscribe(data, priceId, priceInCent);
  };

  const engagementSavings = (
    <>
      {engagement === 1 && (
        <div className="bg-gray-200 px-2 py-0.5 mb-4 text-gray-900 rounded-md text-xs inline-flex items-center">
          <strong>Économisez 0%</strong>
        </div>
      )}
      {engagement === 6 && (
        <div className="bg-orange-200 px-2 py-0.5 mb-4 text-orange-900 rounded-md text-xs inline-flex items-center">
          6 mois : économisez&nbsp;<strong>15%</strong>
        </div>
      )}
      {engagement === 12 && (
        <div className="bg-orange-200 px-2 py-0.5 mb-4 text-orange-900 rounded-md text-xs inline-flex items-center">
          1 an : économisez&nbsp;<strong>15%</strong>
        </div>
      )}
    </>
  );

  return (
    <>
      <PageTitle title="Inscription - Paiement" />
      <div className="flex justify-center mx-auto lg:px-6 lg:my-6 xl:my-12 w-full xl:max-w-screen-xl">
        <div className="w-full bg-background pb-10 md:rounded-lg">
          <div className="md:flex w-auto md:h-[350px] photo subscribe bg-right-top items-left flex-col p-4 md:p-12 text-white text-shadow shadow md:rounded-lg">
            <div className="w-32 md:w-64 mb-6 md:mb-12">
              <a href="https://rightsnow.fr/" target="_blank" rel="noreferrer">
                <RightsNowLogo className="fill-white" />
              </a>
            </div>
            <div className="pr-20 md:pr-56 text-sm md:text-2xl select-none">
              <span className="md:hidden">Grâce à RightsNow!, je redresse les montants de mes versements SACEM.</span>{' '}
              <span className="hidden md:inline-block">
                Depuis que je me suis inscris à RightsNow!, je peux facilement redresser les montants de mes versements
                SACEM.
              </span>
            </div>
          </div>

          <div className="bg-white mx-auto mt-16 max-w-2xl shadow rounded ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="px-6 py-4 sm:px-10 sm:py-8 lg:flex-auto">
              <div className="flex items-center gap-x-4">
                <h3 className="font-body mb-0 flex-none text-sm leading-6 text-primary">Fonctionnalités</h3>
                <div className="h-px flex-auto bg-primary-200"></div>
              </div>
              <ul
                role="list"
                className="mt-4 ml-0 grid grid-cols-1 gap-3 text-sm leading-6 text-gray-600 sm:grid-cols-2"
              >
                <li className="flex gap-x-3">
                  <div>
                    <FontAwesomeIcon
                      icon={duotone('history')}
                      className="inline-flex w-4 h-4 align-middle mr-1 mb-0.5 text-black"
                    />{' '}
                    Historique illimité
                  </div>
                </li>
                <li className="flex gap-x-3">
                  <div>
                    <SpotifyLogoSymbol className="inline-flex w-4 h-4 align-middle mr-1 mb-0.5 text-black" />{' '}
                    Statistiques Spotify
                  </div>
                </li>
                <li className="flex gap-x-3">
                  <div>
                    <YoutubeLogoSymbol className="inline-flex w-4 h-4 align-middle mr-1 mb-0.5 text-black" />{' '}
                    Statistiques YouTube
                  </div>
                </li>
                <li className="flex gap-x-3">
                  <div>
                    <SoundCloudLogoSymbol className="inline-flex w-4 h-4 align-middle mr-1 mb-0.5 text-black" />{' '}
                    Statistiques SoundCloud
                  </div>
                </li>
                <li className="flex gap-x-3">
                  <div>
                    <FontAwesomeIcon
                      icon={duotone('radio-alt')}
                      className="inline-flex w-4 h-4 align-middle mr-1 mb-0.5 text-black"
                    />{' '}
                    Statistique{' '}
                    <a href="/monitoring/" target="_blank">
                      Radios
                    </a>
                  </div>
                </li>
                <li className="flex gap-x-3">
                  <div>
                    <FontAwesomeIcon
                      icon={duotone('tv')}
                      className="inline-flex w-4 h-4 align-middle mr-1 mb-0.5 text-black"
                    />
                    Chaînes{' '}
                    <a href="/monitoring/" target="_blank">
                      TV FR
                    </a>
                  </div>
                </li>
                <li className="flex gap-x-3">
                  <div>
                    <FontAwesomeIcon
                      icon={duotone('display-chart-up-circle-dollar')}
                      className="inline-flex w-4 h-4 align-middle mr-1 mb-0.5 text-black"
                    />{' '}
                    Estimation de vos royautés
                  </div>
                </li>
                <li className="flex gap-x-3">
                  <div>
                    <FontAwesomeIcon
                      icon={duotone('hand-holding-dollar')}
                      className="inline-flex w-4 h-4 align-middle mr-1 mb-0.5 text-black"
                    />{' '}
                    Avances Sacem
                    <span className="align-middle bg-green text-white rounded-md ml-1.5 px-1.5 py-0.5 text-[10px]">
                      SOON
                    </span>
                  </div>
                </li>
              </ul>
              <div className="flex items-center gap-x-4 mt-10">
                <h3 className="font-body mb-0 flex-none text-sm leading-6 text-primary">Réduction sur engagement</h3>
                <div className="h-px flex-auto bg-primary-200"></div>
              </div>
              <div className="mt-4">
                <div className="flex w-full border-2 p-2 text-xs md:text-base border-gray-700 rounded bg-gray-700 text-white">
                  <button
                    className={`transition rounded-md w-full block py-1 ${
                      engagement !== 6 && engagement !== 12 ? 'bg-gray-500' : 'hover:text-gray-400'
                    }`}
                    onClick={() => {
                      setEngagement(1);
                    }}
                    type="button"
                  >
                    Mensuel
                  </button>
                  <button
                    className={`transition rounded-md w-full block py-1 relative ${
                      engagement !== 1 && engagement !== 6 ? 'bg-gray-500' : 'hover:text-gray-400'
                    }`}
                    onClick={() => {
                      setEngagement(12);
                    }}
                    type="button"
                  >
                    Annuel{' '}
                    <span className="bg-green-200 text-green-900 rounded-md px-2 py-0.5 text-xs lg:text-sm ml-2">
                      2 mois offerts
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-md bg-primary-50 py-10 text-center lg:flex lg:flex-col lg:justify-center h-full">
                <div className="mx-auto max-w-xs px-8">
                  <div className="inline-flex items-center text-center text-sm mb-3 justify-center rounded-full bg-green-700 text-white px-5 py-2 w-fit mx-auto">
                    <FontAwesomeIcon icon={duotone('calendar')} className="w-4 text-white mr-1" /> 7 jours d'essai
                    gratuits
                  </div>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2 pb-0">
                    {source !== 'hyconiq' && (
                      <div>
                        <span className="font-bold text-6xl text-primary">{displayPrice(engagement, 3)}</span>
                        <sup className="font-bold text-sm -top-8">
                          /{engagement === 1 ? <span>mois</span> : <span>an</span>}
                        </sup>

                        <div className="text-opacity-50 text-xs font-normal mt-3">
                          {engagement === 1 && <span>Facturé tous les mois</span>}
                          {engagement !== 1 && <span>Facturé chaque année</span>}
                        </div>
                      </div>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-yellow-100 shadow px-6 py-4 mt-12 mb-12 rounded">
            Montant à régler <strong>après les 7 jours d'essai gratuit</strong> :{' '}
            <strong>{formatPriceInEuro(getPriceInCent(engagement, formula))}</strong> pour {engagement} mois.
          </div>
          <div className="p-10 bg-blue-50 relative rounded-t">
            <CreditCardForm
              onSubmit={handleOnSubmit}
              isSubmitting={isProcessing}
              error={error}
              withCgvAndCguValidation={false}
            />
            <div
              className={`bg-blue-50/80 flex justify-center items-center absolute w-full h-full top-0 left-0 ${
                isProcessing ? 'loading' : 'hidden'
              }`}
            >
              <LoaderVertical />
            </div>
          </div>

          <div className="p-10 bg-blue-100">
            <p>
              <FontAwesomeIcon icon={duotone('info-circle')} className="h-6 align-top text-blue-600 inline-flex mr-1" />
              Vous pouvez modifier ou résilier votre abonnement facilement et <strong>à tout moment</strong> depuis
              votre espace personnel. Votre abonnement RightsNow! n'est valide que pour les oeuvres sur lesquelles vous
              êtes auteur et/ou compositeur.
            </p>
          </div>

          <div className="p-10 bg-white rounded-b md:flex justify-between items-center">
            <button
              className={`button outline text-primary ${isProcessing ? 'loading' : ''}`}
              onClick={handleOnPrevious}
              disabled={isProcessing}
            >
              <FontAwesomeIcon icon={duotone('arrow-left')} className="h-5" />
            </button>
            <div className="mt-6 md:mt-0 text-sm tracking-wide leading-normal">
              Un problème, une question ?{' '}
              <a href="https://rightsnow.fr/support/" target="_blank">
                Contactez-nous
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
