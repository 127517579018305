import PageTitle from 'features/layout/PageTitle';
import React, { useRef, useState } from 'react';
import RightNowLogo from 'components/svg/RightsNowLogo';
import { useAuth } from '../../hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import IconDelete from '../../components/icons/IconDelete';
import { Dialog } from '@headlessui/react';
import GenericError from '../../components/GenericError';
import cx from 'classnames';
import Modal from '../../components/Modal';
import api from '../../services/api';
import IconSubscription from '../../components/icons/IconSubscription';
import useSubscriptionStatus from '../../hooks/use-subscription-status';
import IconLoading from '../../components/icons/IconLoading';

export default function CancelPage() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const cancelDeleteButtonRef = useRef(null);
  const [processing, setProcessing] = useState(false);
  const { subscriptionStatus, isLoading, isError } = useSubscriptionStatus();
  const deleteMyAccount = async () => {
    setProcessing(true);
    try {
      await api.deleteUser();
      setProcessing(false);
      signout();
      navigate('/login');
    } catch (e) {
      setError(!!e);
    }
  };
  const { signout } = useAuth();
  const handleSignout = () => {
    signout();
    navigate('/login');
  };

  if (isLoading) {
    return (
      <div className="h-56 text-sm flex items-center justify-center leading-none">
        <IconLoading className="w-5 h-5 text-primary mr-2" /> Chargement...
      </div>
    );
  }

  if (isError) {
    return <GenericError />;
  }

  if (subscriptionStatus !== undefined && subscriptionStatus.status !== 'canceled') {
    navigate('/');
  }
  return (
    <>
      <PageTitle title="Abonnement suspendu" />
      <div className="min-h-full pt-4 md:pt-16 flex flex-col max-w-2xl mx-auto">
        <main className="flex-grow flex flex-col max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex mb-12">
            <span className="sr-only">RightsNow!</span> <RightNowLogo className="h-12 w-auto" />
          </div>
          <h1 className="text-red flex text-xl mb-6">
            <IconSubscription className="mr-2 w-6 flex-shrink-0 self-start mt-0.5" />
            <strong>Information importante concernant votre abonnement</strong>
          </h1>
          <p>
            <strong>Votre abonnement a été suspendu</strong>. Si vous pensez que cette suspension est une erreur,{' '}
            <a href="https://rightsnow.fr/support/" target="_blank" rel="noreferrer">
              {' '}
              contactez-nous
            </a>
            .
          </p>
          <p>
            Dans le cas où vous auriez vous-même suspendu votre abonnement, vous avez également la possibilité de{' '}
            <a className="text-red hover:text-red-700" onClick={() => setShowDeleteModal(true)}>
              supprimer votre compte
            </a>
            .
          </p>
          <button className="w-full button text-sm py-2 md:w-fit mt-6" onClick={handleSignout}>
            Se déconnecter
          </button>
        </main>
      </div>
      <Modal
        isOpen={showDeleteModal}
        setOpen={setShowDeleteModal}
        initialFocus={cancelDeleteButtonRef}
        closeClickOutsideOrEscape={true}
      >
        <>
          <div className="bg-white px-4 py-4 sm:p-6">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <IconDelete className="text-red" />
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-4 text-left">
                <Dialog.Title as="h3" className="text-xl leading-loose text-center sm:text-left text-red">
                  {' '}
                  Supprimer mon compte ?{' '}
                </Dialog.Title>
                <div className="mt-6">
                  {!error && (
                    <>
                      <p>
                        Suite à la suppression de votre compte,{' '}
                        <strong>vous ne pourrez plus avoir accès aux services de RightsNow!</strong>
                      </p>
                      <p className="text-red">Toutes vos données seront supprimées.</p>
                    </>
                  )}{' '}
                  {error && <GenericError />}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-100 px-6 py-4 flex flex-col sm:flex-row sm:justify-end">
            <button
              type="button"
              className={cx(
                'w-full inline-flex justify-center rounded border border-transparent px-4 py-2 mb-4 sm:mb-0 bg-red-100 text-red-700 hover:bg-red hover:text-white focus:ring-opacity-30 focus:outline-none focus:ring-4 focus:ring-red-500 sm:w-auto',
                { loading: processing },
              )}
              disabled={processing}
              onClick={() => deleteMyAccount()}
            >
              Supprimer mon compte
            </button>
            <button
              type="button"
              className="button w-full sm:mb-0 sm:ml-3 sm:w-auto"
              onClick={() => setShowDeleteModal(false)}
              ref={cancelDeleteButtonRef}
            >
              Ne pas supprimer mon compte
            </button>
          </div>
        </>
      </Modal>
    </>
  );
}
