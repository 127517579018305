import React from 'react';
import RightsNowLogo from '../../components/svg/RightsNowLogo';
import PageTitle from '../layout/PageTitle';

interface GreetingProps {
  email: string;
}

export default function Greeting({ email }: GreetingProps) {
  const emailProviders: { [key: string]: string } = {
    'gmail.com': 'https://accounts.google.com/',
    'yahoo.com': 'https://login.yahoo.com/',
    'yahoo.fr': 'https://login.yahoo.com/',
    'gmx.com': 'https://www.gmx.com/#.1559516-header-navlogin2-1',
    'protonmail.com': 'https://account.proton.me/mail',
    'icloud.com': 'https://www.icloud.com/mail',
    'outlook.com': 'https://login.live.com/',
    'outlook.fr': 'https://login.live.com/',
    'hotmail.com': 'https://login.live.com/',
    'hotmail.fr': 'https://login.live.com/',
  };

  const getEmailProviderUrl = (email: string): string | null => {
    const domain = email.split('@')[1];
    return emailProviders[domain] || null;
  };

  const emailProviderUrl = getEmailProviderUrl(email);

  return (
    <>
      <PageTitle title="Inscription - Bienvenue !" />
      <div className="flex justify-center lg:px-6 lg:my-6 xl:my-12 w-full xl:max-w-screen-lg">
        <div className="w-full h-auto hidden lg:flex lg:w-5/12 rounded-l-lg cursor-default select-none text-white text-xl uppercase leading-tight p-10 pt-16 font-display photo thanks" />
        <div className="w-full lg:w-7/12 bg-white p-10 sm:rounded-lg lg:rounded-l-none">
          <div className="w-64 mb-12">
            <a href="https://rightsnow.fr/">
              <RightsNowLogo />
            </a>
          </div>
          <h5 className="margin top">
            <strong>Bienvenue !</strong>
          </h5>
          <p>Tout d'abord, nous tenons à vous remercier pour votre confiance</p>
          <p>
            Pour valider votre inscription, rendez-vous dans votre boite email :{' '}
            <strong className="bg-blue-100 px-2">{email}</strong>
          </p>
          {emailProviderUrl && (
            <div className="mt-6">
              <a href={emailProviderUrl} className="button" target="_blank" rel="noopener noreferrer">
                Vérifier mes emails
              </a>
            </div>
          )}
          <p className="message bg-yellow-200 mt-6 p-4 bottom-0 table">
            <strong>Pas d'email reçu ? Vérifiez dans vos spams.</strong> Sinon,{' '}
            <a href="https://www.rightsnow.fr/support/">Contactez-nous</a>.
          </p>
        </div>
      </div>
    </>
  );
}
