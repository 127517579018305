import React, { useRef, useState } from 'react'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import IconDelete from 'components/icons/IconDelete'
import GenericError from 'components/GenericError'
import api from 'services/api'
import Modal from 'components/Modal'
import { Dialog } from '@headlessui/react'
import { useAuth } from 'hooks/use-auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

export default function DeleteMyAccount() {
  const [error, setError] = useState(false)
  const { signout } = useAuth()
  const navigate = useNavigate()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [processing, setProcessing] = useState(false)
  const cancelDeleteButtonRef = useRef(null)

  const [deleteInput, setDeleteInput] = useState('');
  const deleteMyAccount = async () => {
    setProcessing(true)
    try {
      await api.deleteUser()
      setProcessing(false)
      signout()
      navigate('/login')
    } catch (e) {
      setError(!!e)
    }
  }

  return (
    <>
      <details className="accordion bg-red-700 text-white rounded flex mt-6 overflow-hidden">
        <summary className="p-6 flex hover:bg-red-800 cursor-pointer">
          <FontAwesomeIcon icon={duotone('diamond-exclamation')} className="h-6 mr-2 flex-shrink-0" />
          <strong>Supprimer mon compte ?</strong>
        </summary>
        <div className="bg-red text-white p-6">
          <p>
            <strong>ATTENTION</strong> ! Cette action est irreversible.
          </p>
          <ul className="list-disc mb-6 ml-6">
            <li>vos données personnelles seront entièrement supprimées</li>
            <li>la totalité des historiques de vos titres seront supprimés</li>
            <li>les factures liées à votre abonnement seront inaccessibles, pensez à les télécharger</li>
          </ul>
          <button
            className="border border-white rounded py-2 px-4 text-sm hover:text-red hover:bg-white"
            onClick={() => setShowDeleteModal(true)}
          >
            <FontAwesomeIcon icon={duotone('trash-can')} className="inline-flex mr-2 h-4 align-sub" />
            Supprimer mon compte
          </button>
        </div>
      </details>

      <Modal
        isOpen={showDeleteModal}
        setOpen={setShowDeleteModal}
        initialFocus={cancelDeleteButtonRef}
        closeClickOutsideOrEscape={true}
      >
        <>
          <div className="bg-white px-4 py-4 sm:p-6">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <IconDelete className="text-red" />
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-4 text-left">
                <Dialog.Title as="h3" className="text-xl leading-loose text-center sm:text-left text-red"> Supprimer mon compte ? </Dialog.Title>
                <div className="mt-6">
                  {!error && (
                    <>
                      <p><strong>La suppression de votre compte entrainera</strong> :</p>
                      <ul className="list-disc mb-6 ml-6">
                        <li>la suppression <strong className="text-red">DEFINITIVE</strong> de vos données personnelles</li>
                        <li>la destruction <strong className="text-red">TOTALE</strong> de l'historique de diffusion de tous vos titres</li>
                        <li>l'impossibilité d'accéder aux factures liées à votre abonnement</li>
                      </ul>
                    </>
                  )} {error && <GenericError />}
                </div>
                <input
                  className="mt-2 p-2 border rounded"
                  type="text"
                  placeholder='Entrez "DELETE" en majuscule pour activer le bouton'
                  value={deleteInput}
                  onChange={(e) => setDeleteInput(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-100 px-6 py-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="button w-full mb-4 sm:mb-0 sm:ml-3 sm:w-auto"
              onClick={() => setShowDeleteModal(false)}
              ref={cancelDeleteButtonRef}
            >
              Ne pas supprimer mon compte
            </button>
            <button
              type="button"
              className={cx(
                'w-full inline-flex justify-center rounded border border-transparent px-4 py-2 bg-red-100 text-red-700 hover:bg-red hover:text-white focus:ring-opacity-30 focus:outline-none focus:ring-4 focus:ring-red-500 sm:ml-3 sm:w-auto',
                { loading: processing },
              )}
              disabled={processing || deleteInput !== 'DELETE'}
              onClick={() => deleteMyAccount()}
            >
              Supprimer mon compte
            </button>
          </div>
        </>
      </Modal>
    </>
  )
}
