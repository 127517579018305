import React from 'react';
import IconClose from "../../components/icons/IconClose";

const NotificationSettings = () => {
  return (
    <div>
      <div className="message bg-green-500 text-white mb-6 flex justify-between">
        <div className="content">Alertes mises à jour.</div>
        <div className="cursor-pointer self-baseline">
          <IconClose className="w-6"/>
        </div>
      </div>
      <div className="bg-white shadow-xl p-6 rounded">
        <p className="mb-4 font-bold italic">Envoyez-moi un email dès que :</p>
        <div className="toggle mb-6">
          <label
            htmlFor="id2"
            className="flex items-center cursor-pointer"
          >
            <div className="relative">
              <input id="id2" type="checkbox" className="sr-only"/>
              <div className="w-10 h-4 bg-gray-200 rounded-full shadow-inner"/>
              <div
                className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"/>
            </div>
            <div className="label ml-3 text-gray-700 font-medium">
              le statut d'un virement a été mis à jour
            </div>
          </label>
        </div>
        <div className="toggle">
          <label
            htmlFor="id3"
            className="flex items-center cursor-pointer"
          >
            <div className="relative">
              <input id="id3" type="checkbox" className="sr-only"/>
              <div className="w-10 h-4 bg-gray-200 rounded-full shadow-inner"/>
              <div
                className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"/>
            </div>
            <div className="label ml-3 text-gray-700 font-medium">
              le statut d'un titre a été mis à jour
            </div>
          </label>
        </div>
      </div>
    </div>
  )
    ;
};

export default NotificationSettings;
