import PageTitle from 'features/layout/PageTitle';
import NotificationSettings from './NotificationSettings';

const NotificationSettingsPage = () => {
  return (
    <>
      <PageTitle title="Gérer les notifications" />
      <NotificationSettings />
    </>
  );
};

export default NotificationSettingsPage;
