import cx from 'classnames';
import InputField from 'components/InputField';
import {Field, Form, Formik} from 'formik';
import {useAuth} from 'hooks/use-auth';
import React, {useState} from 'react';
import api from 'services/api';
import * as Yup from 'yup';
import IconArrow from '../../components/icons/IconArrow';
import {isApiError} from "utils/errors";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {duotone} from "@fortawesome/fontawesome-svg-core/import.macro";
import PageTitle from "../layout/PageTitle";

const LoginFormSchema = Yup.object()
  .shape({
    email: Yup.string().required(),
    password: Yup.string().required(),
  })
  .defined();

type LoginFormValues = Yup.InferType<typeof LoginFormSchema>;

function LoginForm() {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordEmailSent, setForgotPasswordEmailSent] = useState(false);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [isSendVerificationEmailDisabled, setIsSendVerificationEmailDisabled] =
    useState(false);
  const [email, setEmail] = useState<string | undefined>();
  const auth = useAuth();
  const navigate = useNavigate()

  const sendVerificationEmail = async () => {
    setIsSendVerificationEmailDisabled(true);
    setVerificationEmailSent(true);
    await api.sendEmailVerificationEmail(email!);
    setTimeout(() => {
      setIsSendVerificationEmailDisabled(false);
    }, 5000);
  };

  return (
    <>
      <PageTitle title="Identification"/>
      <Formik<LoginFormValues>
        initialValues={{email: '', password: ''}}
        onSubmit={async (values, formikHelpers) => {
          try {
            formikHelpers.setStatus(undefined);
            // formikHelpers
            if (!forgotPassword) {
              setEmail(values.email);
              await auth.signin(values.email, values.password);
              console.log('logged in');
              navigate('/dashboard', {replace: true});
            } else {
              await api.sendForgottenPasswordEmail(values.email);
              setForgotPasswordEmailSent(true);
            }
          } catch (error) {
            console.warn(
              'You have an error in your code or there are Network issues.',
              JSON.stringify(error)
            );
            if (isApiError(error)) {
              formikHelpers.setStatus(error.json);
            } else {
              formikHelpers.setStatus({err: error});
            }
          }
        }}
      >
        {({isSubmitting, status, setStatus}) => (
          <>
            <Form className="mb-8">
            {forgotPassword && (
              <div className="mb-4">
                Pour renouveler votre mot de passe, indiquez votre email et
                cliquez sur "Envoyer".
                <PageTitle title="Renouveller son mot de passe"/>
              </div>
            )}
            <Field
              label="Email"
              name="email"
              type="email"
              validate={(value: string) => {
                if (!value) {
                  return 'Ce champ est obligatoire';
                }
                setForgotPasswordEmailSent(false);
                return undefined;
              }}
              isRequired
              component={InputField}/>
            {!forgotPassword && (
              <>
                <Field
                  label="Mot de passe"
                  name="password"
                  type="password"
                  validate={(value: string) => {
                    if (!value) {
                      return 'Ce champ est obligatoire';
                    }
                    return undefined;
                  }}
                  isRequired
                  component={InputField}/>
                <a
                  className="hint"
                  onClick={() => {
                    setStatus(undefined);
                    setForgotPassword(true);
                  }}
                >
                  Mot de passe perdu ?
                </a>
              </>
            )}
            {forgotPasswordEmailSent && (
              <div className="message bg-blue-100 text-blue-900 mb-4">
                Si l'e-mail renseigné est bien associé à un compte RightsNow!,
                nous vous avons envoyé un email afin de renouveler votre mot de
                passe.
              </div>
            )}
            <div className="flex mt-4">
              {forgotPassword ? (
                <>
                  {/* Invisible button to allow form submit when press ENTER key */}
                  <button
                    style={{
                      overflow: 'visible !important',
                      height: '0 !important',
                      width: '0 !important',
                      margin: '0 !importan',
                      border: '0 !important',
                      padding: '0 !important',
                      display: 'block !important',
                    }}
                    type="submit"
                    value="default action"/>

                  <button
                    className="button ghost mr-4 outline text-primary border-primary"
                    onClick={() => {
                      setForgotPasswordEmailSent(false);
                      setForgotPassword(false);
                    }}
                  >
                    <FontAwesomeIcon icon={duotone("arrow-left")} className="h-5"/>
                  </button>
                </>
              ) : (
                <span/>
              )}
              <button
                className={cx('button w-full', {
                  loading: isSubmitting || forgotPasswordEmailSent,
                })}
                type="submit"
                disabled={isSubmitting || forgotPasswordEmailSent}
              >
                {forgotPassword ? 'Envoyer' : 'Connexion'}
              </button>
            </div>
            {status && (status.statusCode === 401 || status.err) && (
              <div className="message bg-red-100 text-red-500 my-4">
                <p>🤔 Connexion impossible. Vérifiez vos identifiants.</p>
              </div>
            )}
            {status && status.statusCode === 403 && !verificationEmailSent && (
              <div className="message bg-red-100 text-red-500 my-4">
                <div>
                  <p>
                    L'email n'a pas été confirmé. Merci de vérifier votre boite
                    email.
                  </p>
                  <p>
                    <a className="cursor-pointer" onClick={sendVerificationEmail}>
                      Renvoyer l'email de confirmation
                    </a>
                  </p>
                </div>
              </div>
            )}
            {verificationEmailSent && (
              <div className="message bg-green-100 text-green-500 my-4">
                <div>
                  <p>
                    Nous vous avons renvoyé l'email de confirmation. Merci de
                    vérifier votre boite email.
                  </p>
                  {!isSendVerificationEmailDisabled && (
                    <p>
                      <a
                        className="cursor-pointer"
                        onClick={sendVerificationEmail}
                      >
                        Renvoyer l'email de confirmation
                      </a>
                    </p>
                  )}
                </div>
              </div>
            )}
          </Form></>
        )}
      </Formik></>
  );
}

export default LoginForm;
