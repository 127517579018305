import React from 'react';

type Props = {
  className?: string
}

const IconClose: React.FC<Props> = (props) => {
  return (
    <svg aria-hidden="true" className={`${props.className}`} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g className="fa-group">
        <path className="fa-secondary" fill="currentColor"
              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"
              opacity="0.4"/>
        <path className="fa-primary" fill="currentColor"
              d="M377.6 321.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"/>
      </g>
    </svg>
  );
};

export default IconClose;
