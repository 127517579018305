import { create } from 'zustand';
import { UploadTracksRequestDTO } from 'shared-types';

export interface MyTracksState {
  uploadedTracks?: UploadTracksRequestDTO;
  uploadedTrackCount?: number;
  setUploadedTracks: (data: UploadTracksRequestDTO) => void;
  setUploadedTrackCount: (count: number) => void;
  clearUploadedTracks: () => void;
}

export const useMyTracksStore = create<MyTracksState>()((set) => ({
  setUploadedTracks: (data) => set({ uploadedTracks: data }),
  setUploadedTrackCount: (count) => set({ uploadedTrackCount: count }),
  clearUploadedTracks: () => set({ uploadedTracks: undefined, uploadedTrackCount: undefined }),
}));
