import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import RightsNowLogo from '../../components/svg/RightsNowLogo';
import ForgottenPasswordForm from './ForgottenPasswordForm';

export default function ForgottenPasswordPage() {
    const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
    return (
        <div className="flex justify-center login">
            <Helmet>
                <title>RightsNow! - Mot de passe oublié</title>
            </Helmet>
            <div className="flex justify-center lg:px-6 lg:my-6 xl:my-12 w-full xl:max-w-screen-lg">
                <div className="w-full h-auto hidden lg:flex lg:w-5/12 rounded-l-lg cursor-default select-none text-white text-xl uppercase leading-tight p-10 pt-16 font-display photo bedroom"/>
                <div className="w-full lg:w-7/12 bg-white p-10 sm:rounded-lg lg:rounded-l-none">
                    <div className="w-64 mb-12">
                        <a href="https://rightsnow.fr/">
                            <RightsNowLogo/>
                        </a>
                    </div>
                    {!passwordChangeSuccess && (
                        <ForgottenPasswordForm
                            onPasswordChangeSuccess={() => setPasswordChangeSuccess(true)}
                        />
                    )}
                    {passwordChangeSuccess && (
                        <div className="h-[400px]">
                            <div className="message bg-green-100 text-green-900">
                                <div>
                                    <h4>🎵 You rock !</h4>
                                    Votre mot de passe a bien été modifié. Vous pouvez vous connecter.
                                    <div className="mt-6">
                                        <a href="/login" className="button">Connexion</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
