import React, { useState } from 'react'
import GrooverLogo from '../../components/svg/GrooverLogo'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useUserInfo from '../../hooks/use-user-info'
import FlorentGarciaLogo from '../../components/svg/FlorentGarciaLogo'
import TuneCoreLogo from '../../components/svg/TuneCoreLogo'
import BandCampLogo from '../../components/svg/BandCampLogo'
import TeefactoryLogo from '../../components/svg/TeefactoryLogo'

const partners = [

  {
    name: 'Groover',
    logo: <GrooverLogo />,
    link: 'https://groover.co/?utm_source=indirect&utm_medium=partner&utm_campaign=rightsnow',
    intro: 'Fais la promotion de ta musique auprès des médias, radios, playlists & labels de ton choix, écoutes et retours GARANTIS !',
    description: 'Rejoins plus de 400 000 artistes qui utilisent déjà Groover en France, au Québec, aux États-Unis, en Europe, en Amérique latine et plus encore. Obtiens 10% de réduction sur ta première campagne avec le code ci-dessous.',
    offer: 'Code promo -10%',
    code: 'RIGHTSNOWVIP',
    online: true,
  },
  {
    name: 'Tunecore',
    logo: <TuneCoreLogo />,
    link: '',
    intro: 'Vendez votre musique dans le monde entier.',
    description: 'Distribuez votre musique sur Spotify, Apple Music, TikTok, YouTube, Deezer, Tidal, Tencent et plus. Conservez tous·vos droits sur votre musique et tenez les rênes de votre carrière.',
    offer: 'Code promo -20%',
    code: 'RIGHTSNOWVIP',
    online: false,
  },
  {
    name: 'Florent GARCIA',
    logo: <FlorentGarciaLogo />,
    link: '',
    intro: 'Les méthodes FLOW - 3 enseignements pour développer sa créativité à la guitare.',
    description: 'Les trois méthodes Flow forment l\'éco-système parfait pour développer sa créativité guitaristique et se développer en tant que musicien. Flow Ultimate s\'adresse aux guitaristes débutants et intermédiaires qui souhaitent pouvoir s\'exprimer au travers de leur instruments.',
    offer: '-20€ sur le pack Flow Ultimate',
    code: 'RIGHTSNOWVIP',
    online: false,
  },
  {
    name: 'Teefactory',
    logo: <TeefactoryLogo />,
    link: '',
    intro: 'Merchandising pour les groupes de musique.',
    description: 'Lancer une ligne de merchandising est l’occasion rêvée de laisser libre cours à sa créativité et d’obtenir un projet musical au-delà du son. Les merch’ sont à l’image de l’univers de l’artiste : c’est un moyen de se rapprocher de son public, de créer un sentiment d’appartenance et de renforcer sa communauté.',
    offer: '-10% sur votre première commande',
    code: 'RIGHTSNOWVIP',
    online: false,
  },
  {
    name: 'BandCamp',
    logo: <BandCampLogo />,
    link: '',
    intro: 'Touchez une toute nouvelle communauté de fans.',
    description: 'Bandcamp est à la fois un disquaire et une communauté en ligne qui offre aux passionnés de musique la possibilité d’entrer en contact avec leurs artistes préférés et de les soutenir directement.',
    offer: 'Code promo -15%',
    code: 'RIGHTSNOWVIP',
    online: false,
  },
]

export default function PartnerOffers() {
  const {
    userInfo: {
      subscription,
    },
  } = useUserInfo()

  const [copySuccess, setCopySuccess] = useState(false)

  // Fonction pour gérer la copie du code
  const handleCopy = async (codeCopy: string) => {
    try {
      await navigator.clipboard.writeText(codeCopy)
      setCopySuccess(true)
      setTimeout(() => setCopySuccess(false), 4000) // Efface le message après 2 secondes
    } catch (err) {
      console.error('Erreur lors de la copie :', err)
      setCopySuccess(false)
    }
  }

  return (
    <>
      <h2 className="text-2xl">Offres <span className="opacity-70">({partners.length})</span></h2>

      <p>Découvrez toutes les offres de nos partenaires sélectionnés pour vous accompagner dans votre vie d'auteur, compositeur interprète.</p>
      {subscription?.status === 'trialing' && (
        <div className="message bg-red-50 text-red-900 strong shadow">Non disponible durant la période d'essai.</div>
      )}

      <div className="space-y-4 mt-6">
        {partners.map((partner) => (
          <div>
            {partner.online && (
              <details className={`accordion bg-white rounded shadow hover:shadow-xl transition-shadow flex ${subscription?.status === 'trialing' ? 'opacity-50 select-none pointer-events-none' : ''}`}>
                <summary className="p-6 flex cursor-pointer">
                  <div className="flex justify-between w-full items-center">
                    <div className="flex flex-row">
                      <div className="mr-6 min-w-[3rem] max-w-[3rem] mt-1">{partner.logo}</div>
                      <div>
                        <div className="text-lg mb-3">
                          <strong>{partner.name}</strong>
                          <span className="text-xs relative w-auto whitespace-nowrap rounded-md ml-3 bg-green-500 py-1 px-2 text-white align-middle">
                        <FontAwesomeIcon icon={duotone('gift')} className="h-3 mr-2" />{partner.offer}
                      </span>
                        </div>
                        <p className="text-sm text-gray-500">{partner.intro}</p>
                      </div>
                    </div>
                  </div>
                </summary>
                {subscription?.status !== 'trialing' && (
                  <div className="p-6 rounded-b border-t">
                    <p className="text-sm mb-3">{partner.description}</p>

                    <div className="flex justify-between items-baseline">
                      <div className="w-auto text-xs rounded-md border bg-gray-50 px-1.5 py-1 shadow">
                        <div className="flex items-center justify-between">
                          <div className="ml-1.5 tracking-widest select-all">{partner.code}</div>
                          <button
                            type="button"
                            className={`ml-3 -mr-0.5 rounded-[5px] bg-primary-100 text-primary-900 px-4 py-1.5 font-medium hover:bg-primary-200 active:ring-4 active:ring-primary-300 ring-inset ${copySuccess && ('pointer-events-none select-none !bg-green !text-white')}`}
                            onClick={() => handleCopy(partner.code)}
                          >
                            {copySuccess ? <>Réussi !</> : <>Copier</>}
                          </button>
                        </div>
                      </div>
                      <a href={partner.link} target="_blank" rel="noreferrer" className="button text-sm py-1.5 px-3.5">Aller sur le site de {partner.name} <FontAwesomeIcon icon={duotone('arrow-up-right-from-square')} className="h-3 ml-4" /></a>
                    </div>
                  </div>
                )}
              </details>
            )}
          </div>
        ))}
        <div className="text-center text-xs !mt-6">👉 Revenez de temps en temps, d'autres offres exclusives sont en cours de négociation ;)...</div>
      </div>
    </>
  )

};
