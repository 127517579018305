import React from 'react';

const BandCampLogo: React.FC = () => {
  return (
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 475.8 476" enableBackground="new 0 0 475.8 476" xmlSpace="preserve">
<circle fill="#17A0C4" cx="237.9" cy="238" r="238"/>
      <g>
	<path fill="#FFFFFF" d="M304.9,204.5c-10.4,0-22,5.7-27.6,15h-0.4v-49h-20.3h-0.1H103.2L31.6,302.7H185l71.5-132L256.4,303h19.4
		v-13.2h0.4c6.1,12.7,19.5,15.8,32.1,15.8c27.4,0,40-24.3,40-50.1C348.3,227.5,334.1,204.5,304.9,204.5z M302.2,288.9
		c-16.5,0-26-14.5-26-33.8c0-20.5,8.8-34,26-34c16.9,0,25.7,16.5,25.7,34C327.9,271.8,320.9,288.9,302.2,288.9z"/>
        <path fill="#FFFFFF" d="M401.6,288.8c-17.8,0-24.6-16.8-24.6-32.8c0-29.1,14.9-34.9,25.5-34.9c11.3,0,19.6,6.3,21.2,18h20.4
		c-2-23.6-20.3-34.7-41.8-34.7c-30.5,0-45.7,22.7-45.7,51.6c0,28.2,15.9,49.5,45,49.5c23.8,0,38.7-13.7,42.5-38.6h-20.4
		C421.7,280.6,413.4,288.8,401.6,288.8z"/>
</g>
</svg>);
};

export default BandCampLogo;
