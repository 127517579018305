import React, { useState } from 'react';
import { TrackWithMonitoringData } from 'shared-types';
import api from 'services/api';
import { Pagination } from 'components/Pagination';
import { MyTracksTable } from 'features/tracks/MyTracksTable';
import GenericError from 'components/GenericError';
import { TrackPanel } from 'features/tracks/TrackPanel';
import IconClose from 'components/icons/IconClose';
import { DebounceInput } from 'react-debounce-input';
import useUserInfo from 'hooks/use-user-info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useQuery } from 'react-query';
import LoaderTrackTable from 'components/LoaderTrackTable';
import { useMyTracksStore } from 'features/tracks/MyTracksState';

const PAGE_SIZE = 20;

export function MyTracksTableContainer() {
  const { userInfo, isLoading: isLoadingUserInfo, isError: isErrorUserInfo } = useUserInfo();
  const { uploadedTrackCount, clearUploadedTracks } = useMyTracksStore()
  const [selectedTrack, setSelectedTrack] = useState<TrackWithMonitoringData | undefined>(undefined);
  const [deletedTrack, setDeletedTrack] = useState<TrackWithMonitoringData | undefined>(undefined);
  const [currentPage, setPage] = useState<number>(1);
  // const [data, setData] = useState<APIDataListResponse<TrackWithMonitoringData> | undefined>();
  // const [isLoadingTracks, setIsLoadingTracks] = useState(false);
  // const [isErrorTracks, setIsErrorTracks] = useState(false);
  const [search, setSearch] = useState('');

  const {
    data,
    isLoading: isLoadingTracks,
    isError: isErrorTracks,
    refetch,
  } = useQuery(['/api/tracks', currentPage, search, deletedTrack], async () => {
    const tracks = await api.fetchMyTracks({
      currentPage,
      pageSize: PAGE_SIZE,
      search,
    });
    if (selectedTrack !== undefined) {
      setSelectedTrack(tracks.data.find((t) => t.id === selectedTrack.id));
    }
    return tracks;
  });

  const tracks: TrackWithMonitoringData[] = data?.data || [];
  const filteredTracks = tracks;
  const total = data?.total || 0;
  const paginatedTracks = filteredTracks;
  const isLoading = isLoadingUserInfo || isLoadingTracks;
  const isError = isErrorUserInfo || isErrorTracks;

  let trackPanel = null;
  if (selectedTrack) {
    const index = filteredTracks.findIndex((t) => t.id === selectedTrack.id);
    const hasPrevious = index > 0;
    const hasNext = index < filteredTracks.length - 1;
    const getCurrentPageForTrackIndex = (index: number) => {
      return Math.ceil((index + 1) / PAGE_SIZE);
    };
    const selectNextTrack = () => {
      const nextTrackIndex = index + 1;
      setSelectedTrack(filteredTracks[nextTrackIndex]);
      setPage(getCurrentPageForTrackIndex(nextTrackIndex));
    };
    const selectPreviousTrack = () => {
      const nextTrackIndex = index - 1;
      setSelectedTrack(filteredTracks[nextTrackIndex]);
      setPage(getCurrentPageForTrackIndex(nextTrackIndex));
    };
    const handleEditTitle = async (value: string) => {
      await api.updateTrack(selectedTrack.id, { title: value });
      // const newTracks = await loadTracks();
      refetch();
      // setSelectedTrack(newTracks.find((t) => t.id === selectedTrack.id));
    };
    const handleEditArtist = async (value: string) => {
      await api.updateTrack(selectedTrack.id, { artist: value });
      // const newTracks = await loadTracks();
      refetch();
      // setSelectedTrack(newTracks.find((t) => t.id === selectedTrack.id));
    };
    trackPanel = (
      <TrackPanel
        track={selectedTrack}
        onDelete={() => {
          setDeletedTrack(selectedTrack);
          setSelectedTrack(undefined);
          // refetch();
        }}
        onClose={() => setSelectedTrack(undefined)}
        subscriptionFormula={userInfo.subscription?.formula}
        showPrevious={hasPrevious}
        onPrevious={selectPreviousTrack}
        showNext={hasNext}
        onNext={selectNextTrack}
        onEditTitle={handleEditTitle}
        onEditArtist={handleEditArtist}
      />
    );
  }

  return (
    <>
      {deletedTrack && (
        <div className="message bg-green-500 text-white mb-6 flex justify-between">
          <div className="content">
            Le titre <strong>{deletedTrack.title}</strong> a bien été supprimé.
          </div>
          <div className="cursor-pointer self-baseline" onClick={() => setDeletedTrack(undefined)}>
            <IconClose className="w-6" />
          </div>
        </div>
      )}
      {uploadedTrackCount && (
        <div className="message bg-green-500 text-white mb-6 flex justify-between">
          <div className="content">
            Titre(s) ajouté(s) : <strong>{uploadedTrackCount}</strong>.
          </div>
          <div className="cursor-pointer self-baseline" onClick={clearUploadedTracks}>
            <IconClose className="w-6" />
          </div>
        </div>
      )}
      <div className="w-full bg-white shadow-xl p-6 rounded">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl mb-0">Mes titres {total > 0 && <span className="counter">{total}</span>}</h1>
          <a className="button" href="/track-upload">
            <FontAwesomeIcon icon={duotone('plus')} className="md:mr-2" />
            <span className="hidden md:inline-block">Ajouter un titre</span>
          </a>
        </div>
        <div className="search-track relative">
          <FontAwesomeIcon icon={duotone('magnifying-glass')} className="absolute translate-y-1/2 ml-6 h-6" />
          <DebounceInput
            debounceTimeout={300}
            type="search"
            className="w-full"
            placeholder="Rechercher un titre"
            value={search}
            onChange={(event) => {
              if (currentPage !== 1) {
                setPage(1);
              }
              setSearch(event.target.value);
            }}
          />
        </div>
        <div className="relative py-2 px-6 -mx-6 text-xs bg-yellow-200">Les données affichées représentent le cumul des diffusions depuis votre inscription (avec 1 mois d'antériorité pour le streaming). <a href="https://www.rightsnow.fr/kb/historique-statistiques/" target="_blank">En savoir plus / Récupérer l'historique</a>.</div>
        {isLoading && !isError && <LoaderTrackTable />}
        {!isLoading && isError && <GenericError className="m-6" />}
        {!isLoading && !isError && (
          <>
            <div className="-mx-6 overflow-auto lg:overflow-visible">
              {paginatedTracks.length > 0 && (
                <MyTracksTable
                  tracks={paginatedTracks}
                  onShowDetailAction={setSelectedTrack}
                  selectedTrack={selectedTrack}
                  searchText={search}
                  userInfo={userInfo}
                />
              )}
              {paginatedTracks.length === 0 && (
                <div className="p-6 bg-yellow-200 border-b border-yellow-400">🤔 Aucun résultat...</div>
              )}
            </div>
            <Pagination
              current={currentPage}
              pageSize={PAGE_SIZE}
              total={total}
              onChange={(page: number) => setPage(page)}
            />
          </>
        )}
        {trackPanel}
      </div>
    </>
  );
}
