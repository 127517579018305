import React from 'react';
import { Field, Form, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import InputField from 'components/InputField';
import { UpdateUserDTO, UserDTO } from 'shared-types';
import {duotone} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PersonnalDataFormSchema = Yup.object()
  .shape({
    firstName: Yup.string().required('Ce champ est obligatoire'),
    lastName: Yup.string().required('Ce champ est obligatoire'),
    phone: Yup.string(),
    addressLine1: Yup.string(),
    addressLine2: Yup.string(),
  })
  .defined();

export type PersonnalDataFormValues = Yup.InferType<
  typeof PersonnalDataFormSchema
>;

type Props = {
  initialData: Partial<UserDTO>;
  handleSubmit: (values: Partial<UpdateUserDTO>) => void;
  updateSuccess: boolean;
  closeUpdateSuccessMessage: () => void;
};

class PersonnalDataForm extends React.Component<
  Props & FormikProps<PersonnalDataFormValues>
> {
  render() {
    const { updateSuccess, isSubmitting, closeUpdateSuccessMessage } =
      this.props;
    return (
      <Form className="space-y-6">
        {updateSuccess && (
        <div className="message bg-green-500 text-white mb-6 flex justify-between">
          <div className="content">Informations mises à jour.</div>
          <div
            className="cursor-pointer self-baseline"
            onClick={closeUpdateSuccessMessage}
          >
            <FontAwesomeIcon icon={duotone("xmark-circle")} className="w-5 h-5 align-sub" />
          </div>
        </div>
        )}
        <div className="bg-white shadow-xl p-6 rounded">
          <div className="border-b">
            <div className="md:flex justify-between mb-6">
              <Field
                label="Prénom"
                name="firstName"
                className="w-full md:w-1/2 md:mr-4"
                isRequired
                component={InputField}
              />{' '}
              <Field
                label="Nom"
                name="lastName"
                className="w-full md:w-1/2"
                isRequired
                component={InputField}
              />
            </div>
            <div className="md:flex justify-between ">
              <Field
                label="Email"
                name="email"
                className="w-full md:w-1/2 md:mr-4"
                isRequired
                isDisabled
                component={InputField}
              />{' '}
              <Field
                label="Téléphone"
                name="phone"
                type="tel"
                className="w-full md:w-1/2"
                component={InputField}
              />
            </div>
            <div className="message p-2 text-xs mb-6">
              <p>
                ☝&nbsp;Pour modifier votre email, contactez-nous en cliquant sur "Nous contacter" tout en bas de la page.
                .
              </p>
            </div>
          </div>
          <div className="pt-6">
            <Field
              label="Adresse"
              name="addressLine1"
              className="w-full"
              component={InputField}
            />{' '}
            <Field
              label="Complément d'adresse"
              name="addressLine2"
              className="w-full"
              component={InputField}
            />
          </div>
          <div className="actions mt-4">
            <button
              type="submit"
              className={cx('button w-full md:w-auto', {
                loading: isSubmitting,
              })}
              disabled={isSubmitting}
            >
              Mettre à jour
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

export default withFormik<Props, PersonnalDataFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    firstName: props.initialData.firstName || '',
    lastName: props.initialData.lastName || '',
    email: props.initialData.email || '',
    phone: props.initialData.phone || '',
    addressLine1: props.initialData.addressLine1 || '',
    addressLine2: props.initialData.addressLine2 || '',
  }),

  validationSchema: PersonnalDataFormSchema,

  handleSubmit: (values, { props }) => props.handleSubmit(values),
})(PersonnalDataForm);
