import React from 'react';

type Props = {
  className?: string
}

const EmptyStateUpload: React.FC<Props> = (props) => {
  return (
      <svg version="1.1" id="Calque_1" className={`${props.className}`} viewBox="0 0 295 173.4">
<path fill="#374151" d="M25.9,114.5c1.5-14.4,0.7-28.1,0.8-51.5c-0.1-0.8,0-1.5,0.2-2.2c0.2-1,2.7-0.8,4-0.9
	c3.9-0.2,12.2-0.1,13.3,0c1.9,0.1,2.8,1.6,3.9,3c0.2,0.2-0.6-0.6,5.4,6.2c3.6,0.3,23.7-0.4,32.9-0.5c18.5-0.2,17.7-0.2,18.1,0.2
	c0.8,0.7,0.6,3.7,1.2,5.6c0.1,0.4,0.6,0.7,1,0.6c0.3-0.1,0.5-0.3,0.6-0.6c0.8-2.5,1.3-6.5-1.2-8.2c-1.6-1.1-2.6-0.8-15.7-0.7
	c-13.3,0.1-34.9,1.1-35.1,1.1c-1.3-1.5-3.6-3.9-4.8-5.3c-2.4-3-3.2-4.2-7-4.3c-5.3-0.1-8.6-0.2-14.2,0.2c-1.5,0.1-3.3,0-4.4,1.1
	c-1,1.1-0.8,3.3-0.8,5.8c0,0.2,0.1,43.7,0.5,50.3c0,0.4,0.3,0.7,0.7,0.7C25.5,115.2,25.9,114.9,25.9,114.5z"/>
        <path fill="#374151" d="M114.9,81.4c-0.2-1.9-1.4-3.6-3.1-4.4c-1.7-0.7,3.3-0.7-61.9,1.1c-5.5,0.2-8.6,0.6-10.7,5.7
	c-1.8,4.5-11.8,30.6-14.4,38.4c-0.3,0.9,1.1,1.5,1.5,0.6c5.5-12.4,13-32.6,15.3-38.1c0.8-2.3,2.9-3.9,5.4-3.9
	c1.6-0.2,59.8-1.5,61.1-1.6c1.1,0,2.5-0.2,3.4,0.7c1.6,1.6,0.9,5,0.5,6.9c-2.1,8.5-4.7,16.8-7.7,25c-1.6,4.3-3.3,8.5-5.1,12.8
	c-0.4,0.9,1,1.6,1.6,0.9c2.5-3.3,4.4-7.1,5.5-11.2c3.9-10.8,6.9-21.1,7.9-25.3C114.9,86.6,115.2,84,114.9,81.4z"/>
        <path fill="#374151" d="M96.3,125.9c-1.7-0.5-3.5-0.6-5.2-0.4c-14.4,0.6-35.4,1-49.9,0.6c-4.9-0.1-10.5,0.2-15.1-1.8
	c-0.6-0.2-1,0.6-0.6,1c3.3,3,8.8,2.9,13.1,3.1c17.2,0.9,36.1,0.2,51.8-0.4c2.1,0.1,4.2-0.2,6.1-0.9
	C97.1,126.9,96.8,126.1,96.3,125.9z"/>
        <path fill="#374151" d="M241.8,80.7c-2.6-7.5-6.5-13.9-13.6-17.8c-6-3.2-14.6-5.3-21.2-2.7c-0.5,0.1-0.8,0.7-0.7,1.2
	c0.1,0.4,0.5,0.7,1,0.7c2.9,0.1,5.7-0.2,8.6,0.1c9.2,0.5,17.3,6,21.2,14.4c3.2,6.6,4.9,13.8,4.8,21.1c-0.5,11.2-7.7,23.5-13.2,28
	c-2.9,2.2-6.2,3.8-9.8,4.7c-23.9,6.9-41.5-10.2-41.5-33.4c0-15.8,8.4-29.8,24.1-33.8c0.7-0.2,0.6-1.4-0.2-1.3
	c-14.6,1.6-24.1,15.6-25.9,29.1c-3.3,24.3,12.8,46.9,38.1,43.4c7.5-1,15.3-3.8,20.2-9.8c5-6,8.6-13.1,10.3-20.8
	C245.4,96.1,244.7,88.1,241.8,80.7z"/>
        <path fill="#003CFF" d="M197.8,77.5c-0.7-0.4-1.6,0.7-0.7,1.2c0.4,10.7,1,21.4,1.6,32.1c0,0.4,0.3,0.7,0.7,0.7c0.1,0,0.2,0,0.3-0.1
	c9.9-3.9,19.1-9.2,27.5-15.6c0.3-0.3,0.3-0.7,0-1l-0.1-0.1C217.7,88.3,207.9,82.5,197.8,77.5z"/>
        <path fill="#374151" d="M251.4,133.1c1.6,1.6,3.7,2.6,5.9,2.8c0.5,0,0.8-0.4,0.8-0.9c0-0.1-0.1-0.3-0.1-0.4c-1.5-1.5-3.1-2.9-5-3.9
	c-1.8-1.4-3.4-3.1-5.2-4.6c-0.4-0.3-1.1-0.3-1.4,0.2c-0.3,0.3-0.3,0.8,0,1.2C247.6,129.7,249.4,131.6,251.4,133.1z"/>
        <path fill="#374151" d="M261.3,118.4c2.5,0.7,5.7,1.4,8.1,0.1c0.7-0.4,0.5-1.4-0.1-1.7c-3.3-1.8-7.1,0.1-15.4-4.5
	c-1.5-0.8-2.4,1.5-1.1,2.3C255.4,116.2,258.3,117.5,261.3,118.4z"/>
        <path fill="#374151" d="M235.1,150.8c0.5,1,2.1,0.8,2-0.4c-0.4-5.5-2.8-11.1-4.4-16.4c-0.5-1.5-2.6-0.7-2.2,0.8
	C231.7,140,232.6,145.9,235.1,150.8z"/>
        <path fill="#374151" d="M173.2,122.2c-3.3-1.6-6.7-2.9-10.2-3.8c-10-3.5-18.8-7.5-28.7-11.5c-1.5-0.6-3.4-1.7-5.1-1.2
	c-0.3,0.1-0.5,0.5-0.4,0.8c-0.2,0-0.5,0.1-0.6,0.3c-1.8,2.8-3.2,5.9-4.8,8.8c-0.1,0.3-0.1,0.7,0.2,1c-2.2,5.3-11.1,23.8-14.1,29.9
	c-2.6-3.2-6-5.7-9.9-7.2c-3.7-1.4-9.1-2.1-11.5,1.8c-3.8,6.1,3.9,18.4,14.5,18.2c5.5-0.1,10.1-4.2,9.3-9.9c0-0.3-0.1-0.5-0.3-0.7
	c8.5-17.3,12.5-27,14.4-31.1c15,5.3,24,13.5,39.4,16.4c-3.5,6.9-7.5,15.4-11.2,24.1c-5.6-5.6-16-8.3-21.2-0.9
	c-2.4,3.7-1.7,8.6,1.8,11.4c3.9,3.2,9.1,4.1,13.8,2.5c4.8-1.6,9.1-6.8,8.1-12.1c3.5-8.3,6.5-14.7,11.3-24.5c0.2,0,0.4,0.1,0.5,0.1
	c0.6,0.1,1.1-0.3,1.3-0.8c1.6-3.2,3.2-6.4,4.7-9.7c0.3-0.6,0-1.3-0.6-1.6C173.9,122.2,173.5,122.1,173.2,122.2z M126.8,115.7
	c2.5-6,0.9-3.4,2.6-8.3c1.2,0.9,2.5,1.6,3.9,2.1c8.2,3.9,31.3,13.7,37.5,14.6c-1.6,2.4-3,4.8-4.2,7.4
	C151,128.8,141.8,120.4,126.8,115.7z"/>
        <path fill="#374151" d="M90.7,162.9c-3.1,2.9-5.9,6-8.3,9.2c-0.4,0.4-0.3,1,0.1,1.2s1.1,0.1,1.5-0.3c3.1-2.9,5.8-6,8.1-9.3
	C92.6,162.9,91.5,162.2,90.7,162.9z"/>
        <path fill="#374151" d="M84.7,150.7c-5.1-1.6-11.4,0.5-16,2.6c-1.3,0.6-0.6,2.6,0.7,2.1c7.7-3,11.3-2.2,15.4-3.2
	C85.5,151.9,85.3,150.9,84.7,150.7z"/>
        <g>
	<path fill="#374151" d="M53.8,98c0.5-1.1,1.1-2.6,1.8-4.4c0.8-1.9,1.4-3.4,1.8-4.4l0.9-1.9c0.1-0.2,0.1-0.4,0.1-0.5
		c-0.1-0.2-0.2-0.3-0.5-0.3c-0.2,0-0.4,0-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5l-4.9,7.4c-0.1,0.1-0.2,0.3-0.7,1
		c0.1-0.3,0.1-0.6,0.2-0.8c0.2-1,0.5-2.5,0.8-4.4s0.5-3.1,0.6-3.5c0-0.3-0.2-0.4-0.5-0.4c-0.1,0-0.3,0-0.6,0.2
		c-0.2,0.1-0.4,0.3-0.5,0.5l-1.9,4c-0.3,0.7-0.7,1.4-1.1,2.3c-0.3,0.7-0.6,1.4-0.9,2l-2.4,5.2c-0.6,1.2,0,1.4,0.5,1.4
		c0.1,0,0.3,0,0.4-0.1c0.3-0.2,0.5-0.4,0.7-0.8l0.4-1c0.1-0.4,0.2-0.7,0.3-0.9c0.5-1.1,1-2.2,1.4-3.1c0.4-0.9,0.9-1.9,1.4-3.1
		c0-0.1,0.1-0.1,0.1-0.2c-0.1,0.2-0.1,0.5-0.2,0.8c-0.6,2.8-1.1,4.6-1.2,5.1c-0.1,0.3-0.1,0.5-0.1,0.7c0.1,0.2,0.3,0.3,0.5,0.3
		s0.5-0.1,0.8-0.3c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.3,0.6-0.9,1.2-1.7c0.5-0.8,1.2-1.8,2-3c-0.2,0.4-0.4,0.9-0.6,1.5
		c-0.2,0.4-0.4,0.8-0.4,1c-0.5,1.3-1,2.3-1.4,3.2l-0.8,1.8c-0.1,0.3-0.2,0.5-0.1,0.7c0,0.1,0.2,0.3,0.6,0.3c0.3,0,0.5-0.1,0.9-0.4
		c0.1-0.2,0.3-0.4,0.4-0.7c0.2-0.4,0.4-1,0.8-2C53.4,98.9,53.6,98.3,53.8,98z"/>
          <path fill="#374151" d="M63.4,86.9L63.4,86.9c-0.4-0.2-0.9-0.4-1.4-0.4c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0.1-0.4,0.2
		c-0.1,0-0.1,0.1-0.1,0.1l-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.5-0.5,1.1-0.8,1.9l-0.3,0.7c-0.6,1.5-1.3,3.1-2.1,4.8
		c-0.3,0.6-0.5,1.1-0.7,1.6l-1.5,3.3c-0.2,0.5-0.5,1.1-0.8,1.7c-0.3,0.7-0.3,1.1,0.2,1.1s0.8-0.2,1-0.4s0.3-0.4,0.5-0.7
		c0.4-0.9,0.9-1.9,1.4-3.2l1.2-2.9c0.6-0.2,1.2-0.5,1.8-1c0.7-0.5,1.3-1.1,1.8-1.8c0.5-0.6,0.9-1.3,1.2-1.9c0.4-1,0.6-1.9,0.4-2.7
		C64,87.5,63.8,87.1,63.4,86.9z M59.7,93.4c0.3-0.7,0.6-1.5,1-2.6c0.5-1.2,0.8-2.1,1.2-2.8c0.1,0,0.3,0.1,0.4,0.2
		c0.2,0.1,0.3,0.3,0.3,0.6c0,0.6-0.1,1.2-0.4,1.8C61.7,91.7,60.9,92.6,59.7,93.4z"/>
          <path fill="#374151" d="M67.4,96.9C67.4,96.9,67.4,96.8,67.4,96.9c0-0.2,0.1-0.5,0.2-0.8c0.2-0.7,0.3-1.3,0.3-1.7
		c0-0.5-0.1-0.9-0.4-1.2l0.1-0.1c0.2-0.2,0.4-0.3,0.3-0.3c0.1,0,0.2-0.1,0.2-0.1c0.5-0.5,0.9-0.9,1-1.3c0.8-1.8,1.1-3.1,0.8-3.9
		c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.2-0.3-0.3-0.4c-0.2-0.1-0.4-0.2-0.7-0.2c-0.5,0-1.1,0.1-1.6,0.4c-0.5,0.3-0.9,0.6-1.4,1.1
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2,0,0.4,0.1,0.5s0.3,0.2,0.5,0.2c0.6,0,1.2-0.6,1.3-0.8c0.1-0.1,0.1-0.1,0.3-0.2
		c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.3,0.1,0.5s-0.1,0.5-0.2,0.9s-0.3,0.9-0.6,1.5
		c-0.1,0.2-0.3,0.5-0.5,0.8c-0.3,0.3-0.6,0.5-0.8,0.7c-0.3,0.2-0.5,0.4-0.6,0.4c-0.2,0.1-0.4,0.3-0.5,0.4C65,93.7,65,93.9,65.1,94
		c0,0.1,0,0.2,0.2,0.3s0.3,0.1,0.5,0.1c0.1,0.2,0.2,0.4,0.2,0.7s0,0.6,0,0.9c0,0.3-0.1,0.6-0.2,1c-0.2,0.6-0.5,1.3-0.9,2.1
		c-0.8,1.8-1.6,2-1.9,2l-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.5-0.2-0.8,0l-0.2,0.1c-0.2,0.1-0.3,0.2-0.4,0.3
		c-0.1,0.3-0.2,0.6,0.3,0.9c0.3,0.2,0.6,0.3,1,0.3c0.3,0,0.7-0.1,1-0.2c0.6-0.2,1.1-0.6,1.6-1.1c0.4-0.4,0.7-0.9,1-1.4
		c0.2-0.4,0.4-0.8,0.6-1.3c0.2-0.4,0.3-0.6,0.3-0.7C67.2,97.5,67.3,97.2,67.4,96.9z"/>
          <path fill="#374151" d="M62.3,105.3c-0.1-0.1-0.3-0.2-0.5-0.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.7l-0.1,0.4
		c-0.3,0.6-0.8,1.5-1.4,2.6c-0.6,1.1-1.1,1.9-1.3,2.3l-2.4,4.2c0.2-0.6,0.4-1.4,0.6-2.2c0.8-2.9,1.3-4.7,1.5-5.4v-0.1
		c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2,0-0.3s-0.2-0.3-0.6-0.3c-0.1,0-0.2,0-0.3,0.1s-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
		l-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.3l-0.7,1.3l-1,1.6c-0.5,0.8-0.8,1.3-1,1.7c0,0-0.1,0.1-1,1.9c0.8-2.4,1.6-4.9,2.5-7.4l0.3-0.6
		c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.2-0.7-0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.3,0.2-0.5,0.3s-0.2,0.3-0.2,0.4
		c0,0.1,0,0.4-0.6,2.3l-0.1,0.4c-0.6,2-1.1,3.5-1.4,4.3c-0.4,1.1-0.7,2.1-1,3c-0.1,0.2-0.2,0.6-0.3,1.1l-0.6,2.3v0.1
		c0,0.1,0,0.1,0,0.1s0,0.1-0.1,0.1l-0.1,0.2c-0.2,0.5,0,0.9,0.5,0.9c0.3,0,0.9-0.2,1.4-1.2l0.1-0.3l3.9-7c-1,3.2-1.7,5.6-2.1,7
		l-0.1,0.3c-0.1,0.4-0.2,0.6-0.2,0.6c-0.1,0.3,0.2,0.6,0.6,0.6c0.4,0,0.7-0.2,0.9-0.3s0.3-0.3,0.4-0.5l1.7-2.9l3.1-5.5
		c1.2-2.2,2.2-4,2.9-5c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.2,0.4-0.4,0.5-0.5C62.5,105.7,62.5,105.5,62.3,105.3z
		 M61.9,105.6L61.9,105.6L61.9,105.6L61.9,105.6z"/>
          <path fill="#374151" d="M65.1,114.9L65.1,114.9l1.2-3.3c0.4-1.2,0.8-2.4,1.2-3.5c0.6-1.8,0.6-2,0.5-2.1c-0.1-0.2-0.2-0.4-0.7-0.4
		c-0.4,0-0.8,0.4-1,0.6c-0.3,0.3-0.5,0.6-0.7,0.8c-0.5,0.6-0.8,1.1-1,1.4c-0.4,0.5-1.2,1.8-2.5,3.8l-0.4,0.6
		c-1.1,1.7-1.8,2.8-2.1,3.1c-0.2,0.2-0.5,0.6-0.9,1.3c-0.4,0.6-0.8,1.2-1.3,1.8l-0.7,1c0,0.1-0.1,0.1-0.1,0.2
		c-0.1,0.2-0.1,0.4,0.1,0.6c0.3,0.3,1.1,0.2,1.4-0.3l0.3-0.4c0.5-0.8,1-1.5,1.5-2.1l0.3-0.4c0.4,0,0.8,0.1,1.3,0.2
		c0.4,0.1,0.6,0.1,0.6,0.2l-1.1,2.4c-0.1,0.2-0.1,0.4,0,0.5s0.3,0.2,0.5,0.2c0.5,0,0.9-0.2,1.1-0.4c0.1-0.2,0.2-0.3,0.4-0.6
		c0.3-0.6,0.6-1.4,1-2.6C64.5,116.3,64.8,115.4,65.1,114.9z M64,113.4c-0.5,1.2-0.9,2.3-1.2,3h-0.1h-0.1c-0.6,0-1.2-0.1-1.6-0.2
		c0.1-0.2,0.4-0.6,0.8-1.2l0.5-0.7c0.7-1,1.7-2.4,2.9-4.4c0.1-0.1,0.2-0.3,0.2-0.4v0.1C65,110.8,64.5,112,64,113.4z"/>
          <path fill="#374151" d="M76.7,105.7c0-0.1,0-0.1-0.1-0.1l-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.5,0-1,0.3-1.2,0.8
		c-0.2,0.3-0.4,0.7-0.6,1.1l-0.6,1l-0.6,1c-0.2,0.4-0.7,1.2-1.4,2.4l-2.5,4.3l1.9-6.7l0.7-2.7c0.1-0.2,0.1-0.3,0.1-0.5
		s-0.1-0.4-0.2-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.4,0-0.7,0.3c-0.2,0.1-0.4,0.3-0.4,0.5c-0.2,0.5-0.4,1.3-0.6,2.4l-2.1,7.7
		c-0.5,1.9-0.9,3.3-1.1,4.3c-0.1,0.3,0.2,0.4,0.5,0.4h0.1c0.4,0,0.8-0.2,1.2-0.7l0.1-0.1c0.2-0.3,0.8-1.4,1.9-3.3
		c1.1-1.9,2.1-3.7,3.1-5.3c0.2-0.3,0.7-1.2,1.5-2.6l0.8-1.2c0.5-0.8,0.8-1.3,1-1.7C76.9,106,76.9,105.8,76.7,105.7L76.7,105.7z"/>
          <path fill="#374151" d="M81.8,94.9L81.8,94.9l1.2-3.3c0.4-1.2,0.8-2.4,1.2-3.5c0.6-1.8,0.6-2,0.5-2.1c-0.1-0.2-0.2-0.4-0.7-0.4
		c-0.4,0-0.8,0.4-1,0.6c-0.3,0.3-0.5,0.6-0.7,0.8c-0.5,0.6-0.8,1.1-1,1.4c-0.4,0.5-1.2,1.8-2.5,3.8l-0.4,0.6
		c-1.1,1.7-1.8,2.8-2.1,3.1c-0.2,0.2-0.5,0.6-0.9,1.3c-0.4,0.6-0.8,1.2-1.3,1.8l-0.7,1c0,0.1-0.1,0.1-0.1,0.2
		c-0.1,0.2-0.1,0.4,0.1,0.6c0.3,0.3,1.1,0.2,1.4-0.3l0.3-0.4c0.5-0.8,1-1.5,1.5-2.1l0.3-0.4c0.4,0,0.8,0.1,1.3,0.2
		c0.4,0.1,0.6,0.1,0.6,0.2l-1.1,2.4c-0.1,0.2-0.1,0.4,0,0.5s0.3,0.2,0.5,0.2c0.5,0,0.9-0.2,1.1-0.4c0.1-0.2,0.2-0.3,0.4-0.6
		c0.3-0.6,0.6-1.4,1-2.6C81.2,96.3,81.6,95.5,81.8,94.9z M80.7,93.4c-0.5,1.2-0.9,2.3-1.2,3h-0.1h-0.1c-0.6,0-1.2-0.1-1.6-0.2
		c0.1-0.2,0.4-0.6,0.8-1.2l0.5-0.7c0.7-1,1.7-2.4,2.9-4.4c0.1-0.1,0.2-0.3,0.2-0.4v0.1C81.7,90.8,81.2,92.1,80.7,93.4z"/>
          <path fill="#374151" d="M89.5,94.9L89.5,94.9l1.2-3.3c0.4-1.2,0.8-2.4,1.2-3.5c0.6-1.8,0.6-2,0.5-2.1c-0.1-0.2-0.2-0.4-0.7-0.4
		c-0.4,0-0.8,0.4-1,0.6c-0.3,0.3-0.5,0.6-0.7,0.8c-0.5,0.6-0.8,1.1-1,1.4c-0.4,0.5-1.2,1.8-2.5,3.8l-0.4,0.6
		c-1.1,1.7-1.8,2.8-2.1,3.1c-0.2,0.2-0.5,0.6-0.9,1.3c-0.4,0.6-0.8,1.2-1.3,1.8l-0.7,1c0,0.1-0.1,0.1-0.1,0.2
		c-0.1,0.2-0.1,0.4,0.1,0.6c0.3,0.3,1.1,0.2,1.4-0.3l0.3-0.4c0.5-0.8,1-1.5,1.5-2.1l0.3-0.4c0.4,0,0.8,0.1,1.3,0.2
		c0.4,0.1,0.6,0.1,0.6,0.2l-1.1,2.4c-0.1,0.2-0.1,0.4,0,0.5s0.3,0.2,0.5,0.2c0.5,0,0.9-0.2,1.1-0.4c0.1-0.2,0.2-0.3,0.4-0.6
		c0.3-0.6,0.6-1.4,1-2.6C88.9,96.3,89.2,95.5,89.5,94.9z M88.4,93.4c-0.5,1.2-0.9,2.3-1.2,3h-0.1H87c-0.6,0-1.2-0.1-1.6-0.2
		c0.1-0.2,0.4-0.6,0.8-1.2l0.5-0.7c0.7-1,1.7-2.4,2.9-4.4c0.1-0.1,0.2-0.3,0.2-0.4v0.1C89.4,90.8,88.9,92.1,88.4,93.4z"/>
          <path fill="#374151" d="M95.5,99c-0.2,0-0.5,0.1-1,0.4c-0.4,0.3-0.8,0.4-1.3,0.4c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
		c0-0.1,0-0.5,0.8-2.8c0.6-1.7,1.1-3,1.5-3.9c0.2-0.4,0.3-0.7,0.4-1l0.5-1.1c0.2-0.4,0.4-0.8,0.6-1.1s0.4-0.7,0.7-1
		c0.2-0.3,0.5-0.6,0.7-0.7c0.2-0.2,0.5-0.3,0.8-0.5c0.3-0.1,0.6-0.2,1-0.2c0.1,0,0.2,0,0.4,0.1c0.3,0.1,0.4,0.2,0.6,0.2
		c0.6,0,1-0.3,1.1-0.6c0.1-0.2,0.2-0.6-0.3-0.9c-0.6-0.4-1.5-0.3-2.2-0.1c-0.8,0.2-1.6,0.5-2.2,1c-0.3,0.3-0.6,0.5-0.8,0.8
		c-0.2,0.3-0.4,0.6-0.7,0.9c-0.4,0.7-0.8,1.4-1.1,2c-0.2,0.4-0.3,0.7-0.4,1l-0.5,1c-0.4,0.9-0.9,2.3-1.6,4c-0.9,2.5-1,3.1-0.9,3.3
		c0,0.4,0.2,0.8,0.6,1.1s0.8,0.5,1.3,0.5c0.6,0,1.2-0.1,1.8-0.3c0.4-0.1,0.8-0.3,1.1-0.5c0.3-0.3,0.6-0.5,0.7-0.9
		C96.1,99.2,95.9,99,95.5,99z"/>
</g>
        <g>
	<path fill="#003CFF" d="M142.3,81.4c-1.5-0.8-2.2-1.7-2.3-2.6l-0.4-8.9c-0.1-2.7-0.2-5.2-0.1-7.5l-0.1-7.5
		c-0.1-1.9-0.2-3.4-0.4-4.4c-0.1-1-0.5-2-1.2-3c-0.6-0.9-1.5-1.5-2.7-1.6c-1.2-0.1-3.2-0.1-6.1,0l-6.3,0.9c-2.8,0.7-4.9,1.1-6.3,1.2
		c-5.6,0.3-8.4-1.6-8.6-5.6c-0.1-1.5,0.1-2.6,0.4-3.2c0.4-0.6,1.2-0.9,2.5-1l7.5-0.1l7.8,0.2c7.9-0.4,11.8-1.3,11.7-2.9
		c-0.2-3.3-0.7-8-1.6-14.3l-1.3-14.4c-0.1-2.3,0.2-3.9,0.9-4.9c0.7-1,2.2-1.5,4.5-1.7c3.5-0.2,5.3,1.4,5.4,4.6l-1,2.9l1.1,11.2
		l1.1,11.5c0.1,2.7,0.8,4.5,2.1,5.4c1.3,0.9,3.4,1.3,6.3,1.1l8.6-1l8.6-0.7c5.9-0.3,9,1.3,9.2,4.7c0.1,3.1-1.4,4.7-4.7,4.8
		c-3.3,0.2-6.1,0.1-8.7-0.2c-2.5-0.1-5.5,0-8.9,0.1l-1.4,0.1c-4.4,0.2-7.2,0.9-8.4,2c-1.2,1.1-1.7,3.8-1.5,8c0.2,4,0.8,8,1.7,12
		c1.2,5.3,1.9,8.9,2,10.9c0.1,1.3-0.5,2.5-1.6,3.4c-1.1,0.9-2.5,1.4-4.3,1.5C145.1,82.5,143.8,82.1,142.3,81.4z"/>
</g>
</svg>
  );
};

export default EmptyStateUpload;
