import React from 'react';

export interface CardProps {
  header: React.ReactNode;
  content?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ header, content }) => (
  <div className="overflow-hidden rounded shadow-xl bg-white">
    {header}
    <dl className="divide-y divide-gray-100 px-6 py-4 text-sm leading-6 bg-white">{content}</dl>
  </div>
);

export default Card;
