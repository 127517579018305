import React from 'react';

interface GenericErrorProps {
  className?: string;
}
export default function GenericError({ className }: GenericErrorProps) {
  return (
    <div className={`bg-red-100 p-6 rounded shadow-xl ${className ? className : ''}`}>
      <h4>Oups !</h4>
      <p className="text-red-700">
        Une erreur est survenue mais ce n'est pas de votre faute. <br /> Merci
        de réessayer plus tard...
      </p>
    </div>
  );
}
