import React from 'react';
import PageTitle from '../layout/PageTitle';
import { ChannelsMonitoringPage } from './ChannelsMonitoringPage'

const ChannelsMonitoring = () => {
  return (
    <>
      <PageTitle title="Monitoring des médias" />
      <div className="w-full">
        <ChannelsMonitoringPage />
      </div>
    </>
  );
};

export default ChannelsMonitoring;
