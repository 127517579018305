import React from 'react';
import cx from 'classnames';
import { SubscriptionDTO, TrackWithMonitoringData, UserInfoDTO } from 'shared-types'
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import YoutubeLogoSymbol from '../../components/svg/YoutubeLogoSymbol';
import SoundCloudLogoSymbol from '../../components/svg/SoundCloudLogoSymbol';
import SpotifyLogoSymbol from '../../components/svg/SpotifyLogoSymbol';
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
  tracks: TrackWithMonitoringData[];
  onShowDetailAction: (track: TrackWithMonitoringData) => void;
  selectedTrack?: TrackWithMonitoringData;
  searchText?: string;
  userInfo?: UserInfoDTO;
}

function formatNumberToLocaleString(value: number): string {
  return value.toLocaleString("fr-FR", {
    notation: "compact",
    compactDisplay: "short",
    maximumFractionDigits: 1,
  });
}

export function MyTracksTable(props: Props) {
  const subscription = props.userInfo?.subscription
  const email = props.userInfo?.email
  const tableHead = (
    <thead className="bg-gray-700 text-white">
      <tr>
        <th className="p-4 md:p-6 text-left">Titre</th>
        <th className="p-4 md:p-6 text-right">
          <div className="flex justify-end">
            <Tippy content="Spotify">
              <span>
                <SpotifyLogoSymbol className="!h-5 w-auto" />
              </span>
            </Tippy>
          </div>
        </th>
        <th className="p-4 md:p-6 text-right">
          <div className="flex justify-end">
            <Tippy content="Youtube">
              <span>
                <YoutubeLogoSymbol className="!h-5 w-auto" />
              </span>
            </Tippy>
          </div>
        </th>
        <th className="p-4 md:p-6 text-right">
          <div className="flex justify-end">
            <Tippy content="Soundcloud">
              <span>
                <SoundCloudLogoSymbol className="!h-5 w-auto" />
              </span>
            </Tippy>
          </div>
        </th>
        {(subscription?.formula === '2' || subscription?.formula === '3') && (
          <th className="p-4 md:p-6 text-right">
            <div className="flex justify-end">
              <Tippy content="Radios">
                <FontAwesomeIcon icon={duotone('radio')} className="h-5 w-auto" />
              </Tippy>
            </div>
          </th>
        )}
        {subscription?.formula === '3' && (
          <th className="p-4 md:p-6 text-right">
            <div className="flex justify-end">
              <Tippy content="TV">
                <FontAwesomeIcon icon={duotone('tv-music')} className="h-5 w-auto" />
              </Tippy>
            </div>
          </th>
        )}
        <th className="p-4 md:p-6 text-right">
          <div className="flex justify-end items-right">Date de sortie</div>
        </th>
        <th className="p-4 md:p-6 text-right">&nbsp;</th>
      </tr>
    </thead>
  );

  const collectingData = <Tippy content="En cours de collecte..."><FontAwesomeIcon icon={duotone('refresh')} className="h-4 w-auto"/></Tippy>

  return (
    <table className="table w-full">
      {tableHead}
      <tbody>
        {props.tracks.map((track) => {
          const onDetailActionClick = () => props.onShowDetailAction(track);
          return (
            <tr
              className={cx('cursor-pointer hover:bg-gray-100 transition-all duration-200 border-b', {
                'bg-primary-100': track.id === props.selectedTrack?.id,
              })}
              onClick={onDetailActionClick}
              key={track.id}
            >
              <td className="p-4 md:p-6">
                <div className="track max-w-[366px]">
                  <div className="title truncate text-ellipsis overflow-hidden">
                    {!props.searchText && track.title}
                    {props.searchText && (
                      <Highlighter
                        highlightClassName="bg-yellow-400 rounded-sm"
                        searchWords={[props.searchText]}
                        textToHighlight={track.title}
                      />
                    )}
                  </div>
                  {track.artist && (
                    <div className="artist opacity-60 text-xs tracking-wide line-clamp-1">
                      {!props.searchText && track.artist}
                      {props.searchText && (
                        <Highlighter
                          highlightClassName="bg-yellow-400 rounded-sm"
                          searchWords={[props.searchText]}
                          textToHighlight={track.artist}
                        />
                      )}
                    </div>
                  )}
                </div>
              </td>
              <td className="p-6 text-right">
                {track.spotify === 0 ? (
                  <Tippy content="Aucune statistique disponible (nombre de lectures inférieur à 1000)">
                    <FontAwesomeIcon icon={duotone('hourglass-clock')} className="h-4 w-auto text-gray-500" />
                  </Tippy>
                ) : formatNumberToLocaleString(track.spotify)}
              </td>
              <td className="p-6 text-right">
                {track.youtube === 0 ? (
                  <Tippy content="Ajouter le lien vers la page YouTube de ce titre">
                    <a href={`https://www.rightsnow.fr/track-links/?track=${track.title}&isrc=${track.isrc}&email=${email}`} target="_blank">
                      <FontAwesomeIcon icon={duotone('link')} className="h-4 w-auto hover:scale-125" />
                    </a>
                  </Tippy>
                ) : formatNumberToLocaleString(track.youtube)}
              </td>
              <td className="p-6 text-right">
                {track.soundcloud === 0 ? (
                  <Tippy content="Ajouter le lien vers la page SoundCloud de ce titre">
                    <a href={`https://www.rightsnow.fr/track-links/?track=${track.title}&isrc=${track.isrc}&email=${email}`} target="_blank">
                      <FontAwesomeIcon icon={duotone('link')} className="h-4 w-auto hover:scale-125" />
                    </a>
                  </Tippy>
                ) : formatNumberToLocaleString(track.soundcloud)}
              </td>
              {(subscription?.formula === '2' || subscription?.formula === '3') && (
                <td className="p-6 text-right">{track.radio === 0 ? 0 : `${track.radio}`}</td>
              )}
              {subscription?.formula === '3' && (
                <td className="p-6 text-right">{track.tv === 0 ? 0 : `${track.tv}`}</td>
              )}
              <td className="p-6 text-right">{track.release_date ? moment(track.release_date).format('DD/MM/YYYY') : 'non connu'}</td>
              <td className="p-6 text-right">
                <FontAwesomeIcon icon={duotone('arrow-right')} className="h-5 text-primary inline-flex" />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
