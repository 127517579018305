import React from 'react';

type Props = {
  className?: string
}

const IconDoc: React.FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className={`w-4 ${props.className}`}>
      <path className="fa-primary" d="M352 0v96h96L352 0zM328 512h-208C53.83 512 0 458.2 0 392v-272C0 106.8 10.75 96 24 96S48 106.8 48 120v272c0 39.7 32.3 72 72 72h208c13.25 0 24 10.75 24 24S341.3 512 328 512z"/>
      <path className="fa-secondary" d="M96 368v-320C96 21.49 117.5 0 144 0H352v96h96v272c0 26.51-21.49 48-48 48h-256C117.5 416 96 394.5 96 368z"/>
    </svg>

  );
};

export default IconDoc;
