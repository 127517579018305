import { useQuery } from 'react-query';
import api from 'services/api';

export default function useSubscriptionStatus() {
  const { data, isLoading, isError } = useQuery(
    '/api/subscription',
    () => api.findSubscriptionStatus()
  );
  return {
    subscriptionStatus: data,
    isLoading,
    isError,
  };
}
