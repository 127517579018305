import Dropzone from 'components/Dropzone';
import PageTitle from 'features/layout/PageTitle';
import React from 'react';
import cx from 'classnames';
import api from 'services/api';
import { CreateIdentityDocumentDTO } from 'shared-types';
import IconDoc from '../../components/icons/IconDoc';
import IconPassport from '../../components/icons/IconPassport';
import IconIdCard from '../../components/icons/IconIdCard';

type IdentityType = 'PASSPORT' | 'CNI';

export default function IdentityUploadPage() {
  const [identityType, setIdentityType] =
    React.useState<IdentityType>('PASSPORT');
  const [document, setDocument] =
    React.useState<Partial<CreateIdentityDocumentDTO> | null>(null);
  const [isLoading, setLoading] = React.useState(false);
  const [valided, setValidated] = React.useState(false);
  const [error, setError] = React.useState<string | null>();

  const dzConfig = {
    minSizeBytes: 1,
    maxSizeBytes: 10000000,
    maxFiles: 1,
    accept: '.pdf,.jpg,.jpeg,.png',
    fileIcon: <IconDoc />,
  };

  const dzEmptyMeta = (
    <React.Fragment>
      <div className="meta">
        pdf / jpg / png <br /> Taille max : 10 mo
      </div>
      <a>
        {' '}
        Télécharger le{' '}
        <strong className="text orange">
          {identityType === 'PASSPORT' ? 'passeport' : 'recto'}
        </strong>{' '}
      </a>{' '}
    </React.Fragment>
  );

  const validate = async () => {
    setLoading(true);
    setError(null);
    try {
      await api.createIdentityDocument(document as CreateIdentityDocumentDTO);
      setValidated(true);
    } catch (err) {
      setLoading(false);
      setError(JSON.stringify(err));
    }
  };

  return (
    <React.Fragment>
      {' '}
      <PageTitle title="Vérification de votre identité" />
      <div className="w-full md:w-10/12 lg:w-6/12 bg-white shadow-xl p-6 rounded">
        <h1 className="text-xl">Vérification de votre identité</h1>
        {!valided && (
          <div>
            <div className={cx({ disabled: isLoading })}>
              <label>Sélectionnez le type de pièce d'identité</label>
              <div className="w-full flex mb-3">
                <input
                  type="radio"
                  name="radio"
                  id="id1"
                  className="hidden"
                  checked={identityType === 'PASSPORT'}
                  onClick={() => setIdentityType('PASSPORT')}
                />{' '}
                <label
                  htmlFor="id1"
                  className="w-1/2 flex inline-flex flex-row justify-center items-center p-4 cursor-pointer text-primary rounded-tl  rounded-bl border-2 border-r-0 border-primary hover:bg-primary-100 label-checked:bg-red-600"
                >
                  <IconPassport className="w-8 mr-3" />
                  Passeport
                </label>{' '}
                <input
                  type="radio"
                  name="radio"
                  id="id2"
                  className="hidden"
                  checked={identityType === 'CNI'}
                  onClick={() => setIdentityType('CNI')}
                />{' '}
                <label
                  htmlFor="id2"
                  className="w-1/2 flex inline-flex flex-row justify-center items-center p-4 cursor-pointer text-primary rounded-tr  rounded-br border-2 border-primary hover:bg-primary-100"
                >
                  {' '}
                  <IconIdCard className="w-8 mr-3" />
                  Carte d'identité
                </label>
              </div>
            </div>
            <Dropzone
              isUploadRunning={() => {}}
              disabled={isLoading}
              uploadUrl="identity-documents/upload"
              config={dzConfig}
              emptyMeta={dzEmptyMeta}
              onFileStatusChange={({ file }) => {
                if (file.meta.status === 'done' && file.meta.filename) {
                  setDocument({
                    type: identityType as any,
                    filename1: file.meta.filename,
                    originalname1: file.meta.originalname,
                  });
                }
              }}
            />{' '}
            {identityType === 'CNI' && (
              <div className={cx('dropzone mt-2', { disabled: isLoading })}>
                <IconDoc />
                <div className="meta">
                  pdf / jpg / png <br /> Taille max : 10 mo
                </div>
                <a>
                  Télécharger le <strong className="text orange">verso</strong>
                </a>
              </div>
            )}
            <button
              className={cx('button w-full md:w-auto mt-6', {
                disabled: isLoading,
              })}
              onClick={validate}
            >
              Valider les documents
            </button>
          </div>
        )}{' '}
        {error && (
          <div className="message bg-red-100 text-red-500 mt-4 p-4">
            Une erreur s'est produite, mais ce n'est pas de votre faute ! Merci
            de retenter dans quelques minutes :)
          </div>
        )}{' '}
        {valided && (
          <div className="message bg-green-100 text-green-900 my-4 p-4">
            <p>
              <strong>Document(s) bien reçu(s) !</strong>
            </p>
            <p>Vous recevrez un email lorsque le document sera vérifié.</p>
            <p>
              <a href="/dashboard" className="button white ghost">
                {' '}
                Revenir sur mon tableau de bord{' '}
              </a>
            </p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
