import React from 'react';
import {Link, LinkProps, useMatch, useResolvedPath} from 'react-router-dom';
import cx from 'classnames';

export interface ActiveLinkProps extends LinkProps {
  activeOnlyWhenExact?: boolean;
}

export function ActiveLink(props: React.PropsWithChildren<ActiveLinkProps>) {
  const {
    activeOnlyWhenExact,
    children,
    ...linkProps
  } = props;

  const { to, className: linkClassName, ...linkPropsRest } = linkProps;

  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })

  return (
    <Link
      to={to}
      className={cx(linkClassName, {
        'bg-white font-bold shadow': match,
      })}
      {...linkPropsRest}
    >
      {children}
    </Link>
  );
}
