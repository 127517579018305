import React from 'react';
import {Helmet} from 'react-helmet';
import LoginForm from './LoginForm';
import RightsNowLogo from '../../components/svg/RightsNowLogo';

export default class LoginPage extends React.Component {
  // componentDidMount() {
  //   document.getElementsByTagName('body')[0].className = 'login';
  // }

  render() {
    return (
      <div className="flex justify-center login">
        <Helmet>
          <title>RightsNow! - Login</title>
        </Helmet>
        <div className="flex justify-center lg:px-6 lg:my-6 xl:my-12 w-full xl:max-w-screen-lg">
          <div className="w-full h-auto hidden lg:flex lg:w-5/12 rounded-l-lg cursor-default select-none text-white text-xl uppercase leading-tight p-10 pt-16 font-display photo bedroom"/>
          <div className="w-full lg:w-7/12 bg-white p-10 lg:rounded-lg lg:rounded-l-none">
            <div className="w-full md:w-64 mb-12">
              <a href="https://rightsnow.fr"><RightsNowLogo className="max-w-max"/></a>
            </div>
            <LoginForm/>
            <div className="text-center text-sm">
              <a href="/register" className="block py-3 sm:inline-block sm:py-0">Pas encore de compte ?</a><span className="hidden sm:inline-block">&nbsp;&nbsp;&mdash;&nbsp;&nbsp;</span><a href="https://rightsnow.fr/support/" target="_blank" className="block py-3 sm:inline-block sm:py-0">Problèmes de connexion ? </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
