import cx from 'classnames';
import InputField from 'components/InputField';
import {
  isReqFullfiledHelper,
  PasswordRequirementInForm,
  passwordRequirementSchema,
} from 'components/PasswordRequirement';
import { Field, FieldProps, Form, FormikProps, withFormik } from 'formik';
import React, { ReactElement } from 'react';
import api from 'services/api';
import { validateYupSchemaMultiErrors } from 'utils/formikHelpers';
import * as Yup from 'yup';
import RightsNowLogo from '../../components/svg/RightsNowLogo';
import PageTitle from '../layout/PageTitle';
import SelectField from '../../components/SelectField';

const CreateAccountSchema = Yup.object({
  firstName: Yup.string().required('Ce champ est obligatoire'),
  lastName: Yup.string().required('Ce champ est obligatoire'),
  source: Yup.string().required('Ce champ est obligatoire'),
  email: Yup.string()
    .email('Veuillez entrer un email valide')
    .required('Ce champ est obligatoire')
    .test('email', 'Cet email est déjà utilisé', async (value) => {
      if (value && Yup.string().email().isValidSync(value)) {
        try {
          const existingEmailRes = await api.existingEmail(value);
          return !existingEmailRes;
        } catch (error) {
          console.log(error);
          return Promise.resolve(true);
        }
      } else {
        const result = await Promise.resolve(true);
        return result;
      }
    }),
  password: passwordRequirementSchema,
  cgvCgu: Yup.bool().oneOf([true], 'Vous devez accepter les CGV et les CGU'),
}).defined();

const sourceOptions = [
  { value: '', label: '-- Choisissez --' },
  { value: 'youtube', label: 'YouTube' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'search_engine', label: 'une recherche (Google, Bing...)' },
  { value: 'word_of_mouth', label: 'du bouche à oreille' },
  { value: 'other', label: 'Autre' },
];

export type CreateAccountFormSchema = Yup.InferType<typeof CreateAccountSchema>;

interface Props {
  onSubmit: (formData: CreateAccountFormSchema) => void;
  loading?: boolean;
  submitError?: any;
  initialValues?: CreateAccountFormSchema;
  disableSource?: boolean;
}

function CreateAccountForm(props: Props & FormikProps<CreateAccountFormSchema>): ReactElement {
  const { loading, submitError, errors, values } = props;

  let errorMsg = null;
  if (submitError) {
    errorMsg = (
      <div className="message bg-red-100 text-red-900">
        Une erreur est survenue, votre compte n'a pas pu être crée. Veuillez re-essayer plus tard.
      </div>
    );
  }

  if (
    (submitError &&
      submitError.message &&
      Array.isArray(submitError.message) &&
      !!submitError.message.find((obj: any) => obj && obj.constraints && obj.constraints.existingEmail)) ||
    errors.email === 'Cet email est déjà utilisé'
  ) {
    errorMsg = (
      <div className="message bg-red-100 text-red-900">
        Cet email est déjà utilisé. <br /> Utilisez un autre email ou <a href="/login">Connectez vous</a>
      </div>
    );
  }

  const isReqFullfiled = (req: string) => isReqFullfiledHelper(values, errors, 'newPassword', req);

  return (
    <>
      <PageTitle title="Inscription - Informations" />
      <div className="flex justify-center lg:px-6 lg:my-6 xl:my-12 w-full xl:max-w-screen-lg">
        <div className="w-full relative h-auto hidden lg:flex lg:w-5/12 rounded-l-lg cursor-default select-none text-white text-lg uppercase leading-tight font-display photo producer text-shadow">
          <div className="ml-12 bottom-72 w-2/3 text-shadow absolute">
            Grâce à RightsNow! je suis en temps réel mes diffusions.
          </div>
        </div>
        <div className="w-full lg:w-7/12 bg-white p-10 lg:rounded-lg lg:rounded-l-none">
          <div className="mb-12">
            <a href="https://rightsnow.fr/">
              <RightsNowLogo className="w-64 mx-auto lg:mx-0" />
            </a>
            <div className="flex w-fit items-center justify-center mx-auto lg:inline-flex lg:mx-0 px-3 py-1 text-xs font-bold leading-none text-primary-100 bg-primary-600 rounded-full">
              7 jours d'essai gratuit
            </div>
            <p className="text-center w-full text-gray-500 text-sm mt-3 select-none inline-block lg:hidden">
              (Re)Prenez le contrôle sur vos droits d'auteurs.
            </p>
          </div>
          <Form>
            <div className="lg:flex justify-between">
              <Field label="Prénom" name="firstName" isRequired className="lg:w-1/2 lg:mr-4" component={InputField} />
              <Field label="Nom" name="lastName" isRequired className="lg:w-1/2" component={InputField} />
            </div>
            <Field
              label="Email"
              name="email"
              type="email"
              placeholder="Ex : music@ismylife.com"
              isRequired
              component={InputField}
              renderError={(error: string | string[]) => (
                <div className="error explanation">{typeof error === 'string' ? error : error[0]}</div>
              )}
            />
            <Field
              label="Mot de passe"
              name="password"
              type="password"
              isRequired
              component={InputField}
              renderError={(error: string | string[]) => {
                if (typeof error !== 'string' && error.includes('Ce champ est obligatoire')) {
                  return <div className="error explanation">{error[0]}</div>;
                } else return undefined;
              }}
            />
            <PasswordRequirementInForm fieldName="password" values={values} errors={errors} />
            <div className="flex flex-col">
              <Field name="cgvCgu">
                {({ field, form: { touched, errors }, meta }: FieldProps) => (
                  <>
                    <div
                      className={cx('inline-flex items-center my-4 required', {
                        error: touched[field.name] && errors[field.name],
                      })}
                    >
                      <input type="checkbox" id="cgu" checked={field.value} {...field} />
                      <label htmlFor="cgu" className="mb-0 ml-2 leading-none cursor-pointer">
                        J'accèpte les&nbsp;
                        <a href="https://rightsnow.fr/cgv-cgu/" target="_blank">
                          CGV / CGU
                        </a>
                      </label>
                    </div>
                    {touched[field.name] && errors[field.name] && (
                      <div className="w-full pt-4 error explanation">{`${errors[field.name]}`}</div>
                    )}
                  </>
                )}
              </Field>
              {!props.disableSource && (
                <SelectField
                  label="J'ai connu RightsNow! par"
                  name="source"
                  isRequired
                  options={sourceOptions}
                  className="mb-4"
                />
              )}
              <button
                className={cx('button w-full', { loading: loading || !props.isValid })}
                type="submit"
                disabled={loading || !props.isValid}
              >
                S'inscrire
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export const defaultValues: CreateAccountFormSchema = {
  firstName: '',
  lastName: '',
  source: '',
  email: '',
  password: '',
  cgvCgu: false,
};

export default withFormik<Props, CreateAccountFormSchema>({
  mapPropsToValues: ({ initialValues }) => ({
    ...defaultValues,
    ...initialValues,
  }),
  validate: async (values) => validateYupSchemaMultiErrors(values, CreateAccountSchema),
  handleSubmit: async (values, { props: { onSubmit } }) => onSubmit(values),
})(CreateAccountForm);
