import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { formatBroadcastNumber } from 'utils/broadcastHelper';
import IconLoading from 'components/icons/IconLoading';
import Tippy from '@tippyjs/react';
import RightAmountWithMargin from 'components/broadcast/RightsAmountWithMargin';
import LoaderCard from "../LoaderCard";

export function BroadcastContent(props: {
  broadcastNumber: number;
  isLoadingBroadcast: boolean;
  rightsAmount: number;
  rightsMargin?: number;
  isLoadingRights: boolean;
  isLoading?: boolean;
  showUpdateSubscriptionLink?: boolean;
}) {
  if (props.isLoading) {
    return (
      <LoaderCard/>
    );
  } else if (props.showUpdateSubscriptionLink) {
    return (
      <a
        href="/settings/subscription"
        className="text-xs text-gray-600 flex justify-center items-center h-full underline hover:bg-primary-50 rounded text-center w-full py-10"
      >
        <FontAwesomeIcon icon={icon({ name: 'stars', family: 'duotone', style: 'solid' })} className="h-4 mr-1.5" />
        Changer de formule
      </a>
    );
  } else {
    return (
      <>
        <div className="flex justify-between gap-x-4 py-3 items-center">
          <dt className="text-gray-500">Diffusions</dt>
          <dd className="text-gray-700">
            <strong>
              {props.isLoadingBroadcast ? (
                <IconLoading className="w-4 h-4 text-primary" />
              ) : (
                formatBroadcastNumber(props.broadcastNumber)
              )}
            </strong>
          </dd>
        </div>
        <div className="flex justify-between gap-x-4 py-3 items-center">
          <dt className="text-gray-500 inline-flex">
            Droits générés
            <Tippy content="Découvrez comment est calculé ce montant">
              <a href="https://www.rightsnow.fr/algorithme" target="_blank" rel="noreferrer" className="ml-1 inline-flex items-center">
                <FontAwesomeIcon
                  className="ml-1 h-4"
                  icon={icon({ name: 'arrow-up-right', family: 'duotone', style: 'solid' })}
                />
              </a>
            </Tippy>
          </dt>
          <dd className="flex items-start gap-x-2">
            <strong>
              {props.isLoadingRights ? (
                <IconLoading className="w-4 h-4 text-primary" />
              ) : (
                <RightAmountWithMargin amount={props.rightsAmount} compact={true} weightPercentage={props.rightsMargin} />
              )}
            </strong>
          </dd>
        </div>
      </>
    );
  }
}
