import React, { MouseEvent, ReactElement, useEffect, useState } from 'react';
import RightsNowLogo from 'components/svg/RightsNowLogo';
import IconArrow from 'components/icons/IconArrow';
import { SpotifyAlbum, SpotifyArtist, SpotifyTrack } from 'shared-types';
import SearchSpotify from './SearchSpotify';
import TrackUpload from './TrackUpload';
import { FileStatusChangeHandler, IFileWithMeta } from '../../components/Dropzone';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import PageTitle from "../layout/PageTitle";

export interface TrackData {
  selectedArtists: SpotifyArtist[];
  selectedTracks: SpotifyTrack[];
  selectedAlbums: SpotifyAlbum[];
  uploadedTracks: IFileWithMeta[];
}

interface SearchTrackProps {
  onNext: (data: TrackData) => void;
  onPrevious: () => void;
  initialValues?: TrackData;
}

function AddTrack({ onNext, onPrevious, initialValues }: SearchTrackProps): ReactElement {
  const [isUploadTrackMode, setIsUploadTrackMode] = useState(false);

  const [selectedArtists, setSelectedArtists] = useState<SpotifyArtist[]>(initialValues?.selectedArtists || []);
  const [selectedTracks, setSelectedTracks] = useState<SpotifyTrack[]>(initialValues?.selectedTracks || []);
  const [selectedAlbums, setSelectedAlbums] = useState<SpotifyAlbum[]>(initialValues?.selectedAlbums || []);
  const [uploadedTracks, setUploadedTracks] = useState<IFileWithMeta[]>(initialValues?.uploadedTracks || []);

  const toggleTrackUploadMode = (active: boolean) => {
    setIsUploadTrackMode(active);
  };

  const toggleTrackSelection = (track: SpotifyTrack) => {
    const isSelectedTrack = selectedTracks.indexOf(track) > -1;
    if (isSelectedTrack) {
      setSelectedTracks(selectedTracks.filter((t) => t !== track));
    } else {
      setSelectedTracks([...selectedTracks, track]);
    }
  };

  const toggleArtistSelection = (artist: SpotifyArtist) => {
    const isSelectedArtist = selectedArtists.indexOf(artist) > -1;
    if (isSelectedArtist) {
      setSelectedArtists(selectedArtists.filter((t) => t !== artist));
    } else {
      setSelectedArtists([...selectedArtists, artist]);
    }
  };

  const toggleAlbumSelection = (album: SpotifyAlbum) => {
    const isSelectedAlbum = selectedAlbums.indexOf(album) > -1;
    if (isSelectedAlbum) {
      setSelectedAlbums(selectedAlbums.filter((t) => t !== album));
    } else {
      setSelectedAlbums([...selectedAlbums, album]);
    }
  };

  const handleOnNext = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onNext({ selectedArtists, selectedTracks, selectedAlbums, uploadedTracks });
  };

  const handleOnPrevious = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onPrevious();
  };

  const handleTrackUploadOnUpdate = (tracks: IFileWithMeta[]) => {
    setUploadedTracks(tracks);
  };

  const selectedItemCount =
    selectedTracks.length +
    selectedAlbums.map((alb) => alb.total_tracks).reduce((a, b) => a + b, 0) +
    selectedArtists.length;
  return (
    <>
      <PageTitle title="Inscription - Recherche"/>
      <div className="flex justify-center mx-auto lg:px-6 lg:my-6 xl:my-12 w-full xl:max-w-screen-xl">
        <div className="w-full bg-white p-10 rounded-lg">
          <div className="w-64 mb-12">
            <a href="https://rightsnow.fr/" target="_blank" rel="noreferrer">
              {' '}
              <RightsNowLogo/>{' '}
            </a>
            <div className="inline-flex items-center justify-center px-3 py-1 text-xs font-bold leading-none text-primary-100 bg-primary-600 rounded-full">7 jours d'essai gratuit</div>
          </div>
          <h4>Rechercher et ajouter mes titres</h4>
          {/*Upload module*/}
          <div>
            {!isUploadTrackMode && (
              <>
                <p>
                  Retrouvez vos titres sur <strong className="text-[#1ED760]">Spotify</strong> pour les ajouter à votre
                  compte RightsNow!.{' '}
                  <strong className="bg-yellow-300">Bien sur, vous pourrez ajouter d'autres titres par la suite.</strong>
                </p>
              </>
            )}
            {isUploadTrackMode && (
              <a onClick={() => setIsUploadTrackMode(false)} className="inline-flex">
                {' '}
                <IconArrow className="w-3 mr-2"/> Revenir à la recherche Spotify{' '}
              </a>
            )}
          </div>
          {!isUploadTrackMode && (
            <SearchSpotify
              selectedArtists={selectedArtists}
              selectedTracks={selectedTracks}
              selectedAlbums={selectedAlbums}
              toggleArtistSelection={toggleArtistSelection}
              toggleTrackSelection={toggleTrackSelection}
              toggleAlbumSelection={toggleAlbumSelection}/>
          )}
          {isUploadTrackMode && (
            <TrackUpload onUpdate={handleTrackUploadOnUpdate} initialFiles={initialValues?.uploadedTracks || []}/>
          )}
          <div className="mt-6 flex justify-between">
            <button className="button outline text-primary" onClick={handleOnPrevious}>
              <IconArrow/>
            </button>
            {selectedItemCount === 0 && (
              <Tippy content="Sélectionnez au moins un artiste, titre ou ablum">
                          <span>
                              <button className="button" onClick={handleOnNext} disabled={selectedItemCount === 0}>
                                  Suivant
                              </button>
                          </span>
              </Tippy>
            )}
            {selectedItemCount > 0 && (
              <button className="button" onClick={handleOnNext} disabled={selectedItemCount === 0}>
                Suivant
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTrack;
