import PageTitle from 'features/layout/PageTitle';
import React, { useState } from 'react';
import RightNowLogo from 'components/svg/RightsNowLogo';
import NavigationFooter from 'features/layout/NavigationFooter';
import PastDueImg from 'styles/assets/img/bg-pastdue.jpg';
import PastDueContent from 'features/subscription/PastDueContent';

export default function PastDuePage() {
  const [isSubscriptionRenewed, setIsSubscriptionRenewed] = useState(false);
  return (
    <>
      <PageTitle title="Le paiement a échoué" />
        <div className="min-h-full pt-3 sm:pt-16 flex flex-col max-w-2xl mx-auto">
          <main className="flex-grow flex flex-col max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 flex mb-12">
              <span className="sr-only">RightsNow!</span>{' '}
              <RightNowLogo className="h-12 w-auto text-white" />
            </div>
            {/*{!isSubscriptionRenewed && <PastDueForm onPaymentSuccess={() => setIsSubscriptionRenewed(true)} />}*/}
            {!isSubscriptionRenewed && <PastDueContent />}
            {isSubscriptionRenewed && (
              <span>Subscription successfully renewed</span>
            )}
          </main>
        </div>
    </>
  );
}
