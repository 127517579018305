import PageTitle from 'features/layout/PageTitle';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassswordPage = () => {
  return (
    <>
      <PageTitle title="Modifier mon mot de passe" />
      <ChangePasswordForm />
    </>
  );
};

export default ChangePassswordPage;
