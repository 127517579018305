import React from 'react';

type Props = {
    className?: string
}

const StripeLogo: React.FC<Props> = (props) => {
    return (
        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 360 149.8" enableBackground="new 0 0 360 149.8" className={`${props.className}`}>
            <g>
                <path fillRule="evenodd" clipRule="evenodd" d="M360,77.4c0-25.6-12.4-45.8-36.1-45.8
		c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3c11.9,0,20.9-2.7,27.7-6.5V96c-6.8,3.4-14.6,5.5-24.5,5.5
		c-9.7,0-18.3-3.4-19.4-15.2h48.9C359.8,85,360,79.8,360,77.4z M310.6,67.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H310.6z"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M247.1,31.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3
		l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3c17.9,0,34.2-14.4,34.2-46.1C281.1,47.4,264.6,31.6,247.1,31.6z M241.1,100.5
		c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C256.5,90.5,250.3,100.5,241.1,100.5z"/>
                <polygon fillRule="evenodd" clipRule="evenodd" points="169.8,25.7 194.9,20.3 194.9,0 169.8,5.3 	"/>
                <rect x="169.8" y="33.3" fillRule="evenodd" clipRule="evenodd" width="25.1" height="87.5"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M142.9,40.7l-1.6-7.4h-21.6v87.5h25V61.5c5.9-7.7,15.9-6.3,19-5.2
		v-23C160.5,32.1,148.8,29.9,142.9,40.7z"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M92.9,11.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7
		c8.2,0,14.2-1.5,17.5-3.3V99c-3.2,1.3-19,5.9-19-8.9V54.6h19V33.3h-19L92.9,11.6z"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M25.3,58.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V36.2
		c-8.3-3.3-16.5-4.6-24.8-4.6C13.5,31.6,0,42.2,0,59.9C0,87.5,38,83.1,38,95c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8
		c9.3,4,18.7,5.7,27.3,5.7c20.8,0,35.1-10.3,35.1-28.2C63.4,64.6,25.3,69.9,25.3,58.7z"/>
            </g>
        </svg>
    );
};

export default StripeLogo;
