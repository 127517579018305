import React from 'react';

type Props = {
    className?: string
}

const RightNowLogoIcon: React.FC<Props> = (props) => {
    return (
        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 228.3 247.4" className={`logo ${props.className}`}>
            <rect y="32.3" width="32.9" height="153.6"/>
            <rect x="96.7" y="1.6" width="32.9" height="245.8"/>
            <rect x="48.3" y="137.7" width="32.9" height="79"/>
            <rect x="48.3" y="32.3" width="32.9" height="92.2"/>
            <rect x="146.1" y="63" width="32.9" height="153.6"/>
            <rect x="195.4" y="32.3" width="32.9" height="135.4"/>
            <rect x="195.4" y="183.8" width="32.9" height="32.9"/>
        </svg>

    );
};

export default RightNowLogoIcon;
