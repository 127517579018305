import PageTitle from 'features/layout/PageTitle';
import SubscriptionSettings from "features/settings/subscription/SubscriptionSettings";
import React from 'react';
import DeleteMyAccount from '../DeleteMyAccount'

const NotificationSettingsPage = () => {
  return (
    <>
      <PageTitle title="Abonnement & Facture" />
      <SubscriptionSettings />
      <DeleteMyAccount />
    </>
  );
};

export default NotificationSettingsPage;
