import React from 'react';
import { useQuery } from 'react-query';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';
import IconLoading from '../../components/icons/IconLoading';
import { useAuth } from 'hooks/use-auth';
import IconPayment from '../../components/icons/IconPayment';
import useSubscriptionStatus from '../../hooks/use-subscription-status';
import GenericError from '../../components/GenericError';

export default function PastDueContent() {
  const navigate = useNavigate();
  const { subscriptionStatus, isLoading, isError } = useSubscriptionStatus();
  const { signout } = useAuth();
  const handleSignout = () => {
    signout();
    navigate('/login');
  };

  if (isLoading) {
    return (
      <div className="h-56 text-sm flex items-center justify-center leading-none">
        <IconLoading className="w-5 h-5 text-primary mr-2" /> Chargement...
      </div>
    );
  }

  if (isError) {
    return <GenericError />;
  }

  if (
    subscriptionStatus !== undefined &&
    !(subscriptionStatus.status === 'past_due' || subscriptionStatus.status === 'unpaid')
  ) {
    navigate('/');
  }

  return (
    <>
      <h1 className="text-red flex text-xl mb-6">
        <IconPayment className="mr-2 w-6 flex-shrink-0" />
        <strong>Échec du paiement</strong>
      </h1>
      <p>
        Pour pouvoir accéder à votre compte, merci de mettre à jour{' '}
        <a
          onClick={async () => {
            const res = await api.createCustomerPortalSession();
            window.location.href = (res as any).data;
          }}
          className="underline"
        >
          votre moyen de paiement
        </a>
        .
      </p>
      <div className="flex flex-col sm:flex-row justify-between mt-6">
        <button
          className="w-full button text-sm py-2 sm:w-fit"
          onClick={async () => {
            const res = await api.createCustomerPortalSession();
            window.location.href = (res as any).data;
          }}
        >
          Mettre à jour mon moyen de paiement
        </button>
        <button
          className="w-full button text-sm py-2 sm:w-fit ghost border-primary text-primary mt-3 sm:mt-0"
          onClick={handleSignout}
        >
          Se déconnecter
        </button>
      </div>
    </>
  );
}
