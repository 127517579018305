import React, { CSSProperties } from 'react'

type Props = {
    className?: string
    svgStyle?: CSSProperties
}

const RightNowLogo: React.FC<Props> = (props) => {
  return (
    <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 1142.7 247.4" className={`logo ${props.className}`} style={props.svgStyle}>
      <rect y="32.3" width="32.9" height="153.6"/>
      <rect x="96.7" y="1.6" width="32.9" height="245.8"/>
      <rect x="48.3" y="137.7" width="32.9" height="79"/>
      <rect x="48.3" y="32.3" width="32.9" height="92.2"/>
      <rect x="146.1" y="63" width="32.9" height="153.6"/>
      <rect x="195.4" y="32.3" width="32.9" height="135.4"/>
      <rect x="195.4" y="183.8" width="32.9" height="32.9"/>
      <path d="M327,62.9h24.8c13.6,0,23.3,1.2,29,3.6c5.8,2.4,10.4,6.5,13.9,12.1s5.3,12.3,5.3,20
	c0,8.1-1.9,14.8-5.8,20.3c-3.9,5.4-9.7,9.6-17.5,12.3l29.1,54.7h-25.6l-27.7-52.1h-2.1v52.1h-23.4L327,62.9L327,62.9z M350.5,111
	h7.3c7.5,0,12.6-1,15.4-2.9c2.8-2,4.2-5.2,4.2-9.7c0-2.7-0.7-5-2.1-7c-1.4-2-3.2-3.4-5.6-4.3c-2.3-0.9-6.6-1.3-12.8-1.3h-6.4
	L350.5,111L350.5,111z"/>
      <path d="M424.7,57.4c4,0,7.4,1.5,10.3,4.3s4.3,6.4,4.3,10.5c0,4.1-1.4,7.5-4.2,10.4
	c-2.8,2.9-6.2,4.3-10.2,4.3c-4.1,0-7.5-1.5-10.4-4.4c-2.9-2.9-4.3-6.5-4.3-10.7c0-4,1.4-7.4,4.2-10.3S420.7,57.4,424.7,57.4z
	 M413.3,94.9h22.8v91h-22.8V94.9z"/>
      <path d="M517.7,94.9h22.8v78c0,15.4-3.1,26.7-9.3,34c-8.3,9.8-20.8,14.7-37.6,14.7c-8.9,0-16.4-1.1-22.5-3.4
	c-6.1-2.2-11.2-5.5-15.4-9.8c-4.2-4.3-7.3-9.6-9.3-15.8h25.2c2.2,2.6,5.1,4.5,8.6,5.8c3.5,1.3,7.7,2,12.5,2c6.1,0,11.1-0.9,14.8-2.8
	c3.7-1.9,6.4-4.4,7.9-7.4c1.5-3,2.3-8.2,2.3-15.6c-4,4-8.2,6.9-12.6,8.7c-4.4,1.8-9.4,2.6-15,2.6c-12.2,0-22.5-4.4-31-13.2
	s-12.6-20-12.6-33.5c0-14.4,4.5-26.1,13.4-34.8c8.1-7.9,17.7-11.9,29-11.9c5.2,0,10.2,1,14.9,2.9c4.7,1.9,9.3,5.1,14,9.5L517.7,94.9
	L517.7,94.9z M494,113.9c-7.2,0-13.1,2.4-17.8,7.2c-4.7,4.8-7,10.9-7,18.2c0,7.6,2.4,13.8,7.2,18.7c4.8,4.9,10.8,7.3,18.1,7.3
	c7.1,0,12.9-2.4,17.5-7.1s6.9-11,6.9-18.7c0-7.6-2.3-13.8-6.9-18.5C507.2,116.3,501.3,113.9,494,113.9z"/>
      <path d="M554.1,59.8h22.7v44.3c4.5-3.8,9-6.7,13.5-8.7c4.5-1.9,9.1-2.9,13.7-2.9c9,0,16.7,3.1,22.8,9.4
	c5.3,5.4,8,13.4,8,23.8v60.1h-22.5V146c0-10.5-0.5-17.7-1.5-21.4c-1-3.7-2.7-6.5-5.1-8.4c-2.4-1.8-5.4-2.8-9-2.8
	c-4.6,0-8.6,1.5-11.9,4.6s-5.6,7.2-6.9,12.5c-0.7,2.7-1,9-1,18.7v36.6h-22.7L554.1,59.8L554.1,59.8z"/>
      <path d="M653,61.4h22.8v33.5h13.5v19.7h-13.5v71.4H653v-71.4h-11.7V94.9H653V61.4z"/>
      <path d="M754.7,107.6l-14.1,14.1c-5.8-5.7-11-8.5-15.7-8.5c-2.6,0-4.6,0.5-6,1.6s-2.2,2.4-2.2,4.1
	c0,1.2,0.5,2.4,1.4,3.4s3.2,2.4,6.8,4.2l8.4,4.2c8.8,4.4,14.9,8.8,18.2,13.3c3.3,4.5,4.9,9.8,4.9,15.9c0,8.1-3,14.8-8.9,20.2
	s-13.9,8.1-23.9,8.1c-13.3,0-23.9-5.2-31.8-15.6l14.1-15.3c2.7,3.1,5.8,5.6,9.4,7.6c3.6,1.9,6.8,2.9,9.6,2.9c3,0,5.4-0.7,7.3-2.2
	c1.8-1.4,2.8-3.1,2.8-5c0-3.5-3.3-6.9-10-10.3l-7.7-3.9c-14.7-7.4-22.1-16.7-22.1-27.9c0-7.2,2.8-13.3,8.3-18.4
	c5.5-5.1,12.7-7.7,21.3-7.7c5.9,0,11.5,1.3,16.7,3.9C746.6,99.1,751,102.8,754.7,107.6z"/>
      <path d="M766.6,62.9h22.5l52.7,81v-81h23.4v123.1h-22.5L790,105.2v80.7h-23.4V62.9z"/>
      <path d="M925,92.6c8.6,0,16.7,2.2,24.2,6.4c7.6,4.3,13.5,10.1,17.7,17.5c4.2,7.4,6.4,15.3,6.4,23.8
	c0,8.6-2.1,16.6-6.4,24.1s-10.1,13.3-17.4,17.5s-15.5,6.3-24.3,6.3c-13,0-24.2-4.6-33.4-13.9s-13.8-20.6-13.8-33.8
	c0-14.2,5.2-26.1,15.7-35.6C902.6,96.7,913.1,92.6,925,92.6z M925.4,114.1c-7.1,0-13,2.5-17.7,7.4c-4.7,4.9-7.1,11.3-7.1,19
	c0,7.9,2.3,14.3,7,19.2c4.7,4.9,10.6,7.4,17.7,7.4c7.2,0,13.1-2.5,17.8-7.4c4.8-5,7.1-11.4,7.1-19.2s-2.3-14.2-7-19
	C938.6,116.5,932.6,114.1,925.4,114.1z"/>
      <path d="M973.3,94.9h22.4l18.3,51.8l19.7-51.8h13.7l19.3,51.2l18.3-51.2h22.5l-33.2,91h-14.5l-19.4-51.9
	l-20,51.9h-14.3L973.3,94.9z"/>
      <path d="M1128.3,159.7c4,0,7.3,1.4,10.2,4.2c2.8,2.8,4.2,6.2,4.2,10.1c0,3.9-1.4,7.3-4.2,10.1
	c-2.8,2.8-6.2,4.2-10.2,4.2c-4,0-7.3-1.4-10.1-4.2s-4.2-6.2-4.2-10.1c0-4,1.4-7.3,4.2-10.1C1121,161.1,1124.4,159.7,1128.3,159.7z
	 M1116.8,59.8h23.1v89.9h-23.1V59.8z"/>
    </svg>
  );
};

export default RightNowLogo;
