import React from 'react'
import useUserInfo from 'hooks/use-user-info'

export default function NavigationFooter() {
  const {
    userInfo: {
      email,
    },
  } = useUserInfo()
  return (
    <React.Fragment>
      <div className="flex flex-wrap items-baseline md:flex-row space-y-4 md:space-y-0 justify-center text-sm tracking-wide leading-normal">
        {/*<a href={`https://www.rightsnow.fr/support/?email=${email}&firstName=${firstName}&lastName=${lastName}&member=true`} target="_blank" rel="noreferrer" className="text-gray-700">*/}
        <a href={`https://www.rightsnow.fr/support/?email=${email}&member=true`} target="_blank" rel="noreferrer" className="text-gray-700">
          Nous contacter
        </a>
        <div className="w-1.5 h-1.5 mx-3 bg-gray-500 rounded-xl self-center" />
        <a href={`https://tally.so/r/mJO5Jz?email=${email}&member=true`} target="_blank" rel="noreferrer" className="text-gray-700">
          Idée de fonctionnalité ?
        </a>
        <div className="w-1.5 h-1.5 mx-3 bg-gray-500 rounded-xl self-center" />
        <a href="https://rightsnow.fr/faq/" target="_blank" rel="noreferrer" className="text-gray-700">
          FAQ
        </a>
        <div className="w-1.5 h-1.5 mx-3 bg-gray-500 rounded-xl self-center" />
        <a href="https://rightsnow.fr/news/" target="_blank" rel="noreferrer" className="text-gray-700">
          News
        </a>
        <div className="w-1.5 h-1.5 mx-3 bg-gray-500 rounded-xl self-center" />
        <a href="https://rightsnow.fr" target="_blank" rel="noreferrer" className="text-gray-700">
          Revenir sur RightsNow.fr
        </a>
      </div>
    </React.Fragment>
  )
}
