import React from 'react';

export default function LoaderChart({className}: { className?: string }) {

  // Générer 20 divs avec des valeurs aléatoires
  const chartBars = [];
  for (let i = 0; i < 80; i++) {
    const randomDuration = Math.floor(Math.random() * 301); // Nombre aléatoire entre 0 et 300
    const randomHeight = Math.floor(Math.random() * (432 - 150 + 1)) + 150; // Nombre aléatoire entre 150 et 432
    const divStyle = {
      height: `${randomHeight}px`, // Ajout de 'px' pour la hauteur
      transitionDuration: `${randomDuration}ms`, // Ajout de 'ms' pour la durée de transition
    };

    chartBars.push(
      <div key={i} className="w-full bg-gray-200 rounded-t-lg animate-pulse" style={divStyle}></div>
    );
  }

  return (
    <div role="status" className={`w-full flex justify-start flex-row items-baseline space-x-1 ${className}`}>
      {chartBars}
      <span className="sr-only">Chargement du graphique...</span>
    </div>
  );
}