import React, { PropsWithChildren } from 'react';
import SettingsLeftMenu from './SettingsLeftMenu';

export default class SettingsLayout extends React.Component<PropsWithChildren> {
  render() {
    const { children } = this.props;
    return (
      <div className="settings w-full">
        <div className="md:flex w-full md:space-x-6">
          <div className="md:w-4/12">
            <SettingsLeftMenu />
          </div>
          <div className="md:w-8/12">{children}</div>
        </div>
      </div>
    );
  }
}
