import cx from 'classnames';
import InputField from 'components/InputField';
import {
  PasswordRequirementInForm,
  passwordRequirementSchema,
} from 'components/PasswordRequirement';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from 'services/api';
import { validateYupSchemaMultiErrors } from 'utils/formikHelpers';
import * as Yup from 'yup';

const ForgottenPasswordFormSchema = Yup.object()
  .shape({
    password: passwordRequirementSchema,
    verificationPassword: Yup.string()
      .required('Ce champ est obligatoire')
      .test(
        'password-match',
        'Les mots de passe doivent être identiques',
        (value, ctx) => {
          return ctx.parent.password === value;
        },
      ),
  })
  .defined();

type ForgottenPasswordFormValues = Yup.InferType<
  typeof ForgottenPasswordFormSchema
>;

function ForgottenPasswordForm({
  onPasswordChangeSuccess,
}: {
  onPasswordChangeSuccess: () => void;
}) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get('email');
  const token = query.get('token');
  const [passwordChangeError, setPasswordChangeError] = useState(false);
  return (
    <Formik<ForgottenPasswordFormValues>
      initialValues={{ password: '', verificationPassword: '' }}
      validate={async (values) =>
        validateYupSchemaMultiErrors(values, ForgottenPasswordFormSchema)
      }
      onSubmit={async (values) => {
        try {
          await api.changePassword(email!, token!, values.password);
          onPasswordChangeSuccess();
        } catch (error: any) {
          console.warn(
            'You have an error in your code or there are Network issues.',
            error,
          );
          setPasswordChangeError(true);
        }
      }}
    >
      {({ isSubmitting, isValid, errors, values }) => (
        <Form className="mb-8">
          <Field
            label="Mot de passe"
            name="password"
            type="password"
            isRequired
            component={InputField}
            renderError={(error: string | string[]) => {
              if (
                typeof error !== 'string' &&
                error.includes('Ce champ est obligatoire')
              ) {
                return <div className="error explanation">{error[0]}</div>;
              } else return undefined;
            }}
          />
          <Field
            label="Confirmez votre de passe"
            name="verificationPassword"
            type="password"
            isRequired
            component={InputField}

          />

          <PasswordRequirementInForm
            fieldName="password"
            values={values}
            errors={errors}
          />
          <div className="flex mt-6 lg:mt-6">
            <button
              className={cx('button w-full md:w-auto', {
                loading: !isValid || isSubmitting || passwordChangeError,
              })}
              type="submit"
              disabled={!isValid || isSubmitting || passwordChangeError}
            >
              Valider
            </button>
            <a
              href="/login"
              className="button ghost text-primary mt-4 md:mt-0 md:ml-2 w-full md:w-auto"
            >
              Revenir à la page de connection
            </a>
          </div>
          {passwordChangeError && (
            <div className="message bg-red-100 text-red-500 my-4">
              <p>
                Nous n'avons pas pu mettre à jour votre nouveau mot de passe. Si
                le problème persiste, veuillez{' '}
                <a href="https://www.rightsnow.fr/support/" target="_blank">
                  nous contacter
                </a>
                .
              </p>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ForgottenPasswordForm;
