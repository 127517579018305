import React from 'react';

type Props = {
  className?: string
}

const IconFileMusic: React.FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className={`w-4 ${props.className}`}>
      <path className="fa-primary" d="M256 0v128h128L256 0zM267.3 208.8L139.3 256C132.5 258.1 128 264.3 128 271.3v114.8C122.8 384.8 117.4 384 112 384C85.5 384 64 398.4 64 416s21.5 32 48 32S160 433.6 160 416V315.1l96-37.5v76.38C250.8 352.8 245.4 352 240 352c-26.5 0-48 14.38-48 32s21.5 32 48 32s48-14.38 48-32V224c0-5.125-2.375-9.875-6.5-12.88S272 207.3 267.3 208.8z"/>
      <path className="fa-secondary" d="M256 128V0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V128H256zM288 384c0 17.62-21.5 32-48 32S192 401.6 192 384s21.5-32 48-32c5.375 0 10.75 .7498 16 2V277.6l-96 37.5V416c0 17.62-21.5 32-48 32S64 433.6 64 416s21.5-32 48-32c5.375 0 10.75 .7498 16 2V271.3C128 264.3 132.5 258.1 139.3 256l128-47.25C272 207.3 277.4 208.1 281.5 211.1S288 218.9 288 224V384z"/>
    </svg>
  );
};

export default IconFileMusic;
