import React, { useState } from 'react';
import PageTitle from 'features/layout/PageTitle';
import api from 'services/api';
import { SpotifyAlbum, SpotifyArtist, SpotifyTrack } from 'shared-types';
import { useLocation, useNavigate } from 'react-router-dom';
import GenericError from 'components/GenericError';
import SearchSpotify from '../register/SearchSpotify';
import { useQueryClient } from 'react-query';
import { useMyTracksStore } from 'features/tracks/MyTracksState';

export default function TrackUploadPage() {
  const { setUploadedTracks, setUploadedTrackCount } = useMyTracksStore();
  const [isError, setIsError] = useState(false);
  const [step, setStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedArtists, setSelectedArtists] = useState<SpotifyArtist[]>([]);
  const [selectedTracks, setSelectedTracks] = useState<SpotifyTrack[]>([]);
  const [selectedAlbums, setSelectedAlbums] = useState<SpotifyAlbum[]>([]);

  const navigate = useNavigate();
  const queryClient = useQueryClient();


  const toggleAlbumSelection = (album: SpotifyAlbum) => {
    const isSelectedAlbum = selectedAlbums.indexOf(album) > -1;
    if (isSelectedAlbum) {
      setSelectedAlbums(selectedAlbums.filter((t) => t !== album));
    } else {
      setSelectedAlbums([...selectedAlbums, album]);
    }
  };

  const toggleTrackSelection = (track: SpotifyTrack) => {
    const isSelectedTrack = selectedTracks.indexOf(track) > -1;
    if (isSelectedTrack) {
      setSelectedTracks(selectedTracks.filter((t) => t !== track));
    } else {
      setSelectedTracks([...selectedTracks, track]);
    }
  };

  const toggleArtistSelection = (artist: SpotifyArtist) => {
    const isSelectedArtist = selectedArtists.indexOf(artist) > -1;
    if (isSelectedArtist) {
      setSelectedArtists(selectedArtists.filter((t) => t !== artist));
    } else {
      setSelectedArtists([...selectedArtists, artist]);
    }
  };

  const submitTracks = async () => {
    try {
      // setIsUploadTracks(true);
      setIsSubmitting(true);
      const request = {
        spotifyArtistIds: selectedArtists.map((t) => t.id),
        spotifyTrackIds: selectedTracks.map((t) => t.id),
        spotifyAlbumIds: selectedAlbums.map((a) => a.id),
        uploadedTracks: [],
      };
      const trackCount = await api.uploadTracks(request);
      setUploadedTracks(request);
      setUploadedTrackCount(trackCount)
      queryClient.refetchQueries({ queryKey: ['/api/tracks'], active: true });
      queryClient.refetchQueries({ queryKey: ['/api/user-info'], active: true });
      navigate('/my-tracks');
    } catch (e) {
      setIsSubmitting(false);
      setIsError(true);
    }
  };

  const canSubmit = selectedTracks.length > 0 || selectedAlbums.length > 0 || selectedArtists.length > 0;

  return (
    <React.Fragment>
      <div className="w-full bg-white shadow-xl p-6 rounded">
        <PageTitle title="Envoyer vos titres" />
        {!isError && step === 0 && (
          <>
            <h4 className="mb-6">Ajouter des titres</h4>
            <div>
              <p>
                Retrouvez vos titres sur <strong className="text-[#1ED760]">Spotify</strong> pour les ajouter à votre
                compte RightsNow!.{' '}
                <strong className="bg-yellow-300">Bien sur, vous pourrez ajouter d'autres titres par la suite.</strong>
              </p>
            </div>
            <SearchSpotify
              selectedArtists={selectedArtists}
              selectedTracks={selectedTracks}
              selectedAlbums={selectedAlbums}
              toggleTrackSelection={toggleTrackSelection}
              toggleAlbumSelection={toggleAlbumSelection}
              toggleArtistSelection={toggleArtistSelection}
            />
            {canSubmit && (
              <div className="flex justify-end">
                <button className="button w-full md:w-auto mt-6" onClick={submitTracks} disabled={isSubmitting}>
                  Suivant
                </button>
              </div>
            )}
          </>
        )}
        {isError && <GenericError />}
      </div>
    </React.Fragment>
  );
}
