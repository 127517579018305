import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';

const BROADCAST_TYPE = {
  stream: {
    title: 'Streams',
    icon: (
      <FontAwesomeIcon
        icon={icon({ name: 'cloud-music', family: 'duotone', style: 'solid' })}
        className="h-6 text-stream-600 flex-none"
      />
    ),
    backgroundColor: 'bg-stream-50',
    textColor: 'text-stream-800',
  },
  radio: {
    title: 'Radios',
    icon: (
      <FontAwesomeIcon
        icon={icon({ name: 'radio', family: 'duotone', style: 'solid' })}
        className="h-6 text-radio-600 flex-none"
      />
    ),
    backgroundColor: 'bg-radio-50',
    textColor: 'text-radio-800',
  },
  tv: {
    title: 'TV',
    icon: (
      <FontAwesomeIcon
        icon={icon({ name: 'tv-music', family: 'duotone', style: 'solid' })}
        className="h-6 text-tv-600 flex-none"
      />
    ),
    backgroundColor: 'bg-tv-50',
    textColor: 'text-tv-800',
  },
};

function BroadcastHeader(props: {
  title: string;
  icon: React.ReactNode;
  backgroundColor: string;
  textColor: string;
  children?: React.ReactNode;
}) {
  return (
    <div className={`sm:flex justify-between items-center gap-x-4 ${props.backgroundColor} p-6`}>
      <div className="inline-flex items-center">
        {props.icon}
        <div className={`font-bold text-xl leading-6 ml-2 ${props.textColor}`}>{props.title}</div>
      </div>
      {props.children}
    </div>
  );
}

export function StreamHeader({ children }: { children?: React.ReactNode }) {
  return <BroadcastHeader {...BROADCAST_TYPE.stream} children={children} />;
}

export function RadioHeader({ children }: { children?: React.ReactNode }) {
  return <BroadcastHeader {...BROADCAST_TYPE.radio} children={children} />;
}

export function TvHeader({ children }: { children?: React.ReactNode }) {
  return <BroadcastHeader {...BROADCAST_TYPE.tv} children={children} />;
}
