import React, { useEffect } from 'react';
import { useAuth } from 'hooks/use-auth';
import { useNavigate } from 'react-router-dom';

export default function LogoutPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    auth.signout();
    navigate('/', { replace: true });
  });
  return <></>;
}
